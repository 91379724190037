import React, { FC, useEffect, useState } from "react";
import Logo from "../../assets/icons/logo - svg/vuka-logo-dark.svg";
import "./placeOrder.scss";
import WalletIcon from "../../assets/icons/multi color icons - svg/dynamic wallet -  white.svg";
import WalletStaticIcon from "../../assets/icons/multi color icons - svg/static wallet - white.svg";
import { numberConvention } from "../../shared/utils/numberConvention";
import DynamicText from "../../shared/components/DynamicText";
import { Checkbox, List, Skeleton, Button } from "antd";
import { Form, Formik } from "formik";
import { TermsAndConditionsReducerProps } from "../../store/reducers/termsAndConditionsReducer";
import termsAndConditionsContainer from "../../store/container/termsAndconditionsContainer";
import LocalStorage from "../../shared/LocalStorage";
import OrderService from "../../services/OrderService/order.service";
import Lottie from "react-lottie";
import successPlaceOrder from "../../assets/lotties/successPlaceOrder.json";
import declinedPlaceOrder from "../../assets/lotties/declinedPlaceOrder.json";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

interface PlaceOrderProps extends TermsAndConditionsReducerProps {
  accept: boolean;
}

interface User {
  name: string;
  uid: string;
}

interface Order {
  order_type: boolean;
  breakdown: {
    unit: number;
    amount: number;
    breakdown: Array<[string, string]>;
    amount_needed: number;
  };
}

interface JsonData {
  user: User;
  order: Order;
}

const PlaceOrder: FC<PlaceOrderProps> = ({ showTermsAndConditions, accept }) => {
  const { xs: isMobile } = useBreakpoint();
  const [jsonData, setJsonData] = useState<JsonData | null>(null);
  const { confirmOrder } = OrderService();
  const [dynamicUrl, setDynamicUrl] = useState<string>("");
  const [uniqueId, setUniqueId] = useState<number>(0);
  const [showSuccessAnimation, setShowSuccessAnimation] = useState(false);
  const [showDeclineAnimation, setShowDeclineAnimation] = useState(false);

  useEffect(() => {
    parseAndDecodeJson();
  }, []);

  const parseAndDecodeJson = () => {
    const params = new URLSearchParams(window.location.search);
    const encodedUrl = params.get("url");
    setDynamicUrl(encodedUrl!);
  
    if (!encodedUrl) {
      return;
    }
  
    const encodedJsonUrl = new URLSearchParams(encodedUrl);
    setUniqueId(Number(encodedJsonUrl.get("unique_id")));
    const encodedJson = encodedJsonUrl.get("json");
  
    if (encodedJson) {
      setJsonData(JSON.parse(atob(encodedJson)));
    }
  };  

  const order = jsonData?.order || {
    order_type: false,
    breakdown: {
      unit: 0,
      amount: 0,
      breakdown: [],
      amount_needed: 0,
    },
  };

  const orderType = order.order_type;
  const unit = order.breakdown.unit;
  const amount = order.breakdown.amount;
  const breakdown = order.breakdown.breakdown || [];
  const dynamicWalletBalance = parseFloat(breakdown[3]?.[1] || "0");
  const staticWalletBalance = parseFloat(breakdown[2]?.[1] || "0");

  const breakdownTemplate = breakdown.map((breakdown, i) => (
    <List.Item key={i}>
      <span>{breakdown[0]}</span>
      <span
        className={
          (jsonData?.order?.breakdown?.breakdown.length === i + 1
            ? "text-bold"
            : " ") + (breakdown[1] < "0" ? " danger " : "")
        }
      >
        {breakdown[1]}
      </span>
    </List.Item>
  ));

  const successOption = {
    loop: true,
    autoplay: true,
    animationData: successPlaceOrder,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const declineOption = {
    loop: true,
    autoplay: true,
    animationData: declinedPlaceOrder,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  
  const handleOrder = async (confirmValue: number) => {
    LocalStorage.removeItem("has-accepted-terms-and-conditions");
    await confirmOrder(dynamicUrl, {
      uniqueId,
      quantity: jsonData?.order?.breakdown?.unit,
      amount: jsonData?.order?.breakdown?.amount,
      type: jsonData?.order?.order_type ? 1 : 0,
      confirm: confirmValue,
    });
  
    if (confirmValue === 1) {
      setShowSuccessAnimation(true);
    } else {
      setShowDeclineAnimation(true);
    }
  };

  return (
    <div>
      <div className="logo-container">
        <img src={Logo} alt="logo" className="logo" />
      </div>
      {!(showSuccessAnimation || showDeclineAnimation) && (
        <div
          className={isMobile ? "order-container-mobile" : "order-container"}
        >
          <div
            className={`order__header ${orderType ? "yellow-bg" : "blue-bg"}`}
          >
            <div className="order__header-title">
              <h2>{orderType ? "Buy Units" : "Sell Units"}</h2>
              <div>
                <img src={WalletIcon} alt="dynamic" />
                <p className="text">Dynamic Wallet Balance </p>
                <p className="balance">
                  {numberConvention(dynamicWalletBalance)} KES
                </p>
              </div>
              <div>
                <img src={WalletStaticIcon} alt="dynamic" />
                <p className="text">Static Wallet Balance </p>
                <p className="balance">
                  {numberConvention(staticWalletBalance)} KES
                </p>
              </div>
            </div>
          </div>
          <div className="order__body">
            <div className="order__content">
              <p className="order__content-title">
                You are confirming to the place the order for
              </p>
              <div className="order__content-amount">
                <h1>
                  <DynamicText
                    text={`${unit} ${unit === 1 ? "unit" : "units"}`}
                    width="100%"
                    fontSize={isMobile ? 25 : 32}
                  />
                </h1>
                <h1>
                  <DynamicText
                    text={`${numberConvention(amount)} KES`}
                    width="100%"
                    fontSize={isMobile ? 25 : 32}
                  />
                </h1>
              </div>
            </div>
            <div className="order__template">
              <List>
                {breakdown
                  ? breakdownTemplate
                  : new Array(4).fill(" ").map((_) => (
                      <List.Item>
                        <Skeleton.Input active={true} size="small" />
                        <Skeleton.Input
                          active={true}
                          size="small"
                          className="mini-skeleton"
                        />
                      </List.Item>
                    ))}
              </List>
            </div>
            <div className="order__footer">
              <Formik
                enableReinitialize
                initialValues={{ isAgreed: accept }}
                onSubmit={() => {}}
              >
                {({ values }) => {
                  return (
                    <>
                      <Form>
                        <div className="order__footer-terms">
                          <Checkbox
                            className="terms-acceptance--checkbox"
                            name="isAgreed"
                            checked={accept}
                            onChange={showTermsAndConditions}
                          >
                            <span className="terms-acceptance-label">
                              I have read all the{" "}
                              <b onClick={showTermsAndConditions}>
                                Terms and Conditions
                              </b>{" "}
                              and agree to the same
                            </span>
                          </Checkbox>
                        </div>
                        <div className="order__footer-btn">
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="middle"
                            className="order__footer-decline"
                            disabled={!accept}
                            onClick={() => handleOrder(0)}
                          >
                            Decline
                          </Button>
                          <Button
                            type="primary"
                            htmlType="submit"
                            size="middle"
                            className="order__footer-approve"
                            disabled={!accept}
                            onClick={() => handleOrder(1)}
                          >
                            Approve
                          </Button>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
      {showSuccessAnimation && (
        <div className="success-animation-container">
          <Lottie options={successOption} height={400} width={400} />
          <h2
            className={
              isMobile
                ? "success-animation-text-mobile"
                : "success-animation-text"
            }
          >
            You have successfully placed the order
          </h2>
        </div>
      )}
      {showDeclineAnimation && (
        <div className="decline-animation-container">
          <Lottie options={declineOption} height={300} width={300} />
          <h2
            className={
              isMobile
                ? "decline-animation-text-mobile"
                : "decline-animation-text"
            }
          >
            You have declined the order
          </h2>
        </div>
      )}
    </div>
  );
};

export default termsAndConditionsContainer(PlaceOrder);
