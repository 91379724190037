import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import React, { FC } from "react";
import "./tableComponent.scss"

interface TableComponentProps {
    columns: ColumnsType<any> | undefined,
    className?: string
    dataSource: any[] | undefined
    pagination?: false | TablePaginationConfig | undefined
    loading?: boolean
    onChange?: ((pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: SorterResult<any> | SorterResult<any>[], extra: any) => void)
}

const TableComponent: FC<TableComponentProps> = (props) => {

    const { dataSource,
        columns,
        pagination = false,
        className,
        onChange,
        loading } = props

    return <div className={"table__container " + className}>
        <Table
            loading={loading}
            dataSource={dataSource}
            columns={columns}
            pagination={pagination}
            onChange={onChange}
        />
    </div>
}

export default TableComponent