import React, { FC } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from "react-router-dom";
import * as appRoutes from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import Home from "../views/App/Home";
import Holdings from "../views/App/Holdings";
import { RouterProps } from "../shared/types/route.type";
import CreateAccount from "../views/CreateAccount";
import PlaceOrder from "../views/PlaceOrder";
import TellUsMoreAboutYou from "../views/TellUsMoreAboutYou";
import KYCVerification from "../views/KYCVerification";
import Subscriptions from "../views/Subscriptions";
import Wallet from "../views/App/Wallet";
import AppWrapper from "../views/App/AppWrapper";
import TermsAndContionsModal from "../shared/components/TermsAndContionsModal";

interface Props{
    redirectUrl?:string;
}

const AppRoutes = (
    {
        redirectUrl
    }: Props
) => {
    const routes: RouterProps[] = [
        {
            exact: true,
            path: appRoutes.CREATE_ACCOUNT,
            component: CreateAccount,
        },
        {
            exact: true,
            path: appRoutes.TELL_US_MORE_ABOUT_YOU,
            component: TellUsMoreAboutYou,
        },
        {
            exact: true,
            path: appRoutes.KYC_VERIFICATION,
            component: KYCVerification,
        },
        {
            exact: true,
            path: appRoutes.SUBSCRIPTIONS,
            component: Subscriptions,
        },
        {
            exact: false,
            path: appRoutes.AUTH,
            component: AuthWrapper,
        },
        {
            exact: true,
            path: appRoutes.ORDER,
            component: PlaceOrder,
        },
        {
            exact: false,
            path: appRoutes.APP,
            component: isAuthenticated(AppWrapper),
        },
    ];

    return (
        <div>
            <TermsAndContionsModal />
            <Router>
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            {...route}
                            component={route.component as FC}
                        />
                    ))}
                    <Route
                        path="*"
                        render={() => <Redirect to={appRoutes.LOGIN} />}
                    />
                    {(redirectUrl)? <Redirect to={redirectUrl}/>:null}
                </Switch>
            </Router>
        </div>
    );
};

export default AppRoutes;
