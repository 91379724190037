import { serializable, alias, primitive } from "serializr";

export class NotificationData {
  @serializable(alias("title", primitive()))
  title?: string;

  @serializable(alias("message", primitive()))
  message?: string;

  @serializable(alias("type", primitive()))
  type?: string;
}