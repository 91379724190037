import React, { FC, useState } from "react";
import OnBoarding from "../../shared/components/Layout/OnBoarding";
import OnBoardingContainer from "../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../store/reducers/onBoardingReducer";
import AccountDetailsForm from "./AccountDetailsForm";
import OTPForm from "./OTPForm";
import "./createAccount.scss";

interface CreateAccountProps {}

const CreateAccount: FC<CreateAccountProps> = (props) => {
	const [showOTPScreen, setShowOTPScreen] = useState(false);

	const handleOTPSent = (show: boolean) => {
		setShowOTPScreen(show);
	};
	return (
		<div className="create-account">
			<OnBoarding>
				{showOTPScreen ? (
					<OTPForm otpSendHandler={handleOTPSent} />
				) : (
					<AccountDetailsForm otpSendHandler={handleOTPSent} />
				)}
			</OnBoarding>
		</div>
	);
};

export default CreateAccount;
