import {serializable, alias, primitive} from 'serializr';

export class OTP {
    @serializable(alias('email', primitive()))
    email?: string;

    @serializable(alias('phone', primitive()))
    mobileNumber?: string;

    @serializable(alias('email_otp', primitive()))
    emailOTP?: string;

    @serializable(alias('phone_otp', primitive()))
    mobileOTP?: string;
}

