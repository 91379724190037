import React, { FC, useState } from "react";
import OtpInput from "react-otp-input";
import { Form, Formik, FormikValues } from "formik";
import { Button } from "antd";
import { useHistory } from "react-router";
import { Modal } from 'antd'
import "./otpForm.scss"
import ShareService from "../../../services/SharesService/share.service";
interface OTPFormProps {
	otpSubmissionHandler: (values: FormikValues) => void
	handleModalCancel?: (payload: boolean) => void;
	otpModalVisibility: boolean
	loading: boolean
	autoDividend?: boolean;
	mobileNumber?: string;
	
}

const OTPForm: FC<OTPFormProps> = (props) => {
	const { otpSubmissionHandler, otpModalVisibility, loading, autoDividend, mobileNumber, handleModalCancel} = props;
	const {createAutoDividendOtp} = ShareService();
	const handleCancelModal = () => handleModalCancel && handleModalCancel(false)
	const [resendOTP, setResendOTP] = useState<boolean>(false)

	const handleSubmit = async (values: FormikValues) => {
		otpSubmissionHandler(values);
	};

	const handleSendOTP = () => {
		autoDividend && createAutoDividendOtp();
	};

	const resendOTPDisableHandler = (stateUpdate: React.Dispatch<React.SetStateAction<boolean>>) => {
		stateUpdate(true)
		setTimeout(() => {
			stateUpdate(false)
		}, 60000);
	}

	const formikProps = {
		initialValues: { otp: "" },
		onSubmit: handleSubmit,
	};

	return (
		<Modal
			className="otp-modal"
			destroyOnClose={true}
			title= {autoDividend ? "Confirm Auto Invest Dividend": "Enter OTP sent to your mobile"}
			visible={otpModalVisibility}
			onCancel={handleCancelModal}
			>
			<div className="o-t-p-form">
			<h4 className="otp-form__header">Enter OTP sent to {mobileNumber}</h4>
				<Formik {...formikProps}>
					{({ values, setFieldValue, isValid, dirty }) => (
						<Form>
							
							<div className="otp__container">
								<OtpInput
									containerStyle={{
										justifyContent: "space-between",
									}}
									className="otp__wrapper"
									value={values.otp}
									onChange={(otp: string) =>
										setFieldValue("otp", otp)
									}
									numInputs={6}
								/>
								<div
									className={
										"resend__wrapper" +
										(resendOTP ? " disable-resend" : " cursor-pointer")
									}
									onClick={() => {
										if (!resendOTP) {
											handleSendOTP()
										}
									}}
								>
									Resend OTP
								</div>
							</div>
							<div className="submit-btn">
								<Button
									type="primary"
									htmlType="submit"
									disabled={!isValid || !dirty || values.otp.length < 6}
									loading={loading}
								>
									Verify and Proceed &nbsp;{" "}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>

		</Modal>
	);
};

export default OTPForm;

