import { min } from "moment";
import * as yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

const ALLOWED_FILE_TYPES = [
  'application/pdf',
  'image/png',
  'image/jpeg',
  'image/jpg',
]

export const UploadDocumentIndividualValidation = yup.object().shape({
  photograph: yup
    .mixed()
    .test("type", "Photograph is required", (value) => value)
    .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
    .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
  idProof: yup
    .mixed()
    .test("type", "Id proof is required", (value) => value)
    .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
    .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
  KRAPin: yup
    .mixed()
    .test("type", "KRA pin is required", (value) => value)
    .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
    .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
});

export const UploadDocumentChamaValidation = yup.object().shape({
  photograph: yup
    .mixed()
    .test("type", "Photograph is required", (value) => value)
    .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
    .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
  idProof: yup
    .mixed()
    .test("type", "Id proof is required", (value) => value)
    .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
    .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
  KRAPin: yup
    .mixed()
    .test("type", "KRA pin is required", (value) => value)
    .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
    .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
  chamaPersons: yup.array()
    .min(1)
    .required("Chama person 2 details is required")
    .of(
      yup.object().shape({
        firstName: yup.string()
          .min(3, "First name should atleast have 3 characters")
          .required("First name is required"),
        middleName: yup.string()
          .min(3, "Middle name should atleast have 3 characters")
          .required("Middle name is required"),
        lastName: yup.string()
          .min(3, "Last name should atleast have 3 characters")
          .required("Last name is required"),
        kenyanNationalNumber: yup
          .string()
          .required("Kenyan National Number is required!")
          .min(8, "Kenyan National Number must be 8 characters")
          .max(8, "Kenyan National Number must be 8 characters")
          .matches(REGEX.ALPHANUMERIC, "Kenyan National Number should be numeric"),
        KRAPinNumber: yup
          .string()
          .required("KRA pin is required!")
          .min(11, "KRA pin must be 11 characters")
          .max(11, "KRA pin must be 11 characters")
          .matches(REGEX.ALPHANUMERIC, "KRA pin should not include special characters"),
        photograph: yup
          .mixed()
          .test("type", "Photograph is required", (value) => value)
          .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
          .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
        idProof: yup
          .mixed()
          .test("type", "Id proof is required", (value) => value)
          .test('fileSize', "File should be less than 5 MB", value => value?.size <= 5000000)
          .test('fileType', "Please upload pdf or jpeg or png files", value => ALLOWED_FILE_TYPES.includes(value?.type)),
      }),
    )
});
