import { REQUEST_LOGOUT } from './../definitions/authConstants';
import { AUTHENTICATED, SET_CUSTOMER, SET_USER, UNAUTHENTICATED } from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { User } from "../../models/User/user.model";
import { ActionProps } from "../../shared/types/action.type";
import { Customer } from "../../models/Customer/customer.model";
import LocalStorage from '../../shared/LocalStorage';

export interface AuthState {
    authenticated: boolean;
    user?: User;
    customer?: Customer;
}

export interface AuthReducerProps extends AuthState {
    setAuthenticated: (user: User) => ActionProps;
    setUnAuthenticated: () => ActionProps;
    setUser: (user: User) => ActionProps;
    setCustomer: (customer: Customer) => ActionProps;
}

const initState: AuthState = {
    authenticated: false,
    user: undefined,
    customer: undefined,
};

const authReducer = CreateReducer(initState, {
    [AUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
        const { authenticated, user } = action?.payload;
        return {
            ...state,
            authenticated,
            user
        };
    },
    [UNAUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
        const { authenticated } = action?.payload;
        return { ...state, authenticated };
    },
    [REQUEST_LOGOUT](state: AuthState, action: ActionProps): AuthState {
        LocalStorage.clear()
        return { ...state, authenticated: false };
    },
    [SET_USER](state: AuthState, action: ActionProps): AuthState {
        const { user } = action.payload;
        return {
            ...state,
            user
        }
    },
    [SET_CUSTOMER](state: AuthState, action: ActionProps): AuthState {
        const { customer } = action.payload;
        return {
            ...state,
            customer
        }
    }
});

export default authReducer;



