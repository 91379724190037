import { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { Country } from "../../models/Country/country.model";

const CountryService = () => {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState<Country[]>();

  const getCountries = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(ApiRoutes.COUNTRIES);
      const data = deserialize(
        Country,
        response?.data["countries"]
      ) as Country[];
      setCountries(data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return {
    getCountries,
    loading,
    countries,
  };
};

export default CountryService;
