import React, { FC } from "react";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { ErrorMessage, Field, useField, useFormikContext } from "formik";
import Error from "../Error";
import "react-phone-input-2/lib/high-res.css";
import "./phoneNumber.scss";

interface PhoneNumberFieldProps {
  name: string;
  title?: string;
  onChange?: (value: string, data: CountryData) => void;
  placeholder?: string;
  country?:string
}

const PhoneNumberField: FC<PhoneNumberFieldProps> = (props) => {
  const { name, title, onChange, placeholder, country } = props;

  const [field, meta, helpers] = useField(name);

  const { setFieldValue, errors } = useFormikContext();

  const countryChange = (value: string) => {
    setFieldValue(name, value, true);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
  };

  return (
    <div className="phone-number mb-4">
      {title && <div className="field-title">{title}</div>}
      <Field>
        {() => (
          <PhoneInput
            countryCodeEditable={false}
            onChange={(value: string, data: CountryData) => {
              countryChange(value);
              onChange && onChange(value, data);
            }}
            onBlur={handleBlur}
            disableCountryGuess
            value={field.value}
            placeholder={placeholder}
            country={country}
          />
        )}
      </Field>
      <div className="error-holder">
        <ErrorMessage name={name}>
          {(message: string) => <Error message={message} />}
        </ErrorMessage>
      </div>
    </div>
  );
};

export default PhoneNumberField;
