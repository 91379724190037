import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginForm from "../../views/Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";

const authRouter = () => {
	const routes: RouterProps[] = [
		{ exact: true, path: AppRoutes.LOGIN, component: LoginForm },
		{ exact: true, path: AppRoutes.FORGOT_PASSWORD, component: ForgotPassword },
		{ exact: true, path: AppRoutes.RESET_PASSWORD, component: ResetPassword },
	];

	return (
		<Switch>
			{routes.map(({ component, ...routerProps }) => (
				<Route {...routerProps} component={component as FC} />
			))}
			<Redirect exact strict from={AppRoutes.AUTH} to={AppRoutes.LOGIN} />
		</Switch>
	);
};

export default authRouter;
