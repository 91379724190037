import * as yup from "yup";
import { REGEX } from "../../../../shared/utils/regex";

export const beneficiaryDetailsValidation = yup.object().shape({
    name: yup
        .string()
        .required("Beneficiary Name is required!"),
    relation: yup
        .string()
        .required("Beneficiary Relation is required!"),
    phone: yup
        .string()
        .matches(REGEX.NUMERIC, "Beneficiary Phone number should be of numeric and should not start with 0")
        .min(9, "Beneficiary Phone number must be at least 9 characters")
        .max(10, "Beneficiary Phone number must have maximum 10 characters")
        .required("Beneficiary Phone number is required!"),
    kenyanNationalNumber: yup
        .string()
        .required("Kenyan National Number is required!")
        .min(1, "Kenyan National Number must be 8 characters")
        .max(8, "Kenyan National Number must be 8 characters")
        .matches(REGEX.NUMERIC_WITHOUT_ZERO_VALIDATION, "Kenyan National Number should be numeric"),
    // isAlien: yup
    //     .boolean()
    //     .required("Please select proof type")
})