import { alias, list, primitive, serializable } from "serializr";

export class Payment {
    @serializable(alias('amount', primitive()))
    amount?: number

    @serializable(alias('phone', primitive()))
    mobileNumber?: number

    @serializable(alias('dial_code', primitive()))
    dialCode?: number = 254

	@serializable(alias('status_url', primitive()))
	dynamicUrl?: string;

}