import Modal from "antd/lib/modal/Modal";
import React, { FC, useEffect, useState } from "react";
import InformationService from "../../../services/InformationService/information.service";
import termsAndConditionsContainer from "../../../store/container/termsAndconditionsContainer";
import { TermsAndConditionsReducerProps } from "../../../store/reducers/termsAndConditionsReducer";
import LocalStorage from "../../LocalStorage";
import "./termsAndContionsModal.scss";

interface TermsAndConditionsModalProps extends TermsAndConditionsReducerProps {
}

const TermsAndContionsModal: FC<TermsAndConditionsModalProps> = (
    props
) => {
    const { show, noPrompt, acceptTermsAndConditions, rejectTermsAndConditions } = props;

    const { getTermsAndConditions, termsAndContions, loading } = InformationService();

    const title = "Terms And Conditions";

    useEffect(() => {
        getTermsAndConditions();
    }, []);

    const onOk = () => {
        LocalStorage.setItem("has-accepted-terms-and-conditions", true);
        acceptTermsAndConditions();
    };
    const onCancel = () => {
        LocalStorage.setItem("has-accepted-terms-and-conditions", false);
        rejectTermsAndConditions();
    };

    return (
        <Modal
            footer={noPrompt?null:undefined}
            className={"terms-and-conditions-modal"}
            title={title}
            centered
            destroyOnClose
            visible={show}
            onOk={onOk}
            onCancel={onCancel}
            width={"90%"}
            maskClosable={noPrompt}
            okText="Accept"
            cancelText="Reject"
            confirmLoading={loading}
            // maskStyle={maskStyle}
            closable={noPrompt}
            zIndex={1000000000}
        >
            {/* <iframe src="" ref=> */}
                <div
                    dangerouslySetInnerHTML={{ __html: termsAndContions ?? "" }}
                />
            {/* </iframe> */}
        </Modal>
    );
};

export default termsAndConditionsContainer(TermsAndContionsModal);