import { Switch, Row, Col } from "antd";
import React, { Dispatch, useState } from "react";
import { Customer } from "../../../models/Customer/customer.model";
import { User } from "../../../models/User/user.model";
import SubscriptionService from "../../../services/SubscriptionService/subscription.service";
import MailIcon from "./../../../assets/icons/multi color icons - svg/mail.svg"
import PhoneIcon from "./../../../assets/icons/multi color icons - svg/phone.svg"
import "./profileBasicDetailsCard.scss"


interface ProfileBasicDetailsCardProps {
    customer: Customer
    user: User
}

const ProfileBasicDetailsCard = (props: ProfileBasicDetailsCardProps) => {
    const { setAutoRenewal } = SubscriptionService();
    const [autoRenewalEnabled, setAutoRenewalEnabled] = useState(false);
    const [renewalDisabled, setRenewalDisabled] = useState(false);
    const {
        customer,
        user,
    } = props
    const changeAutoRenewal = async (value: any) => {
        setRenewalDisabled(true);
        const res = await setAutoRenewal(value);
        if (res === 200) {
            setAutoRenewalEnabled(value);

        } else {
            setAutoRenewalEnabled(!value);
        }
        setRenewalDisabled(false);
    }


    return <div className="iprs-details">
        <div className="iprs-details__header">
            <p className={`iprs-details--title ${!user.kycBasicInfo?.isFromIprs && 'reduced-opacity'}`}>
                {user.kycBasicInfo?.isFromIprs ? `${user?.kycBasicInfo?.firstName?.toLowerCase()} ${user?.kycBasicInfo?.lastName?.toLowerCase()} ${user?.kycBasicInfo?.middleName?.toLowerCase()}` : 'Member Name'}
            </p>
            <p className="iprs-details--mail"><img src={MailIcon} alt="mail" /> {user?.email}</p>
            <p className="iprs-details--phone"><img src={PhoneIcon} alt="call" /> {user?.mobileNumber}</p>
            {/* Auto renew subscription here */}
            {/* <Row>
                <Col span={18}>
                    <p className="iprs-details--mail"> Auto Renew</p>
                </Col>
                <Col span={4}>
                    <Switch defaultChecked={autoRenewalEnabled} disabled={renewalDisabled} onChange={changeAutoRenewal} />
                </Col>
            </Row> */}
            {/* <p className="iprs-details--phone">Auto Renew  </p> */}
        </div>
        {!!user.kycBasicInfo?.isFromIprs
            ? <div className="iprs-details__body">
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Type</div>
                    <div className="iprs-details--content-value">{user?.role || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Member ID</div>
                    <div className="iprs-details--content-value">{user?.uid?.split("-").pop() || "-"}</div>
                </div>
                {/* <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Invitation Code</div>
                    <div className="iprs-details--content-value">{user?.inviteCode || "-"}</div>
                </div> */}
                {/* <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Member Since</div>
                    <div className="iprs-details--content-value">{user?.memberSince || "-"}</div>
                </div> */}
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">ID Number</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.kenyanNationalNumber?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Gender</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.gender?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">DOB</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.dateOfBirth?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">District of Birth</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.districtOfBirth?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Place of Issue</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.placeOfIssue?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Date of Issue</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.dateOfIssue?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">District</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.district?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Division</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.division?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Location</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.location?.toLowerCase() || "-"}</div>
                </div>
                <div className="iprs-details--content">
                    <div className="iprs-details--content-title">Sub-location</div>
                    <div className="iprs-details--content-value">{user?.kycBasicInfo?.subLocation?.toLowerCase() || "-"}</div>
                </div>

            </div>
            : <div className="iprs-details__empty">
                <p className="icon--container">
                    <i className="icon-ic-info-24px"></i>
                </p>
                <p>Details Fetch Failed</p>
                {/* <p className="modal-trigger">KRA Pin: {customer?.KRAPinNumber}</p>
                <p className="modal-trigger">Kenyan National ID: {customer?.kenyanNationalNumber}</p> */}
            </div>
        }
    </div>

}

export default ProfileBasicDetailsCard