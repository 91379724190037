import React, { FC, useEffect, useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { validationSchema } from "./LoginValidation";
import { Button } from "antd";
import AuthService from "../../../services/AuthService/auth.service";
import { useHistory, withRouter } from "react-router-dom";
import OnBoarding from "../../../shared/components/Layout/OnBoarding";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/login.png";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import LockIcon from "../../../assets/icons/icon.svg";
import "./login.scss";
import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import KYCService from "../../../services/KYCService/kyc.service";

interface User {
  email: string;
  password: string;
}

const initialValue = {
  email: "",
  password: "",
};

interface LoginFormProps extends OnBoardingReducerProps { }

const LoginForm: FC<LoginFormProps> = (props) => {
  const { setCurrentImage, setCurrentText } = props;

  const { user, error, loading, loginUser, requestResetPasswordLink } = AuthService();
  const { getStepperStatus, loading: stepLoading } = KYCService();

  const history = useHistory();

  const [passwordExpired, setPasswordExpired] = useState<boolean>(false)

  useEffect(() => {
    setPasswordExpired(false)
    setCurrentImage(BackgroundImage);
    setCurrentText("Your First Step to Financial Freedom");
    return () => {
      setCurrentText("");
    };
  }, []);

  const getExactTab = (stepNumber: number) => {
    if (stepNumber === 0) {
      history.push(AppRoutes.TELL_US_MORE_ABOUT_YOU);
    } else if (stepNumber > 0 && stepNumber < 5) {
      history.push(AppRoutes.KYC_VERIFICATION);
    }
  };

  const onSubmit = async (user: User) => {
    try {
      await loginUser(user);
      const stepData = await getStepperStatus();
      if (!stepData) {
        return;
      }
      if (
        stepData?.isSubscribed ||
        (stepData?.isKycCompleted && stepData?.isKycVerified)
      ) {
        history.push(AppRoutes.HOME);
      } else if (stepData?.isKycCompleted) {
        history.push(AppRoutes.SUBSCRIPTIONS);
      } else if (stepData.step !== undefined && +stepData?.step >= 0) {
        return getExactTab(
          typeof stepData?.step === "string"
            ? parseInt(stepData?.step)
            : stepData.step
        );
        // return getExactTab(2)
      }
    } catch (error) {
      const passwordExpired = { ...error as any }['response']["data"]["password_expiry"]
      passwordExpired &&
        await requestResetPasswordLink(user?.email, true) &&
        setPasswordExpired(true)
    }
  };

  const handleRegister = () => {
    history.push(AppRoutes.CREATE_ACCOUNT);
  };

  return (
    <div className="login">
      <OnBoarding>
        <Formik
          initialValues={initialValue}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <div className="login__logo"></div>
            <h1 className="text-primary text-center">Login to your account</h1>
            <InputField
              title="Email"
              type="email"
              name="email"
              placeholder="Enter email"
            />
            <InputField
              title="Password"
              type="password"
              className={"mb-0"}
              name="password"
              placeholder="Enter password"
            />
            {passwordExpired && <span className="input__error">Your password is expired. Password reset link has been sent to your mail id</span>}
            <div className=" text-right text-primary mt-5 mb-2">
              <span
                className=" cursor-pointer"
                onClick={() => history.push(AppRoutes.FORGOT_PASSWORD)}
              >
                Forgot Password?
              </span>
            </div>
            <div className="submit-btn mt-5">
              <Button
                className="loginBtn"
                type="primary"
                htmlType="submit"
                size="middle"
                loading={loading || stepLoading}
              >
                {!loading && !stepLoading && <img src={LockIcon} alt="" />}{" "}
                &nbsp; Login
              </Button>
            </div>
            {/* <p className="text-center text-primary mt-5 mb-2">
                            Don't have an account?{" "}
                            <span
                                className="text-link cursor-pointer login-link"
                                onClick={handleRegister}
                            >
                                Create Account
                            </span>
                        </p> */}
            <TermsAndPolicy />
          </Form>
        </Formik>
      </OnBoarding>
    </div>
  );
};

export default OnBoardingContainer(LoginForm);
