import * as yup from "yup";
import { REGEX } from "../../../shared/utils/regex";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const createAccountValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email filed is required"),
  mobileNumberWithCode: yup
    .string()
    .required("Phone number is required")
    .test("isValidPhoneNumber", "Invalid phone number", function (value) {
      if (!value) return false;

      const dialCode = this.parent.dialCode;
      const phoneNumber = this.parent.mobileNumber;
      const withCountryCode = `+${dialCode}${phoneNumber}`;

      if (dialCode === "254") {
        if (!phoneNumber || phoneNumber.length < 9) {
          return this.createError({
            message: "Mobile number must be at least 9 characters",
          });
        }
        if (phoneNumber.length > 10) {
          return this.createError({
            message: "Mobile number must have maximum 10 characters",
          });
        }

        return isPossiblePhoneNumber(withCountryCode);
      } else {
        return isPossiblePhoneNumber(withCountryCode);
      }
    }),
  password: yup
    .string()
    .min(8, "Password has to be longer than 8 characters!")
    .required("Password is required!")
    .matches(
      REGEX.PASSWORD,
      "One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm password is required!")
    .oneOf([yup.ref("password"), ""], "Passwords must match"),
  countryId: yup.string().required("Country is required!"),
});
