
import { Button } from "antd";
import React, { FC, useEffect, useRef, useState } from "react";
import CloseIcon from "../../../../assets/icons/close.svg";
import Placeholder from "../../../../assets/icons/logo - svg/vuka-logo-dark.svg";
import KYCService from "../../../../services/KYCService/kyc.service";
import OnBoardingContainer from "../../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../../store/reducers/onBoardingReducer";
// import { NotificationTypes } from "../../../../enums/notificationTypes";
// import Notification from "../../../../shared/components/Notification";
import "./videoRecorder.scss";
// @ts-ignore
import VideoRecorderPlus from 'react-video-recorder'
// import { BlobServiceClient, ContainerClient } from "@azure/storage-blob";

let recordingTimeMS = 10000;
let clearTimeout: any;

interface VideoRecorderProps extends OnBoardingReducerProps {
	closeVideoHandler: (close: boolean) => void;
	disableClose?: boolean
	afterResubmit?: Function
}

function wait(delayInMS: number) {
	return new Promise((resolve) => setTimeout(resolve, delayInMS));
}

// function startRecording(stream: MediaStream, lengthInMS: number) {
// 	let recorder = new MediaRecorder(stream);
// 	let data: any = [];

// 	recorder.ondataavailable = (event) => data.push(event.data);
// 	recorder.start();

// 	let stopped = new Promise((resolve, reject) => {
// 		recorder.onstop = resolve;
// 		recorder.onerror = (event: any) => reject(event.name);
// 	});

// 	let recorded = wait(lengthInMS).then(
// 		() => recorder.state == "recording" && recorder.stop()
// 	);

// 	return Promise.all([stopped, recorded]).then(() => data);
// }

const VideoRecorder: FC<VideoRecorderProps> = (props) => {
	const { closeVideoHandler, setKycStep, afterResubmit = () => { }, disableClose } = props;

	const [showCode, setShowCode] = useState(false);

	const [submit, setSubmit] = useState(false);

	// const [cameraReady, setCameraReady] = useState(false);

	const [videoFile, setVideoFile] = useState<File>();

	const [timer, setTimer] = useState<number>(10)

	const { getKYCVideoCode, uploadKYCVideo, videoCode, loading } = KYCService();

	// const videoRef: any = useRef<any>(null);

	const recorderRef: any = useRef<any>(null);

	useEffect(() => {
		// openCamera();
		getKYCVideoCode()
	}, []);

	// const openCamera = () => {
	// 	try {
	// 		navigator.mediaDevices
	// 			?.getUserMedia({
	// 				video: true,
	// 				audio: true,
	// 			})
	// 			.then((stream) => {
	// 				setCameraReady(true)
	// 				videoRef.current.srcObject = stream;
	// 				videoRef.current.captureStream =
	// 					videoRef.current.captureStream ||
	// 					videoRef.current.mozCaptureStream;
	// 				return new Promise(
	// 					(resolve) => (videoRef.current.onplaying = resolve)
	// 				);
	// 			})
	// 			.catch(err => {
	// 				Notification({
	// 					message: "",
	// 					description: "Please allow camera and microphone permissions in your browser to proceed further",
	// 					type: NotificationTypes.INFO,
	// 				});
	// 			});
	// 	} catch (error) {
	// 	}
	// };

	const stop = () => {
		recorderRef.current?.handleStop()
		recorderRef.current?.turnOffCamera()
		recorderRef.current?.turnOnCamera()
		// const tracks = videoRef.current.srcObject
		// 	?.getTracks()
		// tracks?.forEach((track: any) => {
		// 	track.stop()
		// })
		// videoRef.current.srcObject = null
		// setSubmit(true);
		// setShowCode(false);
		// setKycStep(3)
		// afterResubmit()
	}

	const handleStartRecording = () => {
		recorderRef.current?.handleStartRecording()
		// if (!cameraReady) {
		// 	return openCamera()
		// }
		setShowCode(true);
		setSubmit(false);

		wait(3000).then(() => {
			clearTimeout = setInterval(() => {
				setTimer(prev => {
					const remaining = prev - 1
					if (remaining >= 0) {
						return remaining
					}
					return 0
				})
			}, 1000);


			wait(recordingTimeMS).then(() => {
				setShowCode(false);
				setSubmit(true);
				stop()
			})
		})
		// try {
		// 	setShowCode(true);
		// 	if (videoRef.current?.captureStream && !videoRef.current?.mozCaptureStream) {
		// 		startRecording(videoRef.current?.captureStream(), recordingTimeMS)
		// 			.then((recordedChunks) => {
		// 				console.log("Recorded...");
		// 				let recordedBlob = new Blob(recordedChunks, {
		// 					type: "video/webm",
		// 				});
		// 				videoRef.current.src = URL.createObjectURL(recordedBlob);
		// 				const mp4VideoFile = new File([recordedBlob], 'verification.webm', { type: 'video/webm', lastModified: Date.now() });
		// 				setVideoFile(mp4VideoFile)
		// 				setShowCode(false);
		// 				setSubmit(true);
		// 				// openCamera();
		// 			})
		// 			.catch(err => {
		// 				console.log(err);
		// 			});
		// 	} else if (videoRef.current?.mozCaptureStream) {
		// 		startRecording(videoRef.current?.mozCaptureStream(), recordingTimeMS)
		// 			.then((recordedChunks) => {
		// 				console.log("Mozi Recorded...");
		// 				let recordedBlob = new Blob(recordedChunks, {
		// 					type: "video/webm",
		// 				});
		// 				videoRef.current.src = URL.createObjectURL(recordedBlob);
		// 				const mp4VideoFile = new File([recordedBlob], 'verification.webm', {
		// 					type: 'video/webm',
		// 					lastModified: Date.now()
		// 				});
		// 				setVideoFile(mp4VideoFile)
		// 				setShowCode(false);
		// 				setSubmit(true);
		// 				// openCamera();
		// 			})
		// 			.catch(err => {
		// 				console.log(err);
		// 			});
		// 	} else {
		// 		navigator.mediaDevices
		// 			?.getUserMedia({
		// 				video: {
		// 					width: 854,
		// 					height: 480,
		// 					frameRate: 20
		// 				},
		// 				audio: true,

		// 			})
		// 			.then((stream: MediaStream) => {
		// 				let recorder = new MediaRecorder(stream);
		// 				let data: any = [];

		// 				recorder.ondataavailable = (event) => data.push(event.data);
		// 				recorder.start();

		// 				let stopped = new Promise((resolve, reject) => {
		// 					recorder.onstop = resolve;
		// 					recorder.onerror = (event: any) => reject(event.name);
		// 				});

		// 				let recorded = wait(recordingTimeMS).then(
		// 					() => recorder.state == "recording" && recorder.stop()
		// 				);

		// 				return Promise.all([stopped, recorded]).then(() => data);

		// 			})
		// 			.then((recordedChunks) => {

		// 				let recordedBlob = new Blob(recordedChunks, {
		// 					type: "video/mp4",
		// 				});
		// 				videoRef.current.src = URL.createObjectURL(recordedBlob);
		// 				const mp4VideoFile = new File([recordedBlob], 'verification.mp4', { type: 'video/mp4', lastModified: Date.now() });
		// 				setVideoFile(mp4VideoFile)
		// 				setShowCode(false);
		// 				setSubmit(true);
		// 				// openCamera();
		// 			})
		// 			.catch(err => {
		// 				console.log(err);

		// 				const tracks = videoRef.current.srcObject
		// 					?.getTracks()
		// 				tracks?.forEach((track: any) => {
		// 					track.stop()
		// 				})
		// 				videoRef.current.srcObject = null
		// 				closeVideoHandler(false)
		// 				Notification({
		// 					message: "",
		// 					description: "Your browser doesn't support media recording. Please enable it in settings or try again with another browser",
		// 					type: NotificationTypes.ERROR,
		// 				});
		// 			});
		// 	}
		// } catch (error) {
		// 	alert(JSON.stringify(error));

		// }
	};


	const handleNext = async () => {
		if (!videoFile) { return }

		try {
			await uploadKYCVideo({
				code: videoCode,
				verificationVideo: videoFile
			})


			stop()
			setKycStep(3)
			afterResubmit()
		} catch (error) {

		}

	};
	console.log(recorderRef)
	return (
		<div className="video-recorder">
			<div className="header">
				<img src={Placeholder} alt="" className="logo" />
				<p>Video KYC</p>
				{!disableClose ? <img
					src={CloseIcon}
					alt=""
					className="cursor-pointer"
					onClick={() => closeVideoHandler(false)}
				/> : <span></span>}
			</div>
			{/* <video
				muted
				autoPlay
				playsInline
				height="100vh"
				width="100vw"
				ref={videoRef}
			/> */}
			<VideoRecorderPlus
				ref={recorderRef}
				isOnInitially={true}
				// showReplayControls={true}
				// videoControlsList="nofullscreen nodownload noremoteplayback noplaybackrate nopicture"
				// disablepictureinpicture={true}
				replayVideoAutoplayAndLoopOff={true}
				//@ts-ignore
				onRecordingComplete={async (videoBlob) => {
					const mp4VideoFile = new File([videoBlob], 'verification.webm', { lastModified: Date.now() });
					setVideoFile(mp4VideoFile)
				}}
			/>
			{/* {videoFile &&
				<video
					src={videoFile as any}
					style={{
						position: "fixed",
						zIndex: 10000000
					}}
				/>
			} */}
			<div className="recorder-panel">
				{showCode && (
					<div className="code__wrapper">
						<p>Speak the code below</p>
						<h2>{videoCode}</h2>
					</div>
				)}
				<div className="video-controller__wrapper">
					{submit ? (
						<div className="kyc-video__controller">
							<Button type="text" onClick={() => {
								clearInterval(clearTimeout)
								setTimer(10)
								setShowCode(false)
								setSubmit(false)
								handleStartRecording()
							}}>
								Retake
							</Button>
							<Button
								type="primary"
								className="submit-kyc"
								onClick={handleNext}
								loading={loading}
							>
								Submit KYC
							</Button>
						</div>
					) : showCode ? null
						// (
						//  <i
						//      className="icon-start-video-kyc video-icon"
						//      onClick={stop}
						//  >
						//      <i className="icon-stop-video-kyc-box" />
						//  </i>
						// ) 
						: (
							<i
								className="icon-start-video-kyc video-icon"
								onClick={handleStartRecording}
							/>
						)}
				</div>
				<p className={`${submit && "display-hidden"}`}>
					{
						!showCode
							? 'Tap to start recording'
							: `Recording will stop in ${timer} seconds`
					}
				</p>
			</div>
		</div>
	);
};

export default OnBoardingContainer(VideoRecorder);