import { Button } from "antd";
import { Formik, Form, FormikValues, validateYupSchema } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import InputField from "../../../shared/components/InputField";
import OnBoarding from "../../../shared/components/Layout/OnBoarding";
import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/login.png";
import { useHistory, withRouter } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import "./forgotPassword.scss"
import AuthService from "../../../services/AuthService/auth.service";
import { forgotPasswordValidationSchema } from "../LoginForm/LoginValidation";
import SuccessImage from "./../../../assets/icons/resent-password.svg"

interface ForgotPasswordProps extends OnBoardingReducerProps { }

const ForgotPassword = (props: ForgotPasswordProps) => {

    const { setCurrentImage, setCurrentText } = props;
    const [buttonLoading, setButtonLoading] = useState(false);

    const history = useHistory();

    const [mailId, setMailId] = useState<string>()

    const {
        requestResetPasswordLink,
    } = AuthService()

    useEffect(() => {
        setCurrentImage(BackgroundImage);
        setCurrentText("Your First Step to Financial Freedom");
        return () => {
            setCurrentText("");
        };
    }, []);

    const submitHandler = async (values?: FormikValues) => {
        
        if (!values?.email && !mailId) { return }
        try {
            setButtonLoading(true);
            await requestResetPasswordLink(values?.email || mailId)
            if (!mailId) {
                setMailId(values?.email)
            }
        } catch (error) {

        }finally{
            setButtonLoading(false);
        }
    }

    return <div className="forgot-password">
        <OnBoarding>
            {mailId
                ? <Fragment>
                    <h1 className="text-primary text-center">
                        Check your Email
                    </h1>
                    <p className="helper-text text-center">
                        Please check your inbox and click on the link to reset your password
                    </p>
                    <img className="password--success" src={SuccessImage} alt="" />
                    <p className="text-center text-primary mt-5 mb-2">
                        Didn’t receive?{" "}
                        <span
                            className="text-link cursor-pointer login-link"
                            onClick={submitHandler}
                        >
                            Resend
                        </span>
                    </p>
                </Fragment>

                : <Formik
                    initialValues={{ email: "" }}
                    onSubmit={submitHandler}
                    validationSchema={forgotPasswordValidationSchema}
                >
                    <Form>
                        <h1 className="text-primary text-center">
                            Forgot Password
                        </h1>
                        <p className="helper-text text-center">Enter Registered Email below to receive password reset instruction</p>
                        <InputField
                            title="Registered Email"
                            type="email"
                            name="email"
                            placeholder="Enter email"
                        />
                        <div className="submit-btn mt-5">
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="middle"
                                loading={buttonLoading}
                            >
                                &nbsp; Send Reset Link
                            </Button>
                        </div>
                        <p className="text-center text-primary mt-5 mb-2">
                            Remember Password?{" "}
                            <span
                                className="text-link cursor-pointer login-link"
                                onClick={() => history.push(AppRoutes.LOGIN)}
                            >
                                Login Here
                            </span>
                        </p>
                    </Form>
                </Formik>}
        </OnBoarding>
    </div>

}

export default OnBoardingContainer(ForgotPassword)