import { Divider } from "antd"
import React from "react"
import "./bankTransferInstructions.scss"

interface BankTransferInstructionsProps {
    customerId: string | number
}

const BankTransferInstructions = (props: BankTransferInstructionsProps) => {
    const {
        customerId,
    } = props
    return (
        <div className="bank-transfer-innstructions__container">
            <p className="bank-transfer-innstructions--text">To deposit into your Vuka account via bank transfer, kindly use the below details and email us a proof of payment alongside your member ID ({customerId}) to <span className="text-primary">care@vuka.co.ke</span></p>
            <div className="bank-transfer-innstructions--list">
                <p>
                    <span className="title">Bank Name</span>
                    <span className="value">NCBA Bank</span>
                </p>

                <p>
                    <span className="title">Account Name</span>
                    <span className="value">Acorn Capital Partners LLP</span>
                </p>

                <p>
                    <span className="title">Account Number</span>
                    <span className="value">5001660013</span>
                </p>

                <p>
                    <span className="title">Swift Code</span>
                    <span className="value">CBAFKENX</span>
                </p>

                <p>
                    <span className="title">Bank Code</span>
                    <span className="value">07</span>
                </p>

                <p>
                    <span className="title">Branch Code</span>
                    <span className="value">000</span>
                </p>

                <p>
                    <span className="title">Reference</span>
                    <span className="value">{customerId}</span>
                </p>
            </div>
            <p className="bank-transfer-innstructions--info"><i className="icon-ic-info-24px" />The amount paid will be transferred to your wallet</p>
        </div>
    )
}

export default BankTransferInstructions