import React, { FC, useEffect, useState } from "react";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/editCustomer.png";
import "./videoKYC.scss";
import VideoInstructions from "./VideoInstructions";
import VideoRecorder from "./VideoRecorder";

interface VideoKYCProps extends OnBoardingReducerProps {}

const VideoKYC: FC<VideoKYCProps> = (props) => {
	const { setCurrentImage, setCurrentText } = props;

	const [startVideo, setStartVideo] = useState(false);

	useEffect(() => {
		setCurrentImage(BackgroundImage);
		setCurrentText("Earn with Ease");
	}, []);

	const handleStartVideo = (start: boolean) => {
		setStartVideo(start);
	};

	return (
		<div className="video-k-y-c">
			{startVideo ? (
				<VideoRecorder closeVideoHandler={handleStartVideo}/>
			) : (
				<VideoInstructions startVideoHandler={handleStartVideo} />
			)}
		</div>
	);
};

export default OnBoardingContainer(VideoKYC);
