import { NotificationData } from "./notificationData.model";

import { serializable, alias, object, primitive } from "serializr";

export class Notifications {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("data", object(NotificationData)))
  data?: NotificationData;

  @serializable(alias("read_at", primitive()))
  readAt?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;
}