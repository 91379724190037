
/* Auth Routes */
export const AUTH = "/auth"
export const REGISTER = AUTH + "/register"
export const LOGIN = AUTH + "/login"
export const CREATE_ACCOUNT = "/create-account";
export const RESET_PASSWORD = AUTH + "/reset-password";
export const FORGOT_PASSWORD = AUTH + "/forgot-password";
export const TELL_US_MORE_ABOUT_YOU = "/tell-us-more-about-you";
export const KYC_VERIFICATION = "/kyc-verification";
export const SUBSCRIPTIONS = "/subscription-plans";
export const ORDER = "/order";

/* Home Route */
export const APP = "/app"
export const HOME = APP + "/home"
export const HOLDINGS = APP + "/holdings"
export const WALLET = APP + "/wallet"
export const REPORT = APP + "/report"
export const PROFILE = APP + "/profile"
export const REFER = APP + "/refer"
export const FAQ = APP + "/faq"
export const REACH_US = APP + "/reach-us"