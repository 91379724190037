import React, { useState } from "react";
import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Notifications } from "../../models/Notification/Notification.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

const NotificationService = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notifications[]>(
    []
  );

  const fetchNotifications = async () => {
    try {
      setLoading(true);
      setButtonLoading(true);
      const response = await axiosInstance.get(ApiRoutes.APP_NOTIFICATIONS);

      const data = deserialize(
        Notifications,
        response.data.notifications
      ) as Notifications[];
      setNotifications(data);
      setLoading(false);
    } catch (error) {}
  };

  const markAllAsRead = async () => {
    try {
      const response = await axiosInstance.put(ApiRoutes.MARK_ALL_AS_READ);
      Notification({
        message: response.data.status,
        description: response?.data.message,
        type:
          response.status === 200
            ? NotificationTypes.SUCCESS
            : NotificationTypes.ERROR,
      });
      return response.status;
    } catch (error) {}
  };

  const markOneAsRead = async (params: { id: string }) => {
    try {
      const { id } = params;
      const response = await axiosInstance.put(`${ApiRoutes.MARK_READ}/${id}`);
      Notification({
        message: response.data.status,
        description: response?.data.message,
        type:
          response.status === 200
            ? NotificationTypes.SUCCESS
            : NotificationTypes.ERROR,
      });
      return response.status;
    } catch (error) {}
  };
  return {
    fetchNotifications,
    notifications,
    buttonLoading,
    loading,
    markAllAsRead,
    markOneAsRead,
  };
};

export default NotificationService;