import { Button, Radio } from "antd";
import { Form, Formik } from "formik";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";
import BackArrow from "../../../assets/icons/backArrow.svg";
import BackgroundImage from "../../../assets/images/editCustomer.png";
import { BankDetail, Customer } from "../../../models/Customer/customer.model";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import KYCService from "../../../services/KYCService/kyc.service";
import DatePicker from "../../../shared/components/DatePicker";
import DropdownField from "../../../shared/components/DropdownField";
import InputField from "../../../shared/components/InputField";
import RadioField from "../../../shared/components/RadioField";
import { OptionProps } from "../../../shared/types/option.type";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import "./bankDetailsForm.scss";
import { bankDetailsValidation } from "./validation";

interface BankDetailFromProps extends OnBoardingReducerProps { }

const AccountTypeOptions = [
	{
		label: "Savings",
		value: true,
	},
	{
		label: "Current",
		value: false,
	},
];

const BankDetailFrom: FC<BankDetailFromProps> = (props) => {
	const { setCurrentImage } = props;

	const history = useHistory();

	const { updateKycBankDetails, loading } = KYCService();

	useEffect(() => {
		setCurrentImage(BackgroundImage);
	}, []);

	const handleSubmit = async (values: BankDetail) => {
		const bankDetails = Object.assign(new BankDetail(), values);
		if (await updateKycBankDetails(bankDetails)) {
			history.push(AppRoutes.SUBSCRIPTIONS);
		}
	};

	const formikProps = {
		initialValues: { ...new BankDetail(), confirmAccountNumber: "" },
		validationSchema: bankDetailsValidation,
		onSubmit: handleSubmit,
	};

	return (
		<div className="bank-detail-from">
			<h1 className="text-center text-primary">Bank Details</h1>
			<Formik {...formikProps}>
				{({ values, setFieldValue, isValid, dirty }) => (
					<Form>
						<InputField
							type="text"
							name="accountNumber"
							title="Account number"
						/>
						<InputField
							type="text"
							name="confirmAccountNumber"
							title="Confirm Account number"
						/>
						<InputField
							type="text"
							name="bankName"
							title="Bank Name"
						/>
						<InputField
							type="text"
							name="branchName"
							title="Branch Name"
						/>

						<RadioField
							title="Account Type"
							name="isSavingsAccount"
							value={values.isSavingsAccount}
							options={AccountTypeOptions}
							setFieldValue={setFieldValue}
						/>
						<div className="submit-btn">
							<Button
								type="primary"
								htmlType="submit"
								disabled={!isValid || !dirty}
								loading={loading}
							>
								Start Investing
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default OnBoardingContainer(BankDetailFrom);
