import { Button } from "antd";
import React, { ChangeEvent, FC, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import SubscriptionService from "../../../services/SubscriptionService/subscription.service";
import ErrorMessage from "../../../shared/components/Error";
import PageHeader from "../../../shared/components/PageHeader";
import { REGEX } from "../../../shared/utils/regex";
import "./refer.scss";

interface ReferProps {}

const Refer: FC<ReferProps> = (props) => {
  const [email, setEmail] = useState<string>("");
  const [isValid, setIsValid] = useState<boolean>(true);

  const { createInvite, loading } = SubscriptionService();

  useEffect(() => {
    if (!isValid) {
      validateEmail();
    }
  }, [email]);

  const validateEmail = () => {
    setIsValid(REGEX.EMAIL.test(email));
  };

  const updateEmail = (val: any) => {
    setEmail(val.target.value || "");
  };

  const submitHandler = async () => {
    try {
      if (isValid) {
        const response = await createInvite(email);
        if (response) {
          setEmail("");
          setIsValid(true);
        }
      }
    } catch (error) {}
  };

  return (
    <Fragment>
      <PageHeader title="Invite Friend" />
      <div className="refer__container">
        <div className="refer__container--email">
          <h1 className="refer__container--email-title">
            Invite a Friend to Vuka
          </h1>
          <p className="refer__container--email-text">
            As a member, you have the exclusive ability to invite friends to
            join Vuka and own a few of the limited memberships available.
          </p>
          <div className="refer__container--email__input">
            <input
              type="text"
              placeholder="Enter email here"
              className="refer__container--email-field"
              onBlur={validateEmail}
              onChange={updateEmail}
              value={email}
            />
            {!isValid && (
              <ErrorMessage message="Please provide a valid email"></ErrorMessage>
            )}
            <Button
              type="primary"
              className="refer__container--email-button"
              onClick={submitHandler}
              loading={loading}
            >
              Send Invite Link
            </Button>
          </div>
        </div>
        <div className="refer__container--instructions">
          <h3>Instructions</h3>
          <ul>
            <li>
              <span>Referral invite can be sent only via Email.</span>
            </li>
            {/* <li>
              <span>
                Referral fee will be paid out when the user creates their account and completes membership.
              </span>
            </li>
            <li>
              <span>Referral fee will be credited to your registered mobile number the following month.</span>
            </li> */}
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Refer;
