import * as yup from "yup";
import { REGEX } from "../../../../shared/utils/regex";

export const bankDetailsValidation = yup.object().shape({
    accountNumber: yup
        .string()
        .required("Account number is required!")
        .matches(REGEX.NUMERIC_WITHOUT_ZERO_VALIDATION, "Enter a valid account number "),
    bankName: yup
        .string()
        .required("Bank name is required!"),
    branchName: yup
        .string()
        .required("Branch name is required!"),
    isSavingsAccount: yup
        .boolean()
        .required("Please select account type")
})