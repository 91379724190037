import {
  serializable,
  alias,
  primitive,
  object,
  list,
  custom,
  SKIP,
} from "serializr";

interface Filter {
  values: string[];
  is_multi_select: boolean;
} /* assigning dynamic key for object in ts */
interface availableFilters {
  [x: string]: Filter;
}

export class Pagination {
  @serializable(alias("total", primitive()))
  total?: number;
  @serializable(alias("per_page", primitive()))
  perPage?: number;
  @serializable(alias("last_page", primitive()))
  lastPage?: number;
  @serializable(alias("current_page", primitive()))
  currentPage?: number;

  @serializable(alias("sort_keys", list(primitive())))
  sortKeys?: string[];
  @serializable(alias("status", list(primitive())))
  status?: string[];

  @serializable(
    alias(
      "available_filters",
      custom(
        () => SKIP,
        (values) => {
          return values;
        }
      )
    )
  )
  availableFilters?: availableFilters;
}
