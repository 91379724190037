import React, { FC, useEffect, useState } from "react";
import { Button, Menu, Drawer, Row, Col, Typography, Space } from "antd";
import "../Navbar/navbar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import AuthService from "../../../services/AuthService/auth.service";
import { MenuOutlined } from "@ant-design/icons";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { useHistory } from "react-router-dom";
import { AuthState } from "../../../store/reducers/authReducer";
import Sidebar from "../Sidebar";
import NotificationService from "../../../services/NotificationService/notification.service";
import NotificationIcon from "../../../assets/icons/multi color icons - svg/notificaction.svg"
import ClockCustom from "../../../assets/icons/clock-custom.svg"
import moment from "moment";
import { DateFormats } from "../../../enums/dateFormat";
import { TypesOfNavigation } from "../../../enums/notificationNavigationTypes";

interface SidebarProps extends AuthState {
  logoutHandler: Function;
}

const { Text } = Typography;

const SidebarMobileView: FC<SidebarProps> = (props) => {
  const [visible, setVisible] = useState(false);
  const { logoutHandler, user } = props;

  const history = useHistory();
  const [activeRoute, setActiveRoute] = useState<string>();
  const [logoutRequested, setLogoutRequested] = useState<boolean>(false);
  const { logoutUser } = AuthService();
  const {fetchNotifications, notifications, markOneAsRead, markAllAsRead} = NotificationService();
  const [showMenu, setShowMenu] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);

  history.listen((data) => {
    setActiveRoute(data.pathname);
  });
  
  useEffect(() => {
    setActiveRoute(history.location.pathname);
    void fetchNotifications();
  }, []);

  const handleLogout = async () => {
    setLogoutRequested(true);
    try {
      await logoutHandler();
      history.push(AppRoutes.LOGIN);
    } catch (error) {}
  };

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

 
  const changeNotificationsState = () => {
    setShowNotifications(prevShowNotifications => !prevShowNotifications);
		setShowMenu(false);
  };
  const markAllNotificationsAsRead = async () => {
    const res = await markAllAsRead();
    if (res === 200) {
      setShowNotifications(false);
    }
  };

  const markOneNotificationsAsRead = async (
    id: string,
    type: string,
    readAt: string | null
  ) => {
    if (type === TypesOfNavigation.Dividend) {
      history.push(AppRoutes.WALLET);
    } else if (type === TypesOfNavigation.Subscription) {
      history.push(AppRoutes.PROFILE);
    } else if (type === TypesOfNavigation.Orders) {
      history.push(AppRoutes.HOLDINGS);
    }
    if (readAt !== null) return;
    await markOneAsRead({ id });
  };
  return (
    <>
      <div className="mobileSidebar__container">
        <Button
          type="primary"
          icon={<MenuOutlined className="hamIcon" width={"20px"} />}
          onClick={showDrawer}
        ></Button>
        <div className="sidebar__logo"></div>
        <div>
          <Row
            className="sidebar__notification"
            justify="end"
            align="middle"
            gutter={[20, 20]}
          >
            <Col>
              <div
                className={`wallet__notification ${
                  notifications?.length === 0 ? "" : "badge"
                }`}
                onClick={changeNotificationsState}
              >
                <img src={NotificationIcon} alt="notification" />
              </div>
            </Col>
          </Row>
          {showNotifications && (
            <Row
              className="wallet__notifications-menu"
              justify="space-between"
            >
              <Col span={24}>
                <Row
                  className="wallet__notifications-header notifications-header"
                  justify="space-between"
                >
                  <Col>
                    <Row gutter={[8, 32]} align="middle">
                      <Col className="notifications-header__icon">
                        <img src={NotificationIcon} alt="notification" />
                      </Col>
                      <Col className="notifications-header__text">
                        <p>Notification</p>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    className="notifications-header__mark-all"
                    onClick={() => {
                      void markAllNotificationsAsRead();
                    }}
                  >
                    <p>Mark all as read</p>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Menu
                  mode="vertical"
                  className="wallet__notifications-menu__menu"
                >
                  {notifications?.map((i, j) => {
                    return (
                      <Menu.Item
                        key={j}
                        className="wallet__notifications-menu__item"
                        onClick={() => {
                          void markOneNotificationsAsRead(
                            i.id ?? "",
                            i.data?.type ?? "",
                            i?.readAt ?? null
                          );
                        }}
                      >
                        <Row align="top">
                          <Col span={24}>
                            <Row className="wallet__notifications-menu__item-row">
                              <Col span={24}>
                                <Text
                                  className={
                                    i.readAt ? "text--black" : "text--blue"
                                  }
                                >
                                  {i.data?.title}
                                </Text>
                              </Col>
                              <Col span={24}>
                                <Space direction="horizontal">
                                  <Col>
                                    <img src={ClockCustom} alt="clock-icon" />
                                  </Col>
                                  <Text
                                    type="secondary"
                                    className="notification__item-row-date"
                                  >
                                    {moment(i.createdAt).format(
                                      DateFormats.DEFAULT
                                    )}
                                  </Text>
                                </Space>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Menu.Item>
                    );
                  })}
                </Menu>
              </Col>
            </Row>
          )}
        </div>
        <Drawer
          title=""
          placement="left"
          onClose={onClose}
          closable
          visible={visible}
        >
          <Sidebar onClose={onClose} logoutHandler={logoutUser} />
        </Drawer>
      </div>
    </>
  );
};

export default AuthContainer(SidebarMobileView);
