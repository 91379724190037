import * as yup from 'yup'
import { REGEX } from '../../../shared/utils/regex'

export const ShareFormValidationSchema = yup.object({
    mobileNumber: yup
    .string()
    .required("Mobile number is required!")
    .matches(REGEX.NUMERIC, "Mobile number should be of numeric and should not start with 0")
    .min(9, "Mobile number must be at least 9 characters")
    .max(10, "Mobile number must have maximum 10 characters")
    // .nullable()
})