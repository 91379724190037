import React, { FC } from "react";
import { Modal } from 'antd';
import "./alert-modal.scss"

interface AlertModalProps {
    visible: boolean
    onOk: () => void
    onCancel: () => void
    title?: string
    okText?: string
    className?: string
    closable?: boolean
    maskClosable?: boolean
    confirmLoading?: boolean
    maskStyle?: React.CSSProperties
}

const AlertModal: FC<AlertModalProps> = (props) => {

    const {
        title,
        visible,
        onOk,
        onCancel,
        children,
        maskClosable = false,
        okText = 'Confirm',
        className = '',
        closable = true,
        confirmLoading,
        maskStyle } = props;

    return (
        <Modal
            className={"alert-modal__container " + className}
            title={title}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            maskClosable={maskClosable}
            okText={okText}
            confirmLoading={confirmLoading}
            maskStyle={maskStyle}
            closable={closable}
            centered={true}
        >
            {children}
        </Modal>
    )
}

export default AlertModal