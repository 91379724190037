import { serialize } from "serializr";
import { useState } from "react";
import { generatePath } from "react-router";
import { Relation } from "../../models/MetaData/relationMetaData.model";
import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { recursiveRequest } from "../../shared/utils/recursiveRequest";

const MetaService = () => {
  const [relationMetaData, setrelationMetaData] = useState<Relation[]>([]);

  const [loading, setLoading] = useState(false);

  const getRelationMetaData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.META_RELATION);
      if (response.data?.success) {
        const relation = deserialize(
          Relation,
          response.data["relations"]
        ) as Relation[];
        setrelationMetaData(relation);
        return;
      }

      // const user = deserialize(User, response.data.user);
      //           store.dispatch({
      //               type: AUTHENTICATED,
      //               payload: {
      //                   authenticated: true,
      //                   user: user,
      //               },
      //           });
      //           setUser(user);

      //           return user;
      //       }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,

    getRelationMetaData,
    relationMetaData,
  };
};

export default MetaService;
