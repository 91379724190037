import { ActionProps } from "../../shared/types/action.type";
import { SET_CURRENT_IMAGE, SET_CURRENT_TEXT, SET_KYC_STEP } from "../definitions/onBoardingConstants";

export const setCurrentImage = (currentImage: string): ActionProps => ({
    type: SET_CURRENT_IMAGE,
    payload: {
        currentImage
    }
})

export const setCurrentText = (currentText: string): ActionProps => ({
    type: SET_CURRENT_TEXT,
    payload: {
        currentText
    }
})

export const setKycStep = (kycStep: number): ActionProps => ({
    type: SET_KYC_STEP,
    payload: {
        kycStep
    }
})