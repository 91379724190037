import React, { FC, Fragment, useEffect } from "react";
import { CustomerTypes } from "../../../enums/customerTypes";
import BackgroundImage from "../../../assets/images/customer-details.png";
import BackgroundImageChama from "../../../assets/images/customer-details-chama.png";
import BackArrow from "../../../assets/icons/backArrow.svg";
import "./customerForm.scss";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { CustomerType } from "../../../models/CustomerType/customerType.model";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Radio } from "antd";
import ForwardArrow from "../../../assets/icons/ForwardArrow.svg";
import { useHistory } from "react-router";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { CustomTypeValidation } from "./validation";
import KYCService from "../../../services/KYCService/kyc.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { Customer } from "../../../models/Customer/customer.model";
import RadioField from "../../../shared/components/RadioField";
interface CustomerFormProps extends OnBoardingReducerProps, AuthReducerProps {
	customerType: CustomerTypes;
	customerTypeHandler: (type?: CustomerTypes) => void;
}
const CustomerForm: FC<CustomerFormProps> = (props) => {
	const { customerType, setCurrentImage, customerTypeHandler, setCurrentText, setCustomer } = props;

	const { updateUserInfo, loading } = KYCService();

	const history = useHistory();

	useEffect(() => {
		if (customerType === CustomerTypes.INDIVIDUAL) {
			setCurrentImage(BackgroundImage);
			setCurrentText("Secure Your Investment");
		} else {
			setCurrentImage(BackgroundImageChama);
			setCurrentText("Achieve your Chama’s Financial Goals");
		}

		return () => {
			setCurrentText("");
		};
	}, []);

	const handleSubmit = async (values: CustomerType) => {
		const customerDetails = Object.assign(new CustomerType(), {
			...values,
			isChama: customerType === CustomerTypes.CHAMA,
		});
		try {

			const customer = await updateUserInfo(customerDetails)
			if (customer) {
				setCustomer(customer);
				history.push(AppRoutes.KYC_VERIFICATION);
			}
		} catch (errors) {
			const error: any = errors
			if (error?.response?.status !== 422 || error?.response?.status !== 500) {
				history.push(AppRoutes.KYC_VERIFICATION);
			}
		}
	};

	const formikProps = {
		initialValues: new CustomerType(),
		validationSchema: CustomTypeValidation,
		onSubmit: handleSubmit,
		enableReinitialize: true,
	};

	const UserDetailsFields = (
		<Fragment>
			<InputField
				type="text"
				title="Kenyan National ID / Alien card number / Passport ID"
				name="kenyanNationalNumber"
				placeholder="Enter here"
			/>

			<InputField
				type="text"
				title={`KRA Pin number`}
				name="KRAPinNumber"
				placeholder="Enter here"
			/>
		</Fragment>
	);

	return (
		<div className="customer-form">
			<h1 className="text-primary  text-center">
				<img
					className="cursor-pointer"
					src={BackArrow}
					alt=""
					onClick={() => customerTypeHandler(undefined)}
				/>{" "}
				&nbsp; Tell us more about you
			</h1>
			<Formik {...formikProps}>
				{({ values, setFieldValue, isValid, dirty, errors }) => (
					<Form>
						{console.log(errors)}
						{customerType === CustomerTypes.INDIVIDUAL ? (
							<Fragment>
								<RadioField
									name="idType"
									title="Proof Type"
									value={values.idType}
									options={[{ label: 'Kenyan National ID', value: "IDCARD" }, { label: "Alien Card ID", value: "ALIENCARD" }, { label: "Passport ID", value: "PASSPORT" }]}
									setFieldValue={(field: string, value: boolean) => {
										setFieldValue('idType', value)
									}}
								/>
								{UserDetailsFields}
							</Fragment>
						) : (
							<Fragment>
								<label htmlFor="" className="title">
									Chama Type
								</label>
								<Radio.Group
									onChange={(e) => {
										setFieldValue(
											"isRegistered",
											e.target.value
										);
									}}
								>
									<Radio value={true}>Registered</Radio>
									<Radio value={false}>Non Registered</Radio>
								</Radio.Group>
								{typeof values.isRegistered === "boolean" ? (
									<div
										className={`${typeof values.isRegistered ===
											"boolean" && "move-up"
											}`}
									>
										<RadioField
											name="idType"
											title="Proof Type"
											value={values.idType}
											options={[{ label: 'Kenyan National ID', value: "IDCARD" }, { label: "Alien Card ID", value: "ALIENCARD" }, { label: "Passport ID", value: "PASSPORT" }]}
											setFieldValue={(field: string, value: boolean) => {
												setFieldValue('idType', value)
											}}
										/>
										<InputField
											type="text"
											title={`Registered Chama Name ${values.isRegistered
												? ""
												: "(Optional)"
												}`}
											name="chamaName"
											placeholder="Enter here"
										/>
										{UserDetailsFields}
									</div>
								) : null}
							</Fragment>
						)}
						{typeof values.isRegistered === "boolean" ||
							customerType === CustomerTypes.INDIVIDUAL ? (
							<div className="submit-btn">
								<Button
									type="primary"
									htmlType="submit"
									disabled={!isValid || !dirty}
									loading={loading}
									className={`${typeof values.isRegistered ===
										"boolean" && "move-up"
										}`}
								>
									Proceed &nbsp;{" "}
									{!loading && <img src={ForwardArrow} alt="" />}
								</Button>
							</div>
						) : null}
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default AuthContainer(OnBoardingContainer(CustomerForm));
