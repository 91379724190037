import React, { FC, useEffect, useState } from "react";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/customer-type.png";
import Individual from "../../../assets/images/avatar.png";
import Chama from "../../../assets/images/people.png";
import "./customerType.scss";
import { Button } from "antd";
import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import { CustomerTypes } from "../../../enums/customerTypes";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";

interface CustomerTypeProps extends OnBoardingReducerProps, AuthReducerProps {
	customerTypeHandler: (type?: CustomerTypes) => void;
}

const CustomerType: FC<CustomerTypeProps> = (props) => {
	const { setCurrentImage, customerTypeHandler, setCurrentText, user } = props;

	const [customerType, setCustomType] = useState<CustomerTypes>();
	useEffect(() => {
		setCurrentImage(BackgroundImage);

		setCurrentText("Achieve Financial Freedom");
		return () => {
			setCurrentText("");
		};
	}, []);

	useEffect(() => {
		if (user?.role) {
			if (user.role === "chama") customerTypeHandler(CustomerTypes.CHAMA)
			else customerTypeHandler(CustomerTypes.INDIVIDUAL)
		}
	}, [user])

	return (
		<div className="customer-type">
			<h1 className="text-primary text-center">Tell us more about you</h1>

			<div className="customer-type__wrapper">
				<div
					className={`card ${customerType === CustomerTypes.INDIVIDUAL &&
						"card-individual"
						} cursor-pointer`}
					onClick={() => setCustomType(CustomerTypes.INDIVIDUAL)}
				>
					<img src={Individual} alt="" />
					<p className="text-primary">Individual</p>
				</div>
				<div
					className={`card ${customerType === CustomerTypes.CHAMA && "card-chama"
						} cursor-pointer`}
					onClick={() => setCustomType(CustomerTypes.CHAMA)}
				>
					<img src={Chama} alt="" />
					<p className="text-primary">Chama</p>
				</div>
			</div>
			<div className="submit-btn mb-5">
				<Button
					type="primary"
					htmlType="button"
					disabled={!customerType}
					onClick={() => customerTypeHandler(customerType)}
				>
					Next
				</Button>
			</div>

			<TermsAndPolicy />
		</div>
	);
};

export default AuthContainer(OnBoardingContainer(CustomerType));
