import React, { FC } from "react";
import { DatePicker as DatePickerAntd } from "antd";
import moment, { Moment } from "moment";
import "./datePicker.scss";
import Error from "../Error";
import { ErrorMessage, Field } from "formik";

interface DatePickerProps {
	title?: string;
	value?: string;
	placeholder?: string;
	disableFuture?: boolean
	defaultValue?: string;
	setFieldValue: (name: string, value: string) => void;
	name: string;
}

const DatePicker: FC<DatePickerProps> = (props) => {
	const { title, name, setFieldValue, placeholder, disableFuture, value } = props;

	const handleChange = (value: Moment | null, date: string) => {
		if (value) {
			setFieldValue(name, value.format("YYYY-MM-DD"));
		}
	};

	return (
		<Field name={name}>
			{({
				field,
				form: { touched, errors, setFieldTouched },
				meta,
			}: any) => (
				<div className="date-picker mb-4">
					<span>{title}</span>
					<DatePickerAntd
						suffixIcon={null}
						showToday
						disabledDate={disableFuture ? (current) => current && current.valueOf() > Date.now() : undefined}
						placeholder={placeholder}
						format={"DD / MMM / YYYY"}
						value={value ? moment(value) : undefined}
						onBlur={() => setFieldTouched(name)}
						onChange={handleChange}
					/>
					<ErrorMessage name={name}>
						{(message: string) => (
							<Error
								className={`${name}__error`}
								message={message}
							/>
						)}
					</ErrorMessage>
				</div>
			)}
		</Field>
	);
};

export default DatePicker;
