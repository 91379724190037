import React, { FC } from "react";
import { Button, Checkbox, Form, Modal } from 'antd';
import { Formik, FormikProps, FormikValues } from "formik";
import InputField from "../InputField";
import MessageIcon from "./../../../assets/icons/single color icons - svg/message.svg"
import "./mobileInputModal.scss"

interface MobileModalProps {
    visible: boolean
    onOk: () => void
    onCancel: () => void
    title?: string
    okText?: string
    className?: string
    closable?: boolean
    maskClosable?: boolean
    confirmLoading?: boolean
    maskStyle?: React.CSSProperties
}

const MobileInputModal: FC<MobileModalProps> = (props) => {

    const {
        title,
        visible,
        onOk,
        onCancel,
        children,
        maskClosable = false,
        okText = 'Confirm',
        className = '',
        closable = true,
        confirmLoading,
        maskStyle } = props;

    const handleSubmit = (values: FormikValues) => {
    }

    const formikProps = {
        initialValues: {
            mobileNumber: "",
            dialCode: 254
        },
        onSubmit: handleSubmit,
    };

    return (
        <Modal
            className={"mobile-input-modal__container " + className}
            title={title || 'Change Phone Number'}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            maskClosable={maskClosable}
            okText={okText}
            confirmLoading={confirmLoading}
            maskStyle={maskStyle}
            closable={closable}
        >
            <Formik {...formikProps}>
                {({ isValid, dirty, setFieldValue, values }) => {
                    return (
                        <Form className="wallet-form">
                            <label htmlFor="mobileNumber">New Phone Number</label>
                            <InputField
                                className="mpesa-field lg"
                                type="text"
                                name="mobileNumber"
                                placeholder="Enter MPESA mobile number"
                            />
                            <div className="styled-modal-btn">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="middle"
                                    disabled={
                                        false
                                        // !values.mobileNumber
                                    }
                                    onClick={() => handleSubmit(values)}
                                >
                                    {/* <img src={MessageIcon} alt="send=otp" /> */}
                                    <i className="icon-message"></i>
                                    {`Add Money to wallet`}
                                </Button>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    )
}

export default MobileInputModal