import React, { FC, Fragment, useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { User } from "../../../models/User/user.model";
import { Wallet } from "../../../models/Wallet/wallet.model";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import AuthService from "../../../services/AuthService/auth.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthState } from "../../../store/reducers/authReducer";
import { numberConvention } from "../../utils/numberConvention";
import MobileInputModal from "../MobileInputModal";
import NotificationIcon from "./../../../assets/icons/multi color icons - svg/notificaction.svg";
import "./pageHeader.scss";
import {Col, Divider, Menu, Row, Space, Typography } from "antd";
import DynamicText from "../DynamicText";
import WalletStaticIcon from "./../../../assets/icons/multi color icons - svg/static wallet - blue.svg";
import arrowRight from "../../../assets/icons/multi color icons - svg/rightArrow.svg"
import ClockCustom from "../../../assets/icons/clock-custom.svg"
import NotificationService from "../../../services/NotificationService/notification.service";
import moment from "moment";
import { DateFormats } from "../../../enums/dateFormat";
import { TypesOfNavigation } from "../../../enums/notificationNavigationTypes";

type investmentDetails={
 investmentAmount: Number,
 investmentTime: Number,
 investmentMonths: Number,
 investedYear: Number
}
interface PageHeaderProps extends AuthState {
  title: string;
  showSubtitles?: boolean;
  titleBadge?: string;
  showWallet?: boolean;
  showUpgrade?: boolean;
  investment_details: investmentDetails,
}

const { Text } = Typography;

const PageHeader: FC<PageHeaderProps> = (props) => {
  const history = useHistory();
  const { title, showWallet = false, user, showSubtitles, showUpgrade=false } = props;
  const { getWalletDetails, wallet } = AuthService();
  const {fetchNotifications, notifications, markOneAsRead, markAllAsRead} = NotificationService();
  const [mobileModal, setMobileModal] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState(false);
	const [showNotifications, setShowNotifications] = useState(false);

  const subtitles = [
    user?.role,
    user?.uid?.split("-").pop(),
    user?.memberSince && "Member since " + user?.memberSince,
  ];

  useEffect(() => {
    getWalletDetails();
    void fetchNotifications();
  }, []);

  const subtitleTemplate = subtitles?.map((subtitle, index) => {
    return (
      subtitle && (
        <Fragment key={subtitle}>
          <span>{subtitle}</span>
          {index + 1 !== subtitles.length && <hr />}
        </Fragment>
      )
    );
  });

  const subscribeHandler = () => {
    history.push(AppRoutes.SUBSCRIPTIONS);
  };

  const changeNotificationsState = () => {
    setShowNotifications(prevShowNotifications => !prevShowNotifications);
		setShowMenu(false);
  };

  const markAllNotificationsAsRead = async () => {
    const res = await markAllAsRead();
    if (res === 200) {
      setShowNotifications(false);
    }
  };

  const markOneNotificationsAsRead = async (
    id: string,
    type: string,
    readAt: string | null
  ) => {
    await markOneAsRead({ id });
    if (type === TypesOfNavigation.Dividend) {
      history.push(AppRoutes.WALLET);
    } else if (type === TypesOfNavigation.Subscription) {
      history.push(AppRoutes.PROFILE);
    } else if (type === TypesOfNavigation.Orders) {
      history.push(AppRoutes.HOLDINGS);
    }
  };


  

  return (
    <div className="page-header__container">
      {title && (
        <div
          className={
            "page-header__details " + (showSubtitles && "subtitle-placeholder")
          }
        >
          <h2 className="page-header__details--title">
            {title}
            {/* {user && !user?.subscription && (
              <p
                className="page-header__details--title-chip"
                onClick={subscribeHandler}
              >
                <i className="icon-alert" />
                <span>Choose a plan</span>
              </p>
            )} */}
            {showSubtitles && user?.subscription?.name && (
              <p
                className={
                  "page-header__details--title-badge " +
                  user?.subscription?.name
                }
                // onClick={() => setMobileModal(true)}
              >
                <i className="icon-subscription" />
                <span>{user?.subscription?.name}</span>
              </p>
            )}
            {showUpgrade && (
              <>
                {user ? (
                  user?.lastSubscription && !user.userHasActivePlan ? (
                    <p
                      className="page-header__details--title-chip"
                      onClick={subscribeHandler}
                    >
                      <i className="icon-alert" />
                      <span>Renew Plan</span>
                    </p>
                  ) : user.subscription ? (
                    <p
                      className="page-header__details--title-upgrade"
                      onClick={subscribeHandler}
                    >
                      <span>
                        Upgrade{" "}
                        <img
                          src={arrowRight}
                          className="page-header__details--upgrade-icon"
                        />{" "}
                      </span>
                    </p>
                  ) : (
                    <p
                      className="page-header__details--title-chip"
                      onClick={subscribeHandler}
                    >
                      <i className="icon-alert" />
                      <span>Choose a plan</span>
                    </p>
                  )
                ) : (
                  <></>
                )}
              </>
            )}
          </h2>

          {showSubtitles && (
            <div className="page-header__details--subtitle">
              {subtitleTemplate}
            </div>
          )}
        </div>
      )}
      {showWallet && wallet && (
        <div className="page-header__wallet--container">
          <p className="page-header__wallet">
            <img
              src={WalletStaticIcon}
              alt="wallet"
              className="page-header__wallet--icon"
            />
            <h3 className="page-header__wallet--heading">
              Total Wallet Balance
            </h3>
            <h3 className="page-header__wallet--amount">{`${numberConvention(
              (wallet.dynamicBalance ?? 0) + (wallet.staticBalance ?? 0)
            )} KES`}</h3>
          </p>
          <Divider type="vertical" className="page-header__wallet-divider" />
          <div>
            <Row className="" justify="end" align="middle" gutter={[20, 20]}>
              <Col>
                <div
                  className={`wallet__notification ${
                    notifications?.length === 0 ? "" : "badge"
                  }`}
                  onClick={changeNotificationsState}
                >
                  <img src={NotificationIcon} alt="notification" />
                </div>
              </Col>
            </Row>
            {showNotifications && (
              <Row
                className="wallet__notifications-menu"
                justify="space-between"
              >
                <Col span={24}>
                  <Row className="wallet__notifications-header notifications-header" justify="space-between">
                    <Col>
                      <Row gutter={[8, 32]} align="middle">
                        <Col className="notifications-header__icon">
                          <img src={NotificationIcon} alt="notification" />
                        </Col>
                        <Col className="notifications-header__text">
                          <p>Notification</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col
                    
                      className="notifications-header__mark-all"
                      onClick={() => {
                        void markAllNotificationsAsRead();
                      }}
                    >
                      <p>Mark all as read</p>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Menu
                    mode="vertical"
                    className="wallet__notifications-menu__menu"
                  >
                    {notifications?.map((i, j) => {
                      return (
                        <Menu.Item
                          key={j}
                          className="wallet__notifications-menu__item"
                          onClick={() => {
                            void markOneNotificationsAsRead(
                              i.id ?? "",
                              i.data?.type ?? "",
                              i?.readAt ?? null
                            );
                          }}
                        >
                          <Row align="top">
                            <Col span={24}>
                              <Row className="wallet__notifications-menu__item-row">
                                <Col span={24}>
                                  <Text
                                    className={"text--blue"}
                                  >
                                    {i.data?.title}
                                  </Text>
                                </Col>
                                <Col span={24}>
                                  <Space direction="horizontal">
                                    <Col>
                                      <img src={ClockCustom} alt="clock-icon" />
                                    </Col>
                                    <Text
                                      type="secondary"
                                      className="notification__item-row-date"
                                    >
                                      {moment(i.createdAt).format(DateFormats.DEFAULT)}
                                    </Text>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      <MobileInputModal
        visible={mobileModal}
        onOk={() => {
          setMobileModal(false);
        }}
        onCancel={() => {
          setMobileModal(false);
        }}
      />
    </div>
  );
};

export default AuthContainer(PageHeader);
