import React, { FC } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import LoginForm from "../Auth/LoginForm";
import { RouterProps } from "../../shared/types/route.type";
import * as appRoutes from "../../routes/routeConstants/appRoutes";
import AppRoutes from "../../routes";
import Holdings from "./Holdings";
import Home from "./Home";
import Wallet from "./Wallet";
import Refer from "./Refer";
import { Faq } from "./Faq";
import Profile from "./Profile";
import Report from "./Report";

const appWrapperRouter = () => {
    const routes: RouterProps[] = [
        { exact: true, path: appRoutes.HOME, component: Home },
        { exact: true, path: appRoutes.HOLDINGS, component: Holdings },
        { exact: true, path: appRoutes.WALLET, component: Wallet },
        { exact: true, path: appRoutes.REFER, component: Refer },
        { exact: true, path: appRoutes.FAQ, component: Faq },
        { exact: true, path: appRoutes.PROFILE, component: Profile },
        { exact: true, path: appRoutes.REPORT, component: Report },
    ];

    return (
        <Switch>
            {routes.map(({ component, ...routerProps }) => (
                <Route
                    key={routerProps.path}
                    {...routerProps}
                    component={component as FC}
                />
            ))}
            <Redirect exact strict from={appRoutes.APP} to={appRoutes.HOME} />
        </Switch>
    );
};

export default appWrapperRouter;
