export const MADE_IN_KENYA = "Made in Kenya";
export const ALERT_OKAY_TEXT = "Enter details";

export const FORM_FIELD_LABELS = {
  KENYAN_ID: "Kenyan National ID / Alien card number",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
};

export const FORM_PLACEHOLDERS = {
  DEFAULT: "Enter here",
  DOB: "Enter date of birth",
  DATE_ISSUE: "Enter date of issue",
};
