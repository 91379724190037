import React, { Fragment } from 'react';
import PageHeader from './../PageHeader';
import { User } from '../../../models/User/user.model';
import "./restrictedAccess.scss"
import RiskAssessmentPlaceholderImage from "./../../../assets/images/risk-assesment_placeholder.png"
import AuthContainer from '../../../store/container/AuthContainer';
import { AuthState } from '../../../store/reducers/authReducer';
import PendingKycDocuments from '../PendingKycDocuments';
import RejectIcon from './../../../assets/icons/multi color icons - svg/reject.svg';

interface RestrictAccessProps {
    user?: User
    getUserDetails: Function
}
function RestrictAccess(props: RestrictAccessProps) {

    const {
        user,
        getUserDetails,
    } = props

    return (
        <div className="restricted-access__container">
            <PageHeader
                title={"Hello, " + (user?.kycBasicInfo?.firstName?.toLowerCase() || '')} />
            <p className="restricted-access--text">Stay tuned! your account is under verification</p>
            {!user?.rejectedDocuments?.length
                ? <div className="restricted-access__body-pending">
                    <img src={RiskAssessmentPlaceholderImage} alt="" />
                    <p>Our team is reviewing your KYC and other documents.</p>
                </div>
                : <div className="restricted-access__body-rejected">
                    <img src={RiskAssessmentPlaceholderImage} alt="" />
                    <p className="rejected-text">
                        <img src={RejectIcon} alt="" />
                        Rejected KYC</p>
                    <p className="text">Please resubmit your KYC for verification</p>
                    <PendingKycDocuments getUserDetails={getUserDetails} documents={user?.rejectedDocuments || []} />
                </div>}
        </div>
    );
}

export default RestrictAccess;
