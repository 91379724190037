import { alias, primitive, serializable } from "serializr";

export class Order {
  @serializable(alias("unique_id", primitive()))
  uniqueId?: number;

  @serializable(alias("quantity", primitive()))
  quantity?: number;

  @serializable(alias("type", primitive()))
  type?: number;

  @serializable(alias("confirm", primitive()))
  confirm?: number;

  @serializable(alias("amount", primitive()))
  amount?: number;
}
