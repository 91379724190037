import { Button, Col, Row } from "antd";
import React, { FC } from "react";
import { Subscription } from "../../../../models/Subscription/subscription.model";
import { ActionProps } from "../../../../shared/types/action.type";
import Polygon from "../../../../assets/icons/single color icons - svg/Polygon.svg";

import "./plan.scss";

interface PlanProps {
  subscription: Subscription;
  setSubscription: (subscription: Subscription) => void;
}

const Plan: FC<PlanProps> = (props) => {
  const { subscription, setSubscription } = props;
  const { code, name, price, description } = subscription;

  return (
    <Row className={"plan " + code}>
      <Col className="plan__description">
        <p className="title ">
          <span className="title-name">{name}&nbsp; </span>
          <span className="subscription-icon">
            <i className="icon-subscription" />
          </span>
        </p>

        <div className="description">
          {description?.map((description, index) => (
            <>
              <p key={index}>
                {" "}
                <img className="icon-playBtn " src={Polygon} alt="mpesa-logo" />
                {description}
              </p>
            </>
          ))}
        </div>
      </Col>
      <Col className="subscription-btn__wrapper">
        <Button
          type="primary"
          onClick={(props) => setSubscription(subscription)}
        >
          <span className="price">{price} </span>
          <span className="kes">&nbsp; KES/annum</span>
        </Button>
      </Col>
    </Row>
  );
};

export default Plan;
