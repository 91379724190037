import { deserialize } from "serializr"
import { NotificationTypes } from "../../enums/notificationTypes"
import axiosInstance from "../../interceptor/axiosInstance"
import { DynamicUrlResponse } from "../../models/Subscription/subscription.model"
import Notification from "../../shared/components/Notification";

export const recursiveRequest = async (url: string) => {
    let paymentStatus = new DynamicUrlResponse()
    let counter = 0
    const timer = setInterval(() => {
        counter++
    }, 1000)
    const delay = (delayTiming: number) => new Promise(res => setTimeout(res, delayTiming))
    while ((!paymentStatus?.status || paymentStatus?.status === "Pending") && counter < 90) {
        const dynamicUrlResponse = await axiosInstance.get(url)
        paymentStatus = deserialize(DynamicUrlResponse, dynamicUrlResponse.data['payment'])
        if (paymentStatus.status === 'Pending') {
            await delay(10000)
        }
    }
    clearInterval(timer)
    if (paymentStatus?.message) {
        Notification({
            message: "",
            description: paymentStatus?.message || '',
            type: paymentStatus.status === "Failed" ? NotificationTypes.ERROR : NotificationTypes.SUCCESS,
        });
    }
    return paymentStatus.status
}