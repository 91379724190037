import { alias, custom, object, primitive, serializable, SKIP } from "serializr";
import { WalletHistoryTypes } from "../../enums/walletHistoryTypes";

interface PaymentDetails {
    [x: string]: string;
}

export class Wallet {

    @serializable(alias('dynamic', primitive()))
    dynamicBalance?: number

    @serializable(alias('static', primitive()))
    staticBalance?: number

    @serializable(alias('holdings', primitive()))
    holdings?: number

    @serializable(alias('sellable_holdings', primitive()))
    sellableHoldings?: number

}

class ExportHistoryDetails {

    @serializable(alias('total', primitive()))
    total?: number

    @serializable(alias('status', primitive()))
    status?: string

    @serializable(alias('unit_price', primitive()))
    unitPrice?: number

    @serializable(alias('share_quantity', primitive()))
    shareQuantity?: number
}

export class WalletHistory {

    @serializable(alias('amount', primitive()))
    amount?: number

    @serializable(alias('debit_acc', primitive()))
    debitAccount?: string

    @serializable(alias('type', primitive()))
    type?: WalletHistoryTypes

    @serializable(alias('title', primitive()))
    title?: string

    @serializable(alias('transaction_date', primitive()))
    transactionDate?: string

    @serializable(alias('credit_acc', primitive()))
    creditAccount?: string

    @serializable(alias('created_at', primitive()))
    createdAt?: string

    @serializable(alias('for_dynamic_wallet', primitive()))
    forDynamicWallet?: boolean

    @serializable(alias('details', object(ExportHistoryDetails)))
    details?: ExportHistoryDetails

    @serializable(
        alias(
            "payment_details",
            custom(
                () => SKIP,
                (values) => {
                    return values;
                }
            )
        )
    )
    paymentDetails?: PaymentDetails;
}