import React, { FC } from 'react'
import Lottie from 'react-lottie';
import PaymentProcessingIcon from "./../../../assets/lotties/vuka payment processing.json"
import Logo from "./../../../assets/icons/logo - svg/mpesa2.png";
import AlertModal from '../AlertModal';
import { useState } from 'react';
import { useEffect } from 'react';

interface PaymentProcessingModalProps {
    visible: boolean
}

const PaymentProcessingModal: FC<PaymentProcessingModalProps> = (props) => {

    const { visible } = props

    const [title, setTitle] = useState<any>()

    useEffect(() => {
        setTitle(<h2>Please accept the collect request <br /> sent to your MPESA account</h2>)
        let textChangeTimeout: any;
        
        if (visible) {
            textChangeTimeout = setTimeout(() => {
                setTitle(<h2>Processing your payment</h2>)
            }, 60000);
        }

        return () => {
            if(textChangeTimeout){
                clearTimeout(textChangeTimeout)
            }
        }
    }, [visible])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: PaymentProcessingIcon,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return <AlertModal
        className="payment-modal"
        visible={visible}
        closable={false}
        onOk={() => { }}
        maskStyle={{
            background: 'white'
        }}
        onCancel={() => { }}>
        <div className="payment-modal__container">
            <div className="payment-modal__container--body">
                {title}
                <Lottie
                    options={defaultOptions}
                    height={150}
                    width={150}
                />
            </div>
            {/* <div className="payment-modal__container--footer">
        <Button className="cancel-payment" type="default" disabled>Cancel Payment</Button>
    </div> */}
            <img className="logo" src={Logo} alt="mpesa-logo" />
        </div>
    </AlertModal>
}

export default PaymentProcessingModal