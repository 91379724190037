import * as Yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!'),
  password: Yup.string()
    .min(6, 'Password has to be longer than 6 characters!')
    .required('Password is required!')
    .matches(REGEX.PASSWORD, "One Uppercase, One Lowercase, One Number and one special case Character")
})

export const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('E-mail is not valid!')
    .required('E-mail is required!'),
})