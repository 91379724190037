import React, { FC, SetStateAction, useEffect, useState } from "react";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import OTPImage from "../../../assets/images/otp-page.png";
import BackArrow from "../../../assets/icons/backArrow.svg";
import ForwardArrow from "../../../assets/icons/ForwardArrow.svg";
import ResendIcon from "../../../assets/icons/resend.svg";
import OtpInput from "react-otp-input";
import { Form, Formik, FormikValues } from "formik";
import { Button } from "antd";
import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import { useHistory } from "react-router";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { otpValidationSchema } from "./validation";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { OTP } from "../../../models/OTP/otp.model";
import "./oTPForm.scss";
import AuthService from "../../../services/AuthService/auth.service";
import { OTP_TYPES } from "../../../enums/otpTypes";

interface OTPFormProps extends OnBoardingReducerProps, AuthReducerProps {
	otpSendHandler: (show: boolean) => void;
}

const OTPForm: FC<OTPFormProps> = (props) => {
	const { setCurrentImage, otpSendHandler, setCurrentText, user } = props;

	const [resendOTPEmail, setResendOTPEmail] = useState<boolean>(false)
	const [resendOTPSms, setResendOTPSms] = useState<boolean>(false)

	const { OTPVerification, resendOTP, loading } = AuthService();

	const history = useHistory();

	useEffect(() => {
		setCurrentImage(OTPImage);

		setCurrentText("Dream, Save, Achieve");
		return () => {
			setCurrentText("");
		};
	}, []);

	const handleSubmit = async (values: FormikValues) => {
		const otp = Object.assign(new OTP(), { ...values, ...user });
		const otpVerified = await OTPVerification(otp);
		if (otpVerified) {
			history.push(AppRoutes.TELL_US_MORE_ABOUT_YOU);
		}
	};

	const handleSendOTP = async (type: OTP_TYPES) => {
		const email = user?.email || ""
		if (type === OTP_TYPES.EMAIL) {
			setResendOTPEmail(true)
			// resendOTPDisableHandler(setResendOTPEmail)
		} else {
			setResendOTPSms(true)
			// resendOTPDisableHandler(setResendOTPSms)
		}
		await resendOTP({
			type,
			email
		});
		if (type === OTP_TYPES.EMAIL) {
			setResendOTPEmail(false)
			// resendOTPDisableHandler(setResendOTPEmail)
		} else {
			setResendOTPSms(false)
			// resendOTPDisableHandler(setResendOTPSms)
		}
	};

	const resendOTPDisableHandler = (stateUpdate: React.Dispatch<React.SetStateAction<boolean>>) => {
		stateUpdate(true)
		setTimeout(() => {
			stateUpdate(false)
		}, 60000);
	}

	const handleGoBack = () => {
		otpSendHandler(false);
	};

	const formikProps = {
		initialValues: { emailOTP: "", mobileOTP: "" },
		validationSchema: otpValidationSchema,
		onSubmit: handleSubmit,
	};

	return (
		<div className="o-t-p-form">
			<h1 className="text-primary text-center">
				<img
					src={BackArrow}
					alt=""
					className="cursor-pointer"
					onClick={handleGoBack}
				/>{" "}
				&nbsp; Verify Mobile and Email
			</h1>
			<Formik {...formikProps}>
				{({ values, setFieldValue, isValid, dirty }) => (
					<Form>
						<div className="otp__container">
							<label htmlFor="">
								Enter OTP sent to {user?.email}
							</label>
							<OtpInput
								containerStyle={{
									justifyContent: "space-between",
								}}
								className="otp__wrapper"
								value={values.emailOTP}
								onChange={(otp: string) =>
									setFieldValue("emailOTP", otp)
								}
								numInputs={6}
							/>
							<div
								className={
									"resend__wrapper" +
									(resendOTPEmail ? " disable-resend" : " cursor-pointer")
								}
								onClick={() => {
									if (!resendOTPEmail) {
										handleSendOTP(OTP_TYPES.EMAIL)
									}
								}}
							>
								<img src={ResendIcon} alt="" /> &nbsp; Resend
								OTP
							</div>
						</div>
						<div className="otp__container">
							<label htmlFor="">
								Enter OTP sent to {user?.mobileNumber}
							</label>
							<OtpInput
								containerStyle={{
									justifyContent: "space-between",
								}}
								className="otp__wrapper"
								value={values.mobileOTP}
								onChange={(otp: string) =>
									setFieldValue("mobileOTP", otp)
								}
								numInputs={6}
							/>
							<div
								className={
									"resend__wrapper" +
									(resendOTPSms ? " disable-resend" : " cursor-pointer")
								}
								onClick={() => {
									if (!resendOTPSms) {
										handleSendOTP(OTP_TYPES.SMS)
									}
								}}
							>
								<img src={ResendIcon} alt="" /> &nbsp; Resend
								OTP
							</div>
						</div>

						<div className="submit-btn">
							<Button
								type="primary"
								htmlType="submit"
								disabled={!isValid || !dirty}
								loading={loading}
							>
								Verify and Proceed &nbsp;{" "}
								{!loading && <img src={ForwardArrow} alt="" />}
							</Button>
						</div>
						<TermsAndPolicy />
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default AuthContainer(OnBoardingContainer(OTPForm));
