import { serializable, alias, primitive } from 'serializr';

type CustomerTypeIDType = "IDCARD" | "ALIENCARD" | "PASSPORT"

export class CustomerType {
    @serializable(alias('user_role', primitive()))
    userRole?: CustomerType;

    @serializable(alias('is_chama', primitive()))
    isChama?: boolean;


    @serializable(alias('type', primitive()))
    idType?: CustomerTypeIDType;

    @serializable(alias('kenyan_national_number', primitive()))
    kenyanNationalNumber?: string;


    @serializable(alias('KRA_pin_number', primitive()))
    KRAPinNumber?: string;

    @serializable(alias('chama_name', primitive()))
    chamaName?: string;

    @serializable(alias('is_registered', primitive()))
    isRegistered?: string;
}
