import React, { Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Subscription } from "../../models/Subscription/subscription.model";
import AlertModal from "../../shared/components/AlertModal";
import OnBoarding from "../../shared/components/Layout/OnBoarding";
import PaymentForm from "./PaymentForm";
import SubscriptionPlans from "./SubscriptionPlans";
import Logo from "./../../assets/icons/logo - svg/mpesa2.png";
import PaymentProcess from "./../../assets/icons/multi color icons - svg/start video kyc.svg";
import "./subscriptions.scss";
import { Button } from "antd";
import Lottie from "react-lottie";
import PaymentProcessingIcon from "./../../assets/lotties/vuka payment processing.json";
import AuthService from "../../services/AuthService/auth.service";
import SubscriptionService from "../../services/SubscriptionService/subscription.service";
import { FormikValues } from "formik";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../routes/routeConstants/appRoutes";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import KYCService from "../../services/KYCService/kyc.service";
import { setKycStep } from "../../store/actions/onBoarding";

const Subscriptions = () => {
  const history = useHistory();

  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription>();
  const [paymentProcessing, setPaymentProcessing] = useState<boolean>(false);
  const [initalizeCounter, setInitializeCounter] = useState<boolean>(false);
  const [changeProcessContent, setChangeProcessContent] =
    useState<boolean>(false);
  const [counter, setCounter] = useState<any>();

  const { getUserDetails, user } = AuthService();
  const {
    createSubscription,
    loading,
    SubscriptionAmount,
    getSubscriptionAmount,
  } = SubscriptionService();
  const { getStepperStatus, loading: stepperLoading } = KYCService();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: PaymentProcessingIcon,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    fetchStep();
    getUserDetails();
    const state = history.location.state as { redirectFromHome: boolean };
    if (state?.redirectFromHome) {
      Notification({
        message: "",
        description: "Please subscribe to proceed further...",
        type: NotificationTypes.WARNING,
      });
    }
  }, []);

  useEffect(() => {
    setChangeProcessContent(false);
    setTimeout(() => {
      setChangeProcessContent(true);
    }, 60000);
  }, [paymentProcessing]);

  useEffect(() => {
    /*   if (user && user?.subscription) {
            setCounter(null)
            history.push(AppRoutes.HOME)
        } */

    let userFetchingInterval: any;
    if (initalizeCounter) {
      userFetchingInterval = setInterval(() => {
        // getUserDetails()
      }, 15000);
    }

    return () => {
      if (userFetchingInterval) {
        clearInterval(userFetchingInterval);
      }
    };
  }, [user]);

  const fetchStep = async () => {
    try {
      const stepData = await getStepperStatus();
      if (!stepData) {
        return;
      }
      if (
        stepData?.isSubscribed === true &&
        stepData?.isKycCompleted &&
        stepData?.isKycVerified
      ) {
        /* history.push(AppRoutes.HOME); */
      } else if (stepData?.isKycCompleted) {
        history.push(AppRoutes.SUBSCRIPTIONS);
      } else if (stepData.step !== undefined && +stepData?.step >= 0) {
        return getExactTab(
          typeof stepData?.step === "string"
            ? parseInt(stepData?.step)
            : stepData.step
        );
        // return getExactTab(2)
      }
      setKycStep(0);
    } catch (error) {
      setKycStep(0);
    }
  };

  const getExactTab = (stepNumber: number) => {
    if (stepNumber === 0) {
      history.push(AppRoutes.TELL_US_MORE_ABOUT_YOU);
    } else if (stepNumber > 0 && stepNumber < 5) {
      setKycStep(stepNumber - 1);
    }
  };

  const createSubscriptionHandler = async (values: FormikValues) => {
    const proceedToHomeHandler = () => {
      history.push(AppRoutes.HOME);
    };

    // (SubscriptionAmount?.totalAmount ?? 0) > 0
    //   ?
    // : setPaymentProcessing(false);

    try {
      setPaymentProcessing(true)
      // const upgrade = user?.subscription||user?.lastSubscription?.code!==selectedSubscription?.code ? 1 : 0;
      // const renewal=user?.subscription||user?.lastSubscription!==undefined?0:1;
      if(user && selectedSubscription?.code){
    const data = await createSubscription(values, user, selectedSubscription?.code, proceedToHomeHandler);

      if (data === "Failed" || !data) {
        setSelectedSubscription(undefined);
        // setPaymentProcessing(false);
      } else {
        // setPaymentProcessing(false);
        history.push(AppRoutes.HOME);
        return;
      }}
    } catch (error) {
    } finally {
      setPaymentProcessing(false);
      getUserDetails();
    }
  };

  const paymentProcessingTemplate = (
    <div className="payment-modal__container">
      <div className="payment-modal__container--body">
        {!changeProcessContent ? (
          <h2>
            Please accept the collect request <br /> sent to your MPESA account
          </h2>
        ) : (
          <h2>Processing your payment</h2>
        )}
        <Lottie options={defaultOptions} height={100} width={100} />
      </div>
      {/* <div className="payment-modal__container--footer">
                <Button className="cancel-payment" type="default" disabled>Cancel Payment</Button>
            </div> */}
      <img className="logo" src={Logo} alt="mpesa-logo" />
    </div>
  );

  return (
    <Fragment>
      <OnBoarding
        styles={{
          marginLeft: "-3vw",
        }}
      >
        {!selectedSubscription ? (
          <SubscriptionPlans
            setSubscription={setSelectedSubscription}
            stepperLoading={stepperLoading}
          ></SubscriptionPlans>
        ) : (
          <PaymentForm
            setSubscription={setSelectedSubscription}
            subscription={selectedSubscription}
            createSubscription={createSubscriptionHandler}
            loading={loading || stepperLoading}
            user={user}
          ></PaymentForm>
        )}
      </OnBoarding>
      <AlertModal
        className="payment-modal"
        visible={paymentProcessing}
        closable={false}
        onOk={() => { }}
        maskStyle={{
          background: "white",
        }}
        onCancel={() => { }}
      >
        {paymentProcessingTemplate}
      </AlertModal>
    </Fragment>
  );
};

export default Subscriptions;
