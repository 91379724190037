import React, { FC, useEffect, useState } from "react";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/risk-assesment.png";
import Placeholder from "../../../assets/images/kyc-success.png";
import "./riskAssesment.scss";
import { Button } from "antd";
import RiskAssesmentQuestions from "./RiskAssesmentQuestions";

interface RiskAssesmentProps extends OnBoardingReducerProps { }

const RiskAssesment: FC<RiskAssesmentProps> = (props) => {
	const { setCurrentImage, setCurrentText } = props;

	const [takeAssesment, setTakeAssesment] = useState(false);

	useEffect(() => {
		setCurrentImage(BackgroundImage);
		setCurrentText("You're Almost There");
	}, []);

	const handleStartAssesment = () => {
		setTakeAssesment(true)
	}
	if (takeAssesment) {
		return <RiskAssesmentQuestions closeAssesmentHandler={() => setTakeAssesment(false)} />
	}
	return (
		<div className="risk-assesment">
			<h1 className="text-primary text-center">
				Kindly proceed with the Risk <br /> Assessment to complete your KYC
			</h1>
			<div className="submit-btn">
				<Button type="primary" htmlType="submit" onClick={handleStartAssesment}>
					Take Risk Assesment
				</Button>
			</div>
			{/* <div className="placeholder__wrapper">
				<img src={Placeholder} alt="" />
			</div> */}
			<p>Our team is reviewing your KYC and other documents.</p>
		</div>
	);
};

export default OnBoardingContainer(RiskAssesment);
