import * as yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const paymentValidationSchema = yup.object().shape({
    phone: yup
        .string()
        .matches(REGEX.NUMERIC, "Mobile number should be of numeric")
        .min(9, "Mobile number must be at least 9 characters")
        .max(10, "Mobile number must be at least 10 characters")
        .required("Mobile number is required")
});
