import { serializable, alias, primitive, custom } from 'serializr';

export class KYCStep {

    @serializable(alias('step', primitive()))
    step?: string;

    @serializable(alias('is_kyc_completed', primitive()))
    isKycCompleted?: boolean

    @serializable(alias('is_kyc_verified', primitive()))
    isKycVerified?: boolean


    @serializable(alias('has_subscription', primitive()))
    isSubscribed?: boolean | string


}
