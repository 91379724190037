import * as yup from "yup";
import { REGEX } from "../../../../shared/utils/regex";

export const emergencyDetailsValidation = yup.object().shape({
    name: yup
        .string()
        .required("Name is required!"),
    phone: yup
    .string()
    .matches(REGEX.NUMERIC, "Phone number should be of numeric and should not start with 0")
    .min(9, "Phone number must be at least 9 characters")
    .max(10, "Phone number must have maximum 10 characters")
    .required("Phone number is required!"),
})