import { combineReducers } from "redux";
import authReducer, { AuthState } from "./authReducer";
import onBoardingReducer, { OnBoardingState } from "./onBoardingReducer";
import termsAndConditionsReducer, { TermsAndConditionsState } from "./termsAndConditionsReducer";

export interface RootReducerProps {
    auth: AuthState,
    onBoarding: OnBoardingState,
    termsAndCondtions: TermsAndConditionsState
}

const RootReducer = combineReducers<RootReducerProps>({
    auth: authReducer,
    onBoarding: onBoardingReducer,
    termsAndCondtions: termsAndConditionsReducer
});

export default RootReducer;