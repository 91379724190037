import { Divider, Timeline } from "antd";
import React from "react";
import "./mpesaTransferInstructions.scss";

interface MpesaTransferInstructionsProps {
  customerId: string | number;
  amount: number;
}

const MpesaTransferInstructions = (props: MpesaTransferInstructionsProps) => {
  const { customerId, amount } = props;

  return (
    <div className="mpesa-transfer-innstructions__container">
      <p className="mpesa-transfer-innstructions--text">
        To pay using mpesa app, kindly follow the below instructions.
      </p>
      <Timeline className="mpesa-transfer-innstructions">
        <Timeline.Item dot={<p className="timeline-step">1</p>}>
          Go to Mpesa Mobile App Menu
        </Timeline.Item>
        <Timeline.Item dot={<p className="timeline-step">2</p>}>
          Select <span className="text-primary">Pay Bill</span>
        </Timeline.Item>
        <Timeline.Item dot={<p className="timeline-step">3</p>}>
          Enter Business Number : <span className="text-primary">4077925</span>
        </Timeline.Item>
        <Timeline.Item dot={<p className="timeline-step">4</p>}>
          Enter Account Number :{" "}
          <span className="text-primary">{customerId}</span>
        </Timeline.Item>
        <Timeline.Item dot={<p className="timeline-step">5</p>}>
          Amount to be Paid : <span className="text-primary">{amount} KES</span>
        </Timeline.Item>
        <Timeline.Item dot={<p className="timeline-step">6</p>}>
          Enter the Mpesa PIN and Proceed
        </Timeline.Item>
      </Timeline>
      <p className="mpesa-transfer-innstructions--info">
        <i className="icon-ic-info-24px" />
        The amount paid will be transferred to your wallet
      </p>
    </div>
  );
};

export default MpesaTransferInstructions;
