import { Pagination } from './../../models/Pagination/pagination.model';
import { Withdraw } from './../../models/Withdraw/withdraw.model';
import {
  currentValuation,
  Share,
  ShareHistory,
  SingleShareCalculation,
} from "./../../models/Share/share.model";
import { serialize, deserialize } from "serializr";
import { useState } from "react";
import { Subscription } from "../../models/Subscription/subscription.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { ShareBreakdown, ShareUnit } from "../../models/Share/share.model";
import { Payment } from "../../models/Payment/payment.model";
import { recursiveRequest } from "../../shared/utils/recursiveRequest";

const ShareService = () => {
  const [share, setShare] = useState<ShareBreakdown>();
  const [sharesList, setSharesList] = useState<ShareUnit[]>([]);
  const [withdrawList, setWithdrawList] = useState<Withdraw[]>([]);
  const [withdrawPagination, setWithdrawPagination] = useState<Pagination>()
  const [sharesHistory, setSharesHistory] = useState<ShareHistory[]>([]);
  const [valuation, setValuation] = useState<currentValuation>();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSecondarySubmitting, setSecondarySubmitting] = useState(false);
  const [unitsLoading, setUnitsLoading] = useState(false);

  const getBuyUnitShares = async () => {
    setUnitsLoading(true);
    setSharesList([]);
    try {
      const response = await axiosInstance.get(ApiRoutes.SHARES_OPTION);
      if (response.data?.success) {
        const data = deserialize(
          ShareUnit,
          response.data["options"]
        ) as ShareUnit[];
        setSharesList(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setUnitsLoading(false);
    }
  };

  const getSellUnitShares = async () => {
    setUnitsLoading(true);
    setSharesList([]);
    try {
      const response = await axiosInstance.get(ApiRoutes.SHARES_SELL_OPTION);
      if (response.data?.success) {
        const data = deserialize(
          ShareUnit,
          response.data["options"]
        ) as ShareUnit[];
        setSharesList(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setUnitsLoading(false);
    }
  };

  const getBuySharesBreakdown = async (option:number, amount?:number, unit?:number) => {
    setLoading(true);
    setShare(new Share());
    const params = {
     option,
     amount,
     unit,
    };
    try {
      const response = await axiosInstance.get(ApiRoutes.SHARES_BREAKDOWN, {
        params,
      });
      if (response.data?.success) {
        const data = deserialize(
          ShareBreakdown,
          response.data["detailed_breakdown"]
        ) as ShareBreakdown;
        setShare(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getSellSharesBreakdown = async (unit: string) => {
    setLoading(true);
    setShare(new Share());
    const params = {
      unit,
    };
    try {
      const response = await axiosInstance.get(
        ApiRoutes.SHARES_SELL_BREAKDOWN,
        { params }
      );
      if (response.data?.success) {
        const data = deserialize(
          ShareBreakdown,
          response.data["detailed_breakdown"]
        ) as ShareBreakdown;
        setShare(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createBuyShares = async (share: Share) => {
    setSubmitting(true);

    const serialisedShare = serialize(Share, { ...share });
    try {
      const response = await axiosInstance.post(
        ApiRoutes.SHARES,
        serialisedShare
      );
      const data = deserialize(Share, response.data["order"]);
      if (!response.data?.success) {
        Notification({
          message: "",
          description: response.data?.message || "",
          type: NotificationTypes.ERROR,
        });
      }
      return data;
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const createSellShares = async (share: Share) => {
    setSubmitting(true);

    const serialisedShare = serialize(Share, share);
    try {
      const response = await axiosInstance.post(
        ApiRoutes.SHARES_SELL,
        serialisedShare
      );
      const data = deserialize(Share, response.data["order"]);
      if (!response.data?.success) {
        Notification({
          message: "",
          description: response.data?.message || "",
          type: NotificationTypes.ERROR,
        });
      }
      return data;
    } catch (error) {
      throw error;
    } finally {
      setSubmitting(false);
    }
  };

  const createPayment = async (share: Payment) => {
    setSubmitting(true);

    const serialisedPayment = serialize(Payment, share);
    try {
      const response = await axiosInstance.post(
        ApiRoutes.PAYMENT,
        serialisedPayment
      );
      if (response.data?.success) {
        const data = deserialize(Payment, response.data["payment_request"]);
        Notification({
          message: "",
          description: response.data?.message || "",
          type: NotificationTypes.SUCCESS,
        });
        return data;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const getBuySharesHistory = async () => {
    setLoading(true);
    setSharesHistory([]);
    try {
      const response = await axiosInstance.get(ApiRoutes.SHARES_HISTORY);
      if (response.data?.success) {
        const data = deserialize(
          ShareHistory,
          response.data["buy_order_history"]
        ) as ShareHistory[];
        setSharesHistory(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getSellSharesHistory = async () => {
    setLoading(true);
    setSharesHistory([]);
    try {
      const response = await axiosInstance.get(ApiRoutes.SHARES_SELL_HISTORY);
      if (response.data?.success) {
        const data = deserialize(
          ShareHistory,
          response.data["sell_order_history"]
        ) as ShareHistory[];
        setSharesHistory(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getSingleShareCalculationData = async () => {
    try {
      const data = new SingleShareCalculation();
      data.currentPrice = 20.88;
      data.buyPercent = 3;
      data.sellPercent = 3;
      return data;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  const getCurrentValuation = async () => {
    setUnitsLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.CURRENT_VALUATION);
      if (response.data?.success) {
        const data = deserialize(
          currentValuation,
          response.data["current_valuation"]
        ) as currentValuation;
        setValuation(data);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setUnitsLoading(false);
    }
  };

  const fetchWithdrawRequests = async (params?: { 
    page: number 
  }) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.WITHDRAW, { params });
      const data = deserialize(Withdraw, response.data["withdraw_requests"]["data"]) as Withdraw[]
      const pagination = deserialize(Pagination, response.data["withdraw_requests"])
      setWithdrawList(data)
      setWithdrawPagination(pagination)
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createWithdrawRequest = async (withdrawRequest: Withdraw) => {
    setSubmitting(true);
    try {
      const payload = serialize(Withdraw, withdrawRequest)
      const response = await axiosInstance.post(ApiRoutes.CREATE_WITHDRAW, payload);
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.SUCCESS,
      });
      return response.data;
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  const createOTPRequest = async (withdrawRequest: Withdraw) => {
    setSecondarySubmitting(true);
    try {
      const payload = serialize(Withdraw, withdrawRequest)
      const response = await axiosInstance.post(ApiRoutes.CREATE_WITHDRAW_OTP, payload);
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.SUCCESS,
      });
      return response.data;
    } catch (error) {
    } finally {
      setSecondarySubmitting(false);
    }
  };

  const verifyWithdrawOTP = async (email_otp: string) => {
    setSecondarySubmitting(true);
    try {
      const response = await axiosInstance.post(ApiRoutes.VERIFY_WITHDRAW_OTP, { email_otp });
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.SUCCESS,
      });
      return response.data;
    } catch (error) {
    } finally {
      setSecondarySubmitting(false);
    }
  };

  const createAutoDividendOtp = async () => {
    setSecondarySubmitting(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.AUTO_DIVIDEND_OTP);
    } catch (error) {
    } finally {
      setSecondarySubmitting(false);
    }
  };

  const createAutoDividendInvest = async (
    invest_option: boolean,
    otp?: string
  ) => {
    setSubmitting(true);
    try {
      const data = { invest_option, otp };
      const response = await axiosInstance.put(
        ApiRoutes.AUTO_DIVIDEND_INVEST,
        data
      );
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.SUCCESS,
      });
      return response.data;
    } catch (error) {
    } finally {
      setSubmitting(false);
    }
  };

  return {
    share,
    loading,
    isSubmitting,
    unitsLoading,
    sharesList,
    sharesHistory,
    valuation,
    createPayment,
    getBuyUnitShares,
    getSellUnitShares,
    createBuyShares,
    createSellShares,
    getBuySharesHistory,
    getSellSharesHistory,
    getBuySharesBreakdown,
    getSellSharesBreakdown,
    getSingleShareCalculationData,
    getCurrentValuation,
    withdrawList,
    fetchWithdrawRequests,
    withdrawPagination,
    createWithdrawRequest,
    createOTPRequest,
    verifyWithdrawOTP,
    isSecondarySubmitting,
    createAutoDividendInvest,
    createAutoDividendOtp,
    setShare,
  };
};

export default ShareService;
