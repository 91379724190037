import React, { useEffect, useState } from "react";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import "antd/dist/antd.css";
import "./styles/_main.scss";
import LocalStorage from "./shared/LocalStorage";
import AuthService from "./services/AuthService/auth.service";
import { LOGIN } from "./routes/routeConstants/appRoutes";

const App = () => {
	const {getUserDetails} = AuthService();
	const [isLoggedOut, setIsLoggedOut] = useState(false);

	useEffect(() => {
		// LocalStorage.clear();
		// if(LocalStorage?.getItem("vuka-token").length){
			getUserDetails(true, ()=>setIsLoggedOut(true));
		// }
	}, []);

	return (
		<Provider store={store}>
			<div className="App">
				<AppRoutes redirectUrl={isLoggedOut?LOGIN:undefined} />
			</div>
		</Provider>
	);
};

export default App;
