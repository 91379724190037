import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import OTPForm from "../../../shared/components/OTPForm";
import ShareService from "../../../services/SharesService/share.service";

export default function EnableDisableSwitch({
  disabled = false,
  switchStatus,
  phone,
}: {
  disabled?: boolean;
  switchStatus?: boolean;
  phone: string | undefined;
}) {
  const {
    isSecondarySubmitting,
    createAutoDividendOtp,
    createAutoDividendInvest,
  } = ShareService();
  const [otpModalVisibility, setOtpModalVisibility] = useState<boolean>(false);
  const [autoInvestEnabled, setAutoInvestEnabled] = useState<
    boolean | undefined
  >(switchStatus);

  const handleCancelOtpModal = () => {
    setOtpModalVisibility(false)
  }
  const otpVerificationHandler = async (otp: string) => {
    const response = await createAutoDividendInvest(true, otp);
    if (response.success) {
      setAutoInvestEnabled(true);
    }
    setOtpModalVisibility(false);
  };

  const handleSwitchToggle = async (isChecked: boolean) => {
    if (isChecked) {
      createAutoDividendOtp();
      setOtpModalVisibility(true);
    //   setAutoInvestEnabled(true);
    } else {
      createAutoDividendInvest(false);
      setAutoInvestEnabled(false);

    }
  };

  return (
    <>
      <OTPForm
        autoDividend
        handleModalCancel={handleCancelOtpModal}
        loading={isSecondarySubmitting}
        otpModalVisibility={otpModalVisibility}
        otpSubmissionHandler={(values) =>
          values.otp && otpVerificationHandler(values.otp)
        }
        mobileNumber={phone}
      ></OTPForm>

      <Switch checked={autoInvestEnabled} onChange={handleSwitchToggle} />
    </>
  );
}
