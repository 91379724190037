import React, { FC, useEffect, useState } from "react";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/subscriptions.png";
import "./subscriptionPlans.scss";
import Plan from "./Plan";
import { Col, Row, Spin } from "antd";
import SubscriptionService from "../../../services/SubscriptionService/subscription.service";
import { ActionProps } from "../../../shared/types/action.type";
import { Subscription } from "../../../models/Subscription/subscription.model";
import AuthService from "../../../services/AuthService/auth.service";
import { User } from "../../../models/User/user.model";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";

interface SubscriptionPlansProps extends OnBoardingReducerProps {
  setSubscription: (subscription: Subscription) => ActionProps;
  stepperLoading: boolean;
  currentUser: User;
}

const SubscriptionPlans: FC<SubscriptionPlansProps> = (props) => {
  const history = useHistory();
  const { setCurrentImage, setCurrentText, setSubscription, stepperLoading,currentUser} =
    props;

  const {
    getSubscriptionPlans,
    subscriptions,
    loading,
    createSubscription,
    SubscriptionAmount,
    getSubscriptionAmount,
  } = SubscriptionService();
  const [initialValue, setinitialValue] = useState({ id: "" });

  const { getUserDetails, user } = AuthService();

  useEffect(() => {
    console.log(currentUser)
    getUserDetails();
    getSubscriptionPlans(currentUser);
    setCurrentImage(BackgroundImage);
    setCurrentText("A New Beginning");

    /*  setinitialValue({
      id: subscriptions.filter(function (myId: any) {
        return myId.value === id;
      })[0].id,
    }); */
  }, []);

  const subscriptionSelectionHandler = async (subscription: Subscription) => {
    const proceedToHomeHandler = () => {
      history.push(AppRoutes.HOME);
    };

    setCurrentImage("");
    if ((subscription?.joiningFee || 0) + (subscription?.price || 0) === 0) {
      const subscriptionData = new Subscription();
      subscriptionData.subscriptionId = subscription.id;

      subscriptionData.useWalletOnly = true;
      try {
        // const upgrade = user?.lastSubscription!==undefined&&user?.lastSubscription?.code!==subscription?.code ? 1 : 0;

        // const renewal=user?.subscription||user?.lastSubscription!==undefined?0:1;
        if(user && subscription?.code){

          await createSubscription(subscriptionData,user,subscription?.code, proceedToHomeHandler);
          history.push(AppRoutes.HOME);
        }
      } catch (error) { }
    } else {
      setSubscription(subscription);
    }
  };

  return (
    <div className="subscriptions">
      <h1 className="text-primary text-center">
        Hey
        {` ${user?.kycBasicInfo?.firstName?.toLowerCase() || ""}`},{" "}
        {user?.userHasActivePlan ? "Upgrade" : "Choose"} your subscription
      </h1>
      <Spin spinning={loading || stepperLoading}>
        <Row className="plans-wrapper" justify={"center"}>
          {subscriptions.map((subscription) => (
            <Col sm={24} md={24} lg={24} key={subscription?.id} className="plans-wrapper__col">
              <Plan
                subscription={subscription}
                setSubscription={subscriptionSelectionHandler}
              />
            </Col>
          ))}
          {subscriptions.length === 0 ? "No Subscription Available" : null}
        </Row>
      </Spin>
    </div>
  );
};

export default OnBoardingContainer(SubscriptionPlans);
