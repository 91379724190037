import React, { FC, useEffect, useState } from "react";
import KYCService from "../../../../services/KYCService/kyc.service";
import { CloseOutlined } from "@ant-design/icons";
import LeftArrow from "../../../../assets/icons/left-arrow.svg";
import RightArrow from "../../../../assets/icons/right-arrow.svg";
import Question from "./Question";
import "./riskAssesmentQuestions.scss";
import { Formik, Form, FormikValues } from "formik";
import { Button } from "antd";
import { OnBoardingReducerProps } from "../../../../store/reducers/onBoardingReducer";
import OnBoardingContainer from "../../../../store/container/OnBoardingContainer";
import VukaIcon from "../../../../assets/icons/logo - svg/vuka-logo-light.svg";
import { bankDetailsValidation } from "../../BankDetailsForm/validation";
import { useHistory } from "react-router";
import * as AppRoutes from "../../../../routes/routeConstants/appRoutes";
interface RiskAssesmentQuestionsProps extends OnBoardingReducerProps {
	closeAssesmentHandler: () => void;
}

const RiskAssesmentQuestions: FC<RiskAssesmentQuestionsProps> = (props) => {
	const { closeAssesmentHandler, setKycStep } = props;
	const history = useHistory();

	const [questionNo, setQuestionNo] = useState(0);

	const [showSubmit, setShowSubmit] = useState<boolean>(false);

	const [initialValues, setInitialValues] = useState<any>({});

	const {
		getRiskAssesmentQuestions,
		riskAssesments,
		uploadRiskAssesmentAnswer,
		loading,
	} = KYCService();

	useEffect(() => {
		getRiskAssesmentQuestions();
	}, []);

	useEffect(() => {
		
		if (riskAssesments.length) {
			let answer = {};
			for (let i = 0; i < riskAssesments.length; i++) {
				answer = {
					...answer,
					[`key${i}`]: riskAssesments[i].multiSelect ? [] : undefined,
				};
				setInitialValues({
					answer,
				});
			}
		}
	}, [riskAssesments]);

	const handleChangeQuestion = (type: "next" | "prev") => {
		if (type === "next" && questionNo < riskAssesments.length - 1) {
			return setQuestionNo((questionNo) => questionNo + 1);
		}
		if (type === "prev" && questionNo > 0) {
			return setQuestionNo((questionNo) => questionNo - 1);
		}
	};

	const handleSubmit = async (values: FormikValues) => {
		const answersArray = Object.entries(values.answer).map(
			([_, value]) => value
		);
		const answers = answersArray.flatMap((value) => value);
		if (await uploadRiskAssesmentAnswer(answers as number[])) {
			// setKycStep(4);
			history.push(AppRoutes.SUBSCRIPTIONS);
		}
	};

	const formikProps = {
		initialValues,
		enableReinitialize: true,
		onSubmit: handleSubmit,
	};

	return (
		<div className="risk-assesment-questions">
			<div className="header">
				<img src={VukaIcon} alt="" />
				<h1>Risk Assesment</h1>
				<CloseOutlined
					className="cursor-pointer"
					onClick={closeAssesmentHandler}
				/>
			</div>
			<Formik {...formikProps}>
				{({ values, setFieldValue }) => {
					return (
						<Form>
							<Question
								multiSelect={
									riskAssesments?.length
										? riskAssesments[questionNo].multiSelect
										: false
								}
								answer={values.answer}
								questionNo={questionNo}
								question={
									riskAssesments?.length
										? riskAssesments[questionNo]
										: undefined
								}
								changeQuestionHandler={handleChangeQuestion}
								setFieldValue={setFieldValue}
								setShowSubmit={setShowSubmit}
							/>
							{riskAssesments.length - 1 === questionNo && showSubmit && (
								<div className="risk-assesment__submit">
									<Button
										htmlType="submit"
										type="primary"
										loading={loading}
									>
										Submit
									</Button>
								</div>
							 )} 
						</Form>
					);
				}}
			</Formik>
			{riskAssesments.length ? (
				<div className="question-controller">
					<div
						className="icon"
						onClick={() => handleChangeQuestion("prev")}
					>
						<img src={LeftArrow} />
					</div>
					<p>
						{questionNo + 1} of {riskAssesments.length}
					</p>
					<div
						className="icon"
						onClick={() => handleChangeQuestion("next")}
					>
						<img src={RightArrow} />
					</div>
				</div>
			) : null}
		</div>
	);
};

export default OnBoardingContainer(RiskAssesmentQuestions);
