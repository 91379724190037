import React, { FC, useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Stepper } from "react-form-stepper";
import "./timeline.scss";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";

interface TimelineProps extends OnBoardingReducerProps {}

const Timeline: FC<TimelineProps> = (props) => {
	const { kycStep } = props;

	const [timelines, setTimeline] = useState([
		{
			label: "Profile Details",
 			children: <i className="icon-profile" />,
		},
		{
			label: "Upload Documents",
			children: <i className="icon-upload-documents" />,
		},
		{
			label: "Video KYC",
			children: <i className="icon-video-kyc" />,
		},
		{
			label: "Risk Assessment",

			children: <i className="icon-risk-assesment" />,
		},
		// {
		// 	label: "Bank Details",
		// 	children: <i className="icon-bank-details" />,
		// },
	]);

	return (
		<div className="timeline">
			<Stepper
				steps={timelines}
				activeStep={kycStep}
				connectorStyleConfig={{
					completedColor: "#F068C4",
					activeColor: "#F068C4",
					disabledColor: "#B2B4B5",
					size: 1,
					style: "dashed",
				}}
			/>
		</div>
	);
};

export default OnBoardingContainer(Timeline);
