import { Button } from "antd";
import { ErrorMessage, Field } from "formik";
import React, { FC, useRef } from "react";
import PDFIcon from "../../../assets/icons/pdf.svg";
import PNGIcon from "../../../assets/icons/png.png";
import { CloseOutlined } from "@ant-design/icons";
import Error from "../Error";
import "./documentUpload.scss";
import { formatCountdown } from "antd/lib/statistic/utils";

interface DocumentUploadProps {
	title?: string;
	placeholder?: string;
	value: any;
	name: string;
	setFieldValue: (key: string, value: any) => void;
	bold?: boolean;
	accept?: string
}

const DocumentUpload: FC<DocumentUploadProps> = (props) => {
	const {
		title,
		name,
		value,
		bold,
		placeholder = '',
		setFieldValue,
		accept = "application/pdf, image/png, image/jpg, image/jpeg",
	} = props;


	const inputRef = useRef<HTMLInputElement>(null);

	const handleUploadDocument = () => {
		inputRef.current?.click();
	};

	const handleFile = (event: any) => {
		const image = event.target.files[0];
		if (image && image?.type === "application/pdf" || image && image?.type === "image/png" || image?.type === "image/jpg" || image?.type === "image/jpeg") {
			return setFieldValue(name, image);
		}
	};

	const handleRemoveDocument = () => setFieldValue(name, null);

	return (
		<Field name={name}>
			{({
				field,
				form,
				meta,
			}: any) => {
				const { touched, error } = meta
				return (
					<div className="document-upload mb-4">
						<label htmlFor="" className={bold ? "make-heavy" : ""}>
							{title}
						</label>
						{value ? (
							<div className="drag-and-drop__display">
								<div className="content">
									<img src={value?.name?.includes('pdf') ? PDFIcon : PNGIcon} alt="" /> &nbsp;
									{value?.name}
								</div>
								<CloseOutlined onClick={handleRemoveDocument} />
							</div>
						) : (
							<div
								className="drag-and-drop__container"
								onClick={() => {
									form?.setFieldTouched(name, true)
									handleUploadDocument()
								}}
							>
								{placeholder
									? <p>{placeholder}</p>
									: <p>
										Drag & Drop <br /> your files here
									</p>}
								<Button type="text">Browse Files</Button>
								<input
									type="file"
									ref={inputRef}
									hidden
									onChange={handleFile}
									accept={accept}
								/>
							</div>
						)}
						{touched && <Error className={`${name}__error error-message`} message={error} />}
					</div>
				);
			}}
		</Field>
	);
};

export default DocumentUpload;
