import { Modal } from "antd";
import React from "react";
import "./uiModal.scss"
import { FC } from "hoist-non-react-statics/node_modules/@types/react";

interface UIModalProps {
    visible: boolean;
    onClose: () => void
    onOk: () => void
    okText?: string
    title?: string
    className?: string
    loading?: boolean
    closable?: boolean
}

const UIModal: FC<UIModalProps> = (props) => {
    const {
        visible,
        onClose,
        onOk,
        title = '',
        okText = 'Ok',
        children,
        loading,
        className = '',
        closable = true,
    } = props;



    return (
        <Modal
            className={`ui-modal ${className}`}
            visible={visible}
            okText={okText}
            okButtonProps={{
                loading
            }}
            closable={closable}
            centered={true}
            confirmLoading={false}
            onOk={onOk}
            onCancel={onClose}
            destroyOnClose
            title={title}>
            {children}
        </Modal>
    );
};

export default UIModal;
