import { serializable, alias, primitive, object, list } from "serializr";
import { CustomerTypes } from "../../enums/customerTypes";
import {
  BankDetail,
  BeneficiaryDetail,
  EmergencyContact,
  InvestmentDetails,
} from "../Customer/customer.model";

class Subscription {
  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("code", primitive()))
  code?: string;
}

type RejectedDocumentType =
  | "verification_video"
  | "photograph"
  | "kenyan_id_proof"
  | "KRA_pin";

export class KYCBasicInfo {
  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("middle_name", primitive()))
  middleName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("kenyan_national_number", primitive()))
  kenyanNationalNumber?: string;

  @serializable(alias("KRA_pin_number", primitive()))
  KRAPinNumber?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("id_number", primitive()))
  idNumber?: string;

  @serializable(alias("date_of_birth", primitive()))
  dateOfBirth?: string;

  @serializable(alias("district_of_birth", primitive()))
  districtOfBirth?: string;

  @serializable(alias("place_of_issue", primitive()))
  placeOfIssue?: string;

  @serializable(alias("date_of_issue", primitive()))
  dateOfIssue?: string;

  @serializable(alias("district", primitive()))
  district?: string;

  @serializable(alias("division", primitive()))
  division?: string;

  @serializable(alias("location", primitive()))
  location?: string;

  @serializable(alias("sub_location", primitive()))
  subLocation?: string;

  @serializable(alias("user_type", primitive()))
  userType?: string;

  @serializable(alias("user_role", primitive()))
  userRole?: string;

  @serializable(alias("is_from_iprs", primitive()))
  isFromIprs?: boolean;

  @serializable(alias("attempts_remaining", primitive()))
  attemptsRemaining?: number;
}
export class DocumentsRejected {
  @serializable(alias("document", primitive()))
  document?: RejectedDocumentType;

  @serializable(alias("rejection_reason", primitive()))
  reason?: string;
}

export class User {
  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("phone", primitive()))
  mobileNumber?: string;

  @serializable(alias("dial_code", primitive()))
  dialCode?: number = 254;

  @serializable(alias("invite_code", primitive()))
  inviteCode?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("name", primitive()))
  username?: string;

  @serializable(alias("is_kyc_accepted", primitive()))
  kycAccepted?: string;

  @serializable(alias("rejected_documents", list(object(DocumentsRejected))))
  rejectedDocuments?: DocumentsRejected[];

  @serializable(alias("role", primitive()))
  role?: CustomerTypes;

  @serializable(alias("uid", primitive()))
  uid?: string;

  @serializable(alias("user_has_active_plan", primitive()))
  userHasActivePlan?: boolean;

  @serializable(alias("subscription_auto_renewal", primitive()))
  subscriptionAutoRenewalEnabled?: 0 | 1;

  @serializable(alias("active_subscription", object(Subscription)))
  subscription?: Subscription;

  @serializable(alias("last_active_subscription", object(Subscription)))
  lastSubscription?: Subscription;

  @serializable(alias("member_since", primitive()))
  memberSince?: string;

  @serializable(alias("kyc_basic_info", object(KYCBasicInfo)))
  kycBasicInfo?: KYCBasicInfo;

  @serializable(alias("kyc_bank_details", object(BankDetail)))
  kycBankDetails?: BankDetail;

  @serializable(alias("beneficiary_detail", object(BeneficiaryDetail)))
  beneficiaryDetail?: BeneficiaryDetail;

  @serializable(alias("emergency_contact", object(EmergencyContact)))
  emergencyContact?: EmergencyContact;

  @serializable(alias("investment_details", object(InvestmentDetails)))
  investmentDetails?: InvestmentDetails;

  @serializable(alias("auto_dividend_invest_option", primitive()))
  autoDividendInvestOption?: 0 | 1;

  @serializable(alias("country_id", primitive()))
  countryId?:number
}
