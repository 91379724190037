import { serializable, alias, object, list, primitive } from "serializr";
import { Country } from "../Country/country.model";

export class BankDetail {
  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("account_holder_name", primitive()))
  accountHolderName?: string;

  @serializable(alias("account_number", primitive()))
  accountNumber?: string;

  @serializable(alias(" ", primitive()))
  confirmAccountNumber?: string;

  @serializable(alias("bank_name", primitive()))
  bankName?: string;

  @serializable(alias("branch_name", primitive()))
  branchName?: string;

  @serializable(alias("is_savings_account", primitive()))
  isSavingsAccount?: boolean;

  @serializable(alias("account_type", primitive()))
  accountType?: number;

  @serializable(alias("bank_account_type", primitive()))
  bankAccountType?: string;

  @serializable(alias("swift_code", primitive()))
  swiftCode?: string;

  @serializable(alias("country", object(Country)))
  country?: Country;

  @serializable(alias("country_id", primitive()))
  countryId?: string;

  @serializable(alias("currency", primitive()))
  currency?: string;
}

export class EmergencyContact {
  @serializable(alias("name", primitive()))
  name?: number;

  @serializable(alias("phone", primitive()))
  phone?: string;
}

export class InvestmentDetails {
  @serializable(alias("invested_amount", primitive()))
  investmentAmount?: number;

  @serializable(alias("invested_times", primitive()))
  investmentTime?: number;

  @serializable(alias("invested_months", primitive()))
  investmentMonths?: number;

  @serializable(alias("invested_year", primitive()))
  investedYear?: number;
}

export class BeneficiaryDetail {
  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("kenyan_national_number", primitive()))
  kenyanNationalNumber?: string;

  @serializable(alias("is_alien", primitive()))
  isAlien?: string;

  @serializable(alias("phone", primitive()))
  phone?: string;

  @serializable(alias("relation", primitive()))
  relation?: string;
}

export class Customer {
  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("middle_name", primitive()))
  middleName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("attempts_remaining", primitive()))
  attemptsRemaining?: number;

  @serializable(alias("kenyan_national_number", primitive()))
  kenyanNationalNumber?: string;

  @serializable(alias("KRA_pin_number", primitive()))
  KRAPinNumber?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("id_number", primitive()))
  idNumber?: string;

  @serializable(alias("is_from_iprs", primitive()))
  isFromIprs?: 0 | 1;

  @serializable(alias("date_of_birth", primitive()))
  dateOfBirth?: string;

  @serializable(alias("district_of_birth", primitive()))
  districtOfBirth?: string;

  @serializable(alias("place_of_issue", primitive()))
  placeOfIssue?: string;

  @serializable(alias("date_of_issue", primitive()))
  dateOfIssue?: string;

  @serializable(alias("district", primitive()))
  district?: string;

  @serializable(alias("division", primitive()))
  division?: string;

  @serializable(alias("location", primitive()))
  location?: string;

  @serializable(alias("sub_location", primitive()))
  subLocation?: string;

  @serializable(alias("user_type", primitive()))
  userType?: string;

  @serializable(alias("user_role", primitive()))
  userRole?: string;

  @serializable(alias("kyc_bank_details", object(BankDetail)))
  kycBankDetails?: BankDetail;

  @serializable(alias("beneficiary_detail", object(BeneficiaryDetail)))
  beneficiaryDetail?: BeneficiaryDetail;

  @serializable(alias("emergency_contact", object(EmergencyContact)))
  emergencyContact?: EmergencyContact;
}

