import { Button } from "antd";
import { Form, Formik, FieldArray, FormikValues, Field } from "formik";
import React, { FC, Fragment, useEffect } from "react";
import { useState } from "react";
import BackgroundImage from "../../../assets/images/upload-document.png";
import { CustomerTypes } from "../../../enums/customerTypes";
import { Customer } from "../../../models/Customer/customer.model";
import { Documents, UploadDocumentModal } from "../../../models/Documents/documents.model";
import AuthService from "../../../services/AuthService/auth.service";
import KYCService from "../../../services/KYCService/kyc.service";
import DocumentUpload from "../../../shared/components/DocumentUpload";
import InputField from "../../../shared/components/InputField";
import UppyFileUploader from "../../../shared/components/UppyFileUploader";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import "./uploadDocuments.scss";
import { UploadDocumentIndividualValidation, UploadDocumentChamaValidation } from "./validation";

interface UploadDocumentsProps extends OnBoardingReducerProps { }

const constrain = "PDF or Jpeg or Png files allowed. Max size per file should be 5 MB"

const UploadDocuments: FC<UploadDocumentsProps> = (props) => {
	const { setCurrentImage, setCurrentText, setKycStep } = props;

	const { uploadKYCDocuments, loading, customer, getCustomerDetails } = KYCService();
	const [agreementFileName, setAgreementFileName] = useState<string>("");
	const [agreementUploadUrl, setAgreementUploadUrl] = useState<string>("");

	useEffect(() => {
		getCustomerDetails()
		setCurrentImage(BackgroundImage);
		setCurrentText("For Kenyans");
	}, []);

	const handleSubmit = async (values: FormikValues) => {
		const documents = Object.assign(new UploadDocumentModal(), values);
		if (await uploadKYCDocuments(documents)) {
			setKycStep(2);
		}
	};

	const formikProps = {
		initialValues: {
			...new UploadDocumentModal(),
		} as UploadDocumentModal,
		validationSchema: customer?.userRole === CustomerTypes.CHAMA
			? UploadDocumentChamaValidation
			: UploadDocumentIndividualValidation,
		onSubmit: handleSubmit,
	};

	return (
		<div className="upload-documents">
			<h1 className="text-primary text-center">Upload Documents</h1>
			<p className="constrain">
				{constrain}
			</p>
			<Formik {...formikProps} enableReinitialize>
				{({ values, setFieldValue, isValid, dirty, setFieldError, errors, registerField }) => (
					<Form>
						<DocumentUpload
							title="Photograph"
							name="photograph"
							placeholder="Your recent and clear photograph"
							value={values.photograph}
							setFieldValue={setFieldValue}
							bold
						/>
						{/* <Field name="document" as="file-upload">
							<UppyFileUploader
								title="Photograph"
								setFileUploadUrl={setAgreementUploadUrl}
								setFileUploadName={setAgreementFileName}
								fileUploadUrl={agreementUploadUrl}
							/>
						</Field> */}
						<DocumentUpload
							title="Kenyan ID proof"
							name="idProof"
							value={values.idProof}
							setFieldValue={setFieldValue}
							bold
						/>
						<DocumentUpload
							title="KRA Pin"
							name="KRAPin"
							value={values.KRAPin}
							setFieldValue={setFieldValue}
							bold
						/>
						{console.log(errors)}
						{customer?.userRole === CustomerTypes.CHAMA && (
							<FieldArray
								name="chamaPersons"
								render={(arrayHelpers) => {
									return [1, 2].map((_, index) => (
										<Fragment key={_}>
											<p className="title__charma">
												Chama person {index + 2} Details{" "}
												{index ? "(Optional)" : ""}
											</p>
											<InputField
												title="First name"
												name={`chamaPersons[${index}].firstName`}
												type="text"
											/>
											<InputField
												title="Middle name"
												name={`chamaPersons[${index}].middleName`}
												type="text"
											/>
											<InputField
												title="Last name"
												name={`chamaPersons[${index}].lastName`}
												type="text"
											/>
											<InputField
												title="Kenyan National Number / Alien Number"
												name={`chamaPersons[${index}].kenyanNationalNumber`}
												type="text"
											/>
											<InputField
												title="KRA Pin number"
												name={`chamaPersons[${index}].KRAPinNumber`}
												type="text"
											/>
											<DocumentUpload
												title="Photograph"
												placeholder="Your recent photograph with clear background"
												name={`chamaPersons[${index}].photograph`}
												value={
													values.chamaPersons?.length && values.chamaPersons[index]?.photograph
												}
												setFieldValue={setFieldValue}
											/>
											<DocumentUpload
												title="Kenyan ID proof"
												name={`chamaPersons[${index}].idProof`}
												value={
													values?.chamaPersons?.length && values.chamaPersons[index]?.idProof
												}
												setFieldValue={setFieldValue}
											/>
											{/* <DocumentUpload
												title="KRA Pin"
												name={`chamaPersons[${index}].KRAPin`}
												value={
													values?.chamaPersons[index].KRAPin
												}
												setFieldError={setFieldError}
												setFieldValue={setFieldValue}
											/> */}
										</Fragment>
									));
								}}
							/>
						)}
						<div className="submit-btn">
							<Button
								type="primary"
								htmlType="submit"
								disabled={!isValid || !dirty}
								loading={loading}
							>
								Next
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default OnBoardingContainer(UploadDocuments);
