import React, { FC } from "react";
import AuthService from "../../../../services/AuthService/auth.service";
import Navbar from "../../Navbar";
import "./appLayout.scss";
import SidebarMobileView from "../../SidebarMobileView/index";
import Sidebar from "../../Sidebar";

interface AppLayoutProps {}

const AppLayout: FC<AppLayoutProps> = (props) => {
  const { children } = props;

  const { logoutUser } = AuthService();

  return (
    <div className="app__container">
      <div className="nav__container">
        <Sidebar logoutHandler={logoutUser} />
      </div>
      <SidebarMobileView logoutHandler={logoutUser} />

      <div className="route__container">{children}</div>
    </div>
  );
};

export default AppLayout;
