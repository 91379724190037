import axios from 'axios';
import { store } from "../store";
import { REQUEST_LOGOUT } from "../store/definitions/authConstants";
import { ApiRoutes } from "../routes/routeConstants/apiRoutes";
import LocalStorage from '../shared/LocalStorage';
import { NotificationTypes } from '../enums/notificationTypes';
import Notification from "../shared/components/Notification";

export const getHeaders = (): any => {
    let headers: any = {
        'Content-Type': 'application/json'
    };
    if (LocalStorage.getItem("vuka-token")) {
        const token = LocalStorage.getItem("vuka-token");
        headers.Authorization = `Bearer ${token}`;
    }
    return headers;
};

const axiosInstance = axios.create({
    baseURL: ApiRoutes.BASE_URL,
    timeout: 100000,
});

axiosInstance.interceptors.request.use(function (config) {
    config.headers = getHeaders();
    return config;
});

axiosInstance.interceptors.response.use(
    (response): any => {
        return {
            data: response.data,
            message: response.statusText,
            status: response.status
        }
    },
    (error) => {
        const { response } = error;
        if (response?.status === 401) {
            Notification({
                message: '',
                description: response?.data?.message || "Access Denied",
                type: NotificationTypes.ERROR
            });
            store.dispatch({ type: REQUEST_LOGOUT, payload: 'Request for logout' });
            return Promise.reject(error);;
        }

        if (response?.data?.message && response?.status !== 500) {
            const errors = Object.values(response.data?.errors ? response.data?.errors : {}) as string[]
            const finalError = errors.length ? errors[0] : response.data?.message
            Notification({
                message: "",
                description: finalError,
                type: NotificationTypes.ERROR,
            });
        } else {
            Notification({
                message: "",
                description: response?.data?.message || error?.message,
                type: NotificationTypes.ERROR,
            });
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
