import React, { FC, useEffect, useState } from "react";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/create-account.png";
import "./accountDetailsForm.scss";
import { User } from "../../../models/User/user.model";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Dropdown, Menu, Select, Row, Col, Space } from "antd";
import MessageIcon from "../../../assets/icons/message.svg";
import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import { useHistory } from "react-router";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import { DownOutlined } from "@ant-design/icons";
import { createAccountValidationSchema } from "./validation";
import { OptionProps } from "../../../shared/types/option.type";
import AuthService from "../../../services/AuthService/auth.service";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { useLocation } from "react-router-dom";
import DropdownField from "../../../shared/components/DropdownField";
import CountryService from "../../../services/CountryService/country.service";
import PhoneNumberField from "../../../shared/components/PhoneNumberField";
import { CountryData } from "react-phone-input-2";

interface AccountDetailsFormProps
  extends OnBoardingReducerProps,
    AuthReducerProps {
  otpSendHandler: (show: boolean) => void;
}

interface CountryOption {
  label?: string;
  value?: number;
  countryCode?: string;
}

const AccountDetailsForm: FC<AccountDetailsFormProps> = (props) => {
  const { setCurrentImage, otpSendHandler, setCurrentText, setUser } = props;
  const { search } = useLocation();

  const [showLogin, setShowLogin] = useState(false);

  const history = useHistory();

  const { createAccount, loading, error } = AuthService();
  const {
    loading: countryListLoading,
    countries,
    getCountries,
  } = CountryService();

  useEffect(() => {
    getCountries();
    setCurrentImage(BackgroundImage);

    const url = new URLSearchParams(search);
    const inviteCode = url.get("invite_code") || undefined;
    const email = url.get("email") || undefined;
    formikProps.initialValues.inviteCode = inviteCode;
    formikProps.initialValues.email = email;

    const hasInviteCodeAndEmail = Boolean(inviteCode && email);
    setShowLogin(!hasInviteCodeAndEmail);

    setCurrentText("Your Journey Starts Now");
    return () => {
      setCurrentText("");
    };
  }, []);

  const handleLogin = () => {
    history.push(AppRoutes.LOGIN);
  };

  const handleSubmit = async (values: User) => {
    try {
      const user = Object.assign(new User(), values);
      const userDetails = await createAccount(user);
      if (userDetails) {
        setUser(userDetails);
        otpSendHandler(true);
      }
    } catch (error) {}
  };

  const formikProps = {
    initialValues: {
      ...new User(),
      confirmPassword: "",
      countryId: 115,
      dialCode: 254,
      countryCode: undefined,
    },
    validationSchema: createAccountValidationSchema,
    onSubmit: handleSubmit,
  };

  const mapCountriesToOptions = (): CountryOption[] => {
    return countries
      ? countries.map((country) => ({
          label: country?.name,
          value: country?.id,
          countryCode: country?.countryCode,
        }))
      : [];
  };

  return (
    <div className="account-details-form">
      <h1 className="text-primary text-center">Create Account</h1>
      <Formik {...formikProps}>
        {({ isValid, dirty, setFieldValue, values }) => {
          const handleCountryChange = (
            value: number | string,
            options: CountryOption
          ) => {
            setFieldValue("countryId", value);
            setFieldValue("countryCode", options.countryCode);
          };

          const handleMobileNumberChange = (
            value: string,
            data: CountryData
          ) => {
            setFieldValue("dialCode", data.dialCode);
            setFieldValue("mobileNumber", value.slice(data.dialCode.length));
          };
          return (
            <Form>
              <InputField
                type="text"
                title="Invite code"
                name="inviteCode"
                disabled
              />
              <InputField
                type="text"
                title="Email ID"
                name="email"
                placeholder="Enter Email ID"
              />

              <Row gutter={18}>
                <Col xs={24} sm={24} md={12}>
                  <DropdownField
                    name="countryId"
                    title="Country"
                    placeHolder="Select Country"
                    showSearch
                    options={mapCountriesToOptions()}
                    loading={countryListLoading}
                    onChange={handleCountryChange}
                    value={values.countryId}
                  />
                </Col>
                <Col xs={24} sm={24} md={12}>
                  <PhoneNumberField
                    title="Mobile number"
                    name="mobileNumberWithCode"
                    placeholder="Enter number"
                    onChange={handleMobileNumberChange}
                    country={values.countryCode ?? "ke"}
                  />
                </Col>
              </Row>

              <InputField
                type="password"
                title="Password"
                name="password"
                placeholder="Enter Password"
                disablepaste
              />
              <InputField
                type="password"
                title="Confirm Password"
                name="confirmPassword"
                placeholder="Enter Confirm password"
                disablepaste
              />
              <div className="submit-btn mt-5">
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  disabled={!isValid || !dirty}
                  loading={loading}
                >
                  <Space size={10}>
                    {!loading && <img src={MessageIcon} alt="" />} Send OTP
                  </Space>
                </Button>
              </div>
              {showLogin ? (
                <p className="text-center text-primary mt-5 mb-2">
                  Already have an account?{" "}
                  <span
                    className="text-link cursor-pointer login-link"
                    onClick={handleLogin}
                  >
                    Login here
                  </span>
                </p>
              ) : null}

              <TermsAndPolicy />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default OnBoardingContainer(AuthContainer(AccountDetailsForm));
