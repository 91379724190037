import {serializable, alias, primitive, custom} from 'serializr';

export class KYCVideo {
    @serializable(
        alias(
            "verification_video",
            custom(
                (file) => file,
                (file) => file
            )
        )
    )
    verificationVideo?: File;

    @serializable(alias('code', primitive()))
    code?: string;

    @serializable(alias('verification_code', primitive()))
    verificationCode?: string;


}
