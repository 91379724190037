import { CreateReducer } from "../../shared/utils/createReducer";
import { ActionProps } from "../../shared/types/action.type";
import {
    SET_CURRENT_IMAGE,
    SET_CURRENT_TEXT,
    SET_KYC_STEP,
} from "../definitions/onBoardingConstants";

export interface OnBoardingState {
    currentImage?: string;
    currentText?: string;
    kycStep: number;
}

export interface OnBoardingReducerProps extends OnBoardingState {
    setCurrentImage: (image: string) => ActionProps;
    setKycStep: (kycStep: number) => void;
    setCurrentText: (text: string) => ActionProps;
}

const initState: OnBoardingState = {
    currentImage: undefined,
    currentText: undefined,
    kycStep: 0,
};

const onBoardingReducer = CreateReducer(initState, {
    [SET_CURRENT_IMAGE](
        state: OnBoardingState,
        action: ActionProps
    ): OnBoardingState {
        const { currentImage } = action?.payload;
        return { ...state, currentImage };
    },
    [SET_CURRENT_TEXT](
        state: OnBoardingState,
        action: ActionProps
    ): OnBoardingState {
        const { currentText } = action?.payload;
        return { ...state, currentText };
    },
    [SET_KYC_STEP](
        state: OnBoardingState,
        action: ActionProps
    ): OnBoardingState {
        const { kycStep } = action.payload;
        return {
            ...state,
            kycStep,
        };
    },
});

export default onBoardingReducer;
