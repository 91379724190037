export const AccountTypeOptions = [
  {
    label: "Savings",
    value: true,
  },
  {
    label: "Current",
    value: false,
  },
];

export const BankAccountType = [
  {
    label: "International Bank Account",
    value: 0,
  },
  {
    label: "Kenyan Bank Account",
    value: 1,
  },
];

export const CurrencyOptions = [
  {
    label: "United States Dollar, USD",
    value: "United States Dollar, USD",
  },
  {
    label: "Euro",
    value: "Euro",
  },
  {
    label: "Great Britain Pound, GBP",
    value: "Great Britain Pound, GBP",
  },
  {
    label: "Swiss Franc, CHF",
    value: "Swiss Franc, CHF",
  },
  {
    label: "Austarian Dollar, AUD",
    value: "Austarian Dollar, AUD",
  },
  {
    label: "South African Rand, ZAR",
    value: "South African Rand, ZAR",
  },
  {
    label: "Canadian Dollar, CAD",
    value: "Canadian Dollar, CAD",
  },
];

export const AccountType = {
  SAVINGS: 1,
  CURRENT: 0,
};

export const accountType = {
  SAVINGS: "Savings Account",
  CURRENT: "Current Account",
};
