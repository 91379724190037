import React, { FC } from "react";
import Instruction1 from "../../../../assets/images/instruction1.svg";
import Instruction2 from "../../../../assets/images/instruction2.svg";
import Instruction3 from "../../../../assets/images/instruction3.svg";
import VideoIcon from "../../../../assets/icons/video-icon.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./videoInstructions.scss";
import { Button } from "antd";

interface VideoInstructionsProps {
	startVideoHandler: (start: boolean) => void;
}

const instructions = [
	{
		key: '1',
		text: "The first of its kind in Kenya, making Vuka platform safe for investors like you.​",
	},
	{
		key: '2',
		text: "The Video KYC process will require you to read the random numbers appearing on your screen. "
	},
	{
		key: '3',
		text: "Kindly make sure you are in a well lit environment and your mic is working fine.",
	},
	{
		key: '4',
		text: "Follow the simple instructions and complete your Video KYC",
	},{
		key:'5',
		text:'On clicking the “Start KYC” you will be requested to allow access to your laptop or phone camera to complete the Video KYC'
	},{
		key:'6',
		text:'Submit the recording to complete the Video KYC​'
	}
];

const VideoInstructions: FC<VideoInstructionsProps> = (props) => {
	const { startVideoHandler } = props;

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	const handleVideoPermissions = () => {
		startVideoHandler(true)
	}

	return (
		<div className="video-instructions">
			<h1 className="text-primary text-center">Video KYC</h1>
			<p className="text-center instruction-title mt-4">Instruction</p>
			<ul className="instructions mb-5">
				{instructions.map((instruction) => (
					<li key={instruction.key}>{instruction.text}</li>
				))}
			</ul>
			<Slider {...settings}>
				<div className="slider-image__container">
					<img src={Instruction1} alt="" />
					<p className="text-center text-primary">
						Tap the start KYC button to open camera and tap start
						recording
					</p>
				</div>
				<div className="slider-image__container">
					<img src={Instruction2} alt="" />
					<p className="text-center text-primary">
						Speak loud the 5 digit code shown in the screen. Speak
						the code one by one
					</p>
				</div>
				<div className="slider-image__container">
					<img src={Instruction3} alt="" />
					<p className="text-center text-primary">
						Once you complete submit the KYC for verification
					</p>
				</div>
			</Slider>

			<div className="submit-btn">
				<Button
					type="primary"
					htmlType="submit"
					onClick={() => handleVideoPermissions()}
				>
					<img src={VideoIcon} alt="" />
					&nbsp; Start KYC
				</Button>
			</div>
		</div>
	);
};

export default VideoInstructions;
