import React, { useRef, useEffect,FC, Fragment, useState } from "react";
import DynamicText from "../../../shared/components/DynamicText";
import PageHeader from "../../../shared/components/PageHeader";
import "./report.scss";

interface ReportProps {}

const Report: FC<ReportProps> = (props) => {
  
  return (
    <Fragment>
      <PageHeader title="Report" />
    </Fragment>
  );
};

export default Report;
