import { connect } from "react-redux";
import { Dispatch, bindActionCreators } from "redux";
import { RootReducerProps } from "../reducers/index";
import * as OnBoardingFunctions from "../actions/onBoarding";

const mapStateToProps = (state: RootReducerProps, ownProps: any) => ({
    ...ownProps,
    currentImage: state.onBoarding.currentImage,
    currentText: state.onBoarding.currentText,
    kycStep: state.onBoarding.kycStep,
    currentUser:state.auth.user
});

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(OnBoardingFunctions, dispatch);

const OnBoardingContainer = (component: any) => {
    return connect(mapStateToProps, mapDispatchToProps)(component);
};

export default OnBoardingContainer;
