import { serializable, custom, alias, primitive, list, object } from 'serializr';


export class Document {
    @serializable(alias('first_name', primitive()))
    firstName?: string;

    @serializable(alias('middle_name', primitive()))
    middleName?: string;

    @serializable(alias('last_name', primitive()))
    lastName?: string;

    @serializable(alias('kenyan_national_number', primitive()))
    kenyanNationalNumber?: string;

    @serializable(alias('KRA_pin_number', primitive()))
    KRAPinNumber?: string;

    @serializable(
        alias(
            "photograph",
            custom(
                (file) => file,
                (file) => file
            )
        )
    )
    photograph?: File;

    @serializable(
        alias(
            "kenyan_id_proof",
            custom(
                (file) => file,
                (file) => file
            )
        )
    )
    idProof?: File;

}

export class Documents extends Document {
    @serializable(alias('members', list(object(Document))))
    members: Document[] = [];
}

export class UploadDocumentModal {

    @serializable(
        alias(
            "photograph",
            custom(
                (file) => file,
                (file) => file
            )
        )
    )
    photograph?: File;

    @serializable(
        alias(
            "kenyan_id_proof",
            custom(
                (file) => file,
                (file) => file
            )
        )
    )
    idProof?: File;

    @serializable(
        alias(
            "KRA_pin",
            custom(
                (file) => file,
                (file) => file
            )
        )
    )
    KRAPin?: File;

    @serializable(alias('chama_persons', list(object(Document))))
    chamaPersons?: Document[];

    @serializable(alias('reupload', primitive()))
    reupload?: boolean;
}