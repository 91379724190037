import * as yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const CustomTypeValidation = yup.object().shape({
  idType: yup
    .string()
    .required('Please select the proof type'),
  kenyanNationalNumber: yup
    .string()
    .required("Kenyan National Number is required!")
    .min(1, "Kenyan National Number must be 8 characters")
    .max(8, "Kenyan National Number must be 8 characters")
    .matches(REGEX.ALPHANUMERIC, "Kenyan National Number should be numeric"),
  KRAPinNumber: yup
    .string()
    .required("KRA pin is required!")
    .min(11, "KRA pin must be 11 characters")
    .max(11, "KRA pin must be 11 characters")
    .matches(REGEX.ALPHANUMERIC, "KRA pin should not include special characters"),
  chamaName: yup.string().when("isRegistered", {
    is: true,
    then: yup
      .string()
      .required("Registered Chama Name is required")
      .min(3, 'Registered Chama Name should atleast have 3 characters')
  }),
});
