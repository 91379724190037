import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import React, { Fragment, useEffect } from "react";
import InformationService from "../../../services/InformationService/information.service";
import PageHeader from "../../../shared/components/PageHeader";
import "./faq.scss";

export const Faq = () => {
  const { faqs, getFaqs } = InformationService();

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <Fragment>
      <PageHeader title="FAQs" />

      <div className="faq--container">
        <Collapse
          bordered={false}
          defaultActiveKey={"faq-0"}
          accordion
          expandIcon={({ isActive }) =>
            isActive ? <MinusOutlined /> : <PlusOutlined />
          }
        >
          {faqs?.faqs?.map((faq, index) => (
            <Collapse.Panel header={faq.question} key={`faq-${index}`}>
              <div
                dangerouslySetInnerHTML={{
                  __html: faq.answer ?? "",
                }}
              />
            </Collapse.Panel>
          ))}
        </Collapse>
      </div>
    </Fragment>
  );
};
//sgvs
