import { Button, Col, Row } from "antd";
import moment from "moment";
import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import BackgroundImage from "../../../../assets/images/kyc-page-1.png";
import KYCService from "../../../../services/KYCService/kyc.service";
import AuthContainer from "../../../../store/container/AuthContainer";
import OnBoardingContainer from "../../../../store/container/OnBoardingContainer";
import { AuthReducerProps } from "../../../../store/reducers/authReducer";
import { OnBoardingReducerProps } from "../../../../store/reducers/onBoardingReducer";
import { Spin } from "antd";
import "./customerDetails.scss";
import { Form, Formik, FormikProps } from "formik";
import { Customer } from "../../../../models/Customer/customer.model";
import DatePicker from "../../../../shared/components/DatePicker";
import AlertModal from "../../../../shared/components/AlertModal";
import OnBoarding from "../../../../shared/components/Layout/OnBoarding";
import Timeline from "../../../../shared/components/Timeline";
import InputField from "../../../../shared/components/InputField";
import { ManualFormValidationSchema } from "./manualForm.validation";
import {
  ALERT_OKAY_TEXT,
  FORM_FIELD_LABELS,
  FORM_PLACEHOLDERS,
  MADE_IN_KENYA,
} from "./customerDetails.constants";
import { formatDate } from "../../../../shared/utils/formatDate";

interface CustomerDetailsProps
  extends OnBoardingReducerProps,
    AuthReducerProps {
  openReviewForm: (view: boolean) => void;
  openWrongDetails: (view: boolean) => void;
  alreadyChanged: boolean;
  detailsChanged: boolean;
}

const CustomerDetails: FC<CustomerDetailsProps> = (props, ref) => {
  const {
    setCurrentImage,
    setCurrentText,
    setKycStep,
    openReviewForm,
    alreadyChanged,
    detailsChanged,
    openWrongDetails,
  } = props;

  const {
    customer,
    loading,
    getCustomerDetails,
    updateCustomerDetails,
    updateCustomerName,
    btnLoading,
  } = KYCService();

  const formikRef = useRef<FormikProps<Customer>>(null);
  const [showManualForm, setShowManualForm] = useState(false);
  const [errorFetchingDetails, setErrorFetchingDetails] = useState(false);

  useEffect(() => {
    if (!detailsChanged) {
      formikRef.current?.resetForm();
      getCustomerDetails();
    }
  }, [detailsChanged]);

  useEffect(() => {
    setCurrentImage(BackgroundImage);
    setCurrentText(MADE_IN_KENYA);

    return () => {
      setCurrentText("");
    };
  }, []);

  useEffect(() => {
    if (customer && customer?.attemptsRemaining === 0) {
      handleNextPage();
    }
    if (customer && customer.isFromIprs === 0) {
      setErrorFetchingDetails(true);
    }
  }, [customer]);

  const handleNextPage = async (values?: Customer) => {
    if (
      customer?.isFromIprs &&
      (!customer.dateOfIssue || !customer.dateOfBirth)
    ) {
      if (values?.dateOfBirth && values.dateOfIssue) {
        const response = values && (await updateCustomerDetails(values));
        response && setKycStep(1);
      }
    } else setKycStep(1);
  };

  const handleModalOk = () => {
    setErrorFetchingDetails(false);
    setShowManualForm(true);
  };

  const manualFormOnSubmit = async (values: Customer) => {
    const response = await updateCustomerName(values);
    response && setKycStep(1);
  };

  const manualFormInitialValues = {
    firstName: undefined,
    lastName: undefined,
    kenyanNationalNumber: customer?.kenyanNationalNumber,
  };

  const reviewFormInititialValues = Object.assign(new Customer(), customer);

  const renderContent = () => {
    if (loading) return null;

    if (errorFetchingDetails) {
      return (
        <AlertModal
          visible={errorFetchingDetails}
          okText={ALERT_OKAY_TEXT}
          title="Unable to fetch your details"
          onOk={handleModalOk}
          onCancel={() => {}}
          closable={false}
        >
          <div>
            {` Oops! We encountered an error while fetching your details. Worry
            not! Kindly click here to manually enter your full name to continue`}
          </div>
        </AlertModal>
      );
    }

    return (
      <OnBoarding>
        <div className="kyc__wrapper">
          <Timeline />
          <div className="customer-details">
            {showManualForm ? (
              <Formik
                initialValues={manualFormInitialValues}
                onSubmit={manualFormOnSubmit}
                validationSchema={ManualFormValidationSchema}
                enableReinitialize
              >
                {() => {
                  return (
                    <Form>
                      <h1 className="text-primary text-center">
                        Profile details
                      </h1>
                      <InputField
                        disabled
                        name="kenyanNationalNumber"
                        type="text"
                        title={FORM_FIELD_LABELS.KENYAN_ID}
                      />
                      <InputField
                        placeholder={FORM_PLACEHOLDERS.DEFAULT}
                        name="firstName"
                        title={FORM_FIELD_LABELS.FIRST_NAME}
                        type="text"
                      />
                      <InputField
                        placeholder={FORM_PLACEHOLDERS.DEFAULT}
                        name="lastName"
                        title={FORM_FIELD_LABELS.LAST_NAME}
                        type="text"
                      />

                      <div className="submit-btn">
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={btnLoading}
                        >
                          Next
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <Formik
                initialValues={reviewFormInititialValues}
                onSubmit={handleNextPage}
                innerRef={formikRef}
                enableReinitialize
              >
                {({ values, setFieldValue }) => {
                  return (
                    <Form>
                      <h1 className="text-primary text-center">
                        Review your details
                      </h1>

                      <Row className="details__wrapper" gutter={16}>
                        <Col span={24}>
                          <h1>
                            {customer?.firstName?.toLowerCase()}{" "}
                            {customer?.lastName?.toLowerCase()}{" "}
                            {customer?.middleName?.toLowerCase()}{" "}
                          </h1>{" "}
                        </Col>
                        <Col span={12}>
                          <label htmlFor="">ID Number</label>
                          <p>{customer?.kenyanNationalNumber}</p>
                        </Col>
                        <Col span={12}>
                          <label htmlFor="gender">Gender</label>
                          <p>{customer?.gender}</p>
                        </Col>
                        <Col span={12}>
                          <label htmlFor="dateOfBirth">DOB</label>
                          {customer?.isFromIprs && !customer?.dateOfBirth ? (
                            <DatePicker
                              disableFuture
                              name={"dateOfBirth"}
                              value={values.dateOfBirth}
                              placeholder={FORM_PLACEHOLDERS.DOB}
                              setFieldValue={(name, val) =>
                                setFieldValue(name, val)
                              }
                            />
                          ) : (
                            <p className="mt-2">
                              {customer?.dateOfBirth &&
                                moment(customer?.dateOfBirth).format(
                                  "DD MMM YYYY"
                                )}
                            </p>
                          )}
                        </Col>
                        <Col span={12}>
                          <label htmlFor="">District of Birth</label>
                          <p className="mt-2">{customer?.districtOfBirth}</p>
                        </Col>
                        <Col span={12}>
                          <label htmlFor="placeOfIssue">Place of issue</label>
                          <p className="mt-2">
                            {customer?.placeOfIssue || "-"}
                          </p>
                        </Col>
                        <Col span={12}>
                          <label htmlFor="dateOfIssue">Date of Issue</label>
                          {customer?.isFromIprs && !customer?.dateOfIssue ? (
                            <DatePicker
                              disableFuture
                              name={"dateOfIssue"}
                              value={values.dateOfIssue}
                              placeholder={FORM_PLACEHOLDERS.DATE_ISSUE}
                              setFieldValue={(name, val) =>
                                setFieldValue(name, val)
                              }
                            />
                          ) : (
                            <p className="mt-2">
                              {customer?.dateOfIssue &&
                                formatDate(customer.dateOfIssue)}
                            </p>
                          )}
                        </Col>
                        <Col span={12}>
                          <label htmlFor="">District</label>
                          <p>{customer?.district}</p>
                        </Col>{" "}
                        <Col span={12}>
                          <label htmlFor="">Division</label>
                          <p>{customer?.division}</p>
                        </Col>
                        <Col span={12}>
                          <label htmlFor="">Location</label>
                          <p>{customer?.location}</p>
                        </Col>
                        <Col span={12}>
                          <label htmlFor="">Sub-location</label>
                          <p>{customer?.subLocation}</p>
                        </Col>
                      </Row>
                      <div className="submit-btn">
                        <Button
                          type="primary"
                          htmlType="submit"
                          loading={btnLoading}
                        >
                          Confirm Details
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            )}
            {!alreadyChanged && (
              <p className="text-primary link">
                Wrong ID?{" "}
                <span
                  className="text-link cursor-pointer"
                  onClick={() => {
                    openReviewForm(true);
                  }}
                >
                  Change here
                </span>
              </p>
            )}
            <p className="text-primary link">
              Incorrect Details?{" "}
              <span
                className="text-link cursor-pointer"
                onClick={() => {
                  openWrongDetails(true);
                }}
              >
                Click here
              </span>
            </p>
          </div>
        </div>
      </OnBoarding>
    );
  };

  return renderContent();
};

export default AuthContainer(OnBoardingContainer(CustomerDetails));
