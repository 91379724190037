import * as yup from "yup";
import { REGEX } from "../../../shared/utils/regex";

export const otpValidationSchema = yup.object().shape({
    emailOTP: yup
        .string()
        .required("Email OTP is required")
        .min(6, "Email OTP has to be longer than 6 characters!")
        .max(6, "Email OTP has to be less than 6 characters!"),
    mobileOTP: yup
        .string()
        .required("Mobile OTP is required")
        .min(6, "Mobile OTP has to be longer than 6 characters!")
        .max(6, "Mobile OTP has to be less than 6 characters!"),
});
