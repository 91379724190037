import { DoubleLeftOutlined } from "@ant-design/icons";
import { Button, Tabs, Tooltip, Row, Col } from "antd";
import React, { Fragment, useState } from "react";
import PageHeader from "../../../shared/components/PageHeader";
import WalletBalance from "../../../shared/components/WalletBalance";
import PathWhite from "./../../assets/icons/path-white.svg";
import InvestmentIcon from "./../../assets/icons/multi color icons - svg/total investment.svg";
import ExpandIcon from "./../../assets/icons/multi color icons - svg/expand arrow.svg";
import "./holdings.scss";
import TableComponent from "../../../shared/components/TableComponent";
import ShareService from "../../../services/SharesService/share.service";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import AuthService from "../../../services/AuthService/auth.service";
import { numberConvention } from "../../../shared/utils/numberConvention";
import { AuthState } from "../../../store/reducers/authReducer";
import AuthContainer from "../../../store/container/AuthContainer";
import { User } from "../../../models/User/user.model";
import RestrictedAccess from "../../../shared/components/RestrictedAccess";
import Loader from "../../../shared/components/Loader";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import DynamicText from "../../../shared/components/DynamicText";
import EnableDisableSwitch from "./EnableDisableSwitch";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const sellColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Units",
    dataIndex: "shares",
    key: "shares",
  },
  {
    title: "Single Unit Value",
    dataIndex: "unitPrice",
    key: "unitPrice",
    render: (text: string) => <span className="currency-value">{text}</span>,
  },
  {
    title: "Sold Value",
    dataIndex: "totalValue",
    key: "totalValue",
    render: (text: string) => <span className="currency-value">{text}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => <span className={text}>{text}</span>,
  },
];

const buyColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Units",
    dataIndex: "shares",
    key: "shares",
  },
  {
    title: "Single Unit Value",
    dataIndex: "unitPrice",
    key: "unitPrice",
    render: (text: string) => <span className="currency-value">{text}</span>,
  },
  {
    title: "Purchased Value",
    dataIndex: "totalValue",
    key: "totalValue",
    render: (text: string) => <span className="currency-value">{text}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: string) => <span className={text}>{text}</span>,
  },
];

interface HoldingsProps extends AuthState { }

const Holdings = (props: HoldingsProps) => {
  const screens  = useBreakpoint();
  const isMobile = screens.xs;
  const { TabPane } = Tabs;
  const history = useHistory();
  const { user } = props;

  const [buyMode, setBuyMode] = useState<boolean>();

  const {
    getBuySharesHistory,
    getSellSharesHistory,
    getCurrentValuation,
    sharesHistory,
    loading,
    valuation,
  } = ShareService();
  const { getWalletDetails, getUserDetails, wallet } = AuthService();

  useEffect(() => {
    // if (!user?.subscription && !user.lastSubscription) {
    //   history.push(AppRoutes.HOME);
    // }
    const state = history.location.state as { buyMode: boolean | undefined };
    setBuyMode(state?.buyMode !== undefined ? state?.buyMode : true);
    getWalletDetails();
    getCurrentValuation();
  }, []);

  useEffect(() => {
    if (buyMode === undefined) {
      return;
    }
    if (buyMode) {
      getBuySharesHistory();
    } else {
      getSellSharesHistory();
    }
  }, [buyMode]);

  const ordersTabs = (
    <Tabs
      activeKey={buyMode ? "1" : "2"}
      onChange={(key: string) => setBuyMode(key === "1")}
    >
      <TabPane tab="Buy Orders" key="1">
        <>
          <div className="holdings__auto-dividend--container">
            <h3 className="holdings__auto-dividend--heading">
              Auto Invest Dividend
            </h3>
            <EnableDisableSwitch
              phone={user?.mobileNumber}
              switchStatus={user?.autoDividendInvestOption === 1 ? true : false}
            />
          </div>
        </>
        <TableComponent
          loading={loading}
          className="holdings-table"
          columns={buyColumns}
          dataSource={sharesHistory}
        />
      </TabPane>
      <TabPane tab="Sell Orders" key="2">
        <TableComponent
          loading={loading}
          className="holdings-table"
          columns={sellColumns}
          dataSource={sharesHistory}
        />
      </TabPane>
    </Tabs>
  );

  return user?.kycAccepted === undefined ? (
    <Loader />
  ) : user?.kycAccepted ? (
    <Fragment>
      <PageHeader title="Holdings" showWallet={true} />
      <div className="holdings__container">
        <Row className="holdings">
          <Col className="holdings-data" xs={12} sm={12} md={4} lg={4} xl={4}>
            <DynamicText
              text="Total Investment"
              className="holdings-data--header-title"
              width="100%"
            />
            <DynamicText
              text={`${numberConvention(valuation?.totalInvestment || 0)} KES`}
              className="holdings-data--header-value"
              width="100%"
              fontSize={isMobile ? 16: 22}
            />
          </Col>
          <Col className="holdings-data" xs={12} sm={12} md={4} lg={4} xl={4}>
            <DynamicText
              text="Total Units Held"
              className="holdings-data--header-title"
              width="100%"
            />
            <DynamicText
              text={`${(valuation?.holdings || 0).toLocaleString()}`}
              className="holdings-data--header-value"
              width="100%"
              fontSize={isMobile ? 16: 22}
            />
          </Col>
          <Col className="holdings-data" xs={12} sm={12} md={4} lg={4} xl={4}>
            <DynamicText
              text="Dividends Earned"
              className="holdings-data--header-title"
              width="100%"
            />
            <DynamicText
              text={`${numberConvention(valuation?.dividendsEarned)} KES`}
              className="holdings-data--header-value"
              width="100%"
              fontSize={isMobile ? 16: 22}
            />
          </Col>
          <Col className="holdings-data" xs={12} sm={12} md={4} lg={4} xl={4}>
            <DynamicText
              text="Capital Appreciation"
              className="holdings-data--header-title"
              width="100%"
            />
            <DynamicText
              text={`${numberConvention(valuation?.capitalAppreciation)} KES`}
              className="holdings-data--header-value"
              width="100%"
              fontSize={isMobile ? 16: 22}
            />
          </Col>
          <Col className="holdings-data" xs={12} sm={12} md={4} lg={4} xl={4}>
            <DynamicText
              text="Current Valuation"
              className="holdings-data--header-title"
              width="100%"
            />
            <DynamicText
              text={`${numberConvention(valuation?.currentValuation || 0)} KES`}
              className="holdings-data--header-value"
              width="100%"
              fontSize={isMobile ? 16: 22}
            />
          </Col>
        </Row>
        
        <div className="holdings__data">{ordersTabs}</div>
        
      </div>
    </Fragment>
  ) : (
    <RestrictedAccess getUserDetails={getUserDetails} user={user} />
  );
};

export default AuthContainer(Holdings);
