import React, { FC, SyntheticEvent } from "react";
import { Field, ErrorMessage } from "formik";
import { Input } from "antd";
import Error from "../Error";
import "./inputField.scss";

interface InputFieldProps {
	title?: string;
	type: string;
	name: string;
	placeholder?: string;
	disabled?: boolean;
	addonAfter?: any;
	addonBefore?: any;
	disablepaste?: boolean;
	className?: string
	onChange?: (event: any) => void
}

const InputField: FC<InputFieldProps> = (props) => {
	const { name, title, disablepaste, className, onChange } = props;
	const properties: any = { ...props };
	if (disablepaste) {
		properties.disablepaste = properties.disablepaste.toString()
		properties.onPaste = () => false;
		properties.onDrop = () => false;
	}

	return (
		<div
			className={`input-field__wrapper mb-4 ${className} ${props.addonBefore && "no-border-right"
				}`}
		>
			<label htmlFor={name}>{title}</label>
			<Field
				as={Input}
				{...properties}
				{...onChange ? { ...onChange } : null}
				onPaste={() => false}
				style={{ marginTop: "0.5rem" }}
			/>
			<ErrorMessage name={name}>
				{(message: string) => <Error message={message} />}
			</ErrorMessage>
		</div>
	);
};

export default InputField;
