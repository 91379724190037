import React from "react";

interface ShareListProps {
  items: number[];
  loading?: boolean;
  onSelect: (value: number) => void;
  type: "amount" | "shares";
}

interface ShareItemProps {
  value: number;
  loading?: boolean;
  onClick: (value: number) => void;
}

const ShareItem: React.FC<ShareItemProps> = ({ value, loading, onClick }) => {
  const shareItemClass = loading ? "share-item disabled" : "share-item";

  return (
    <p className={shareItemClass} onClick={() => onClick(value)}>
      {value || 0}
    </p>
  );
};

const ShareList: React.FC<ShareListProps> = ({
  items,
  loading = false,
  onSelect,
  type,
}) => {
  if (!items?.length) return null;

  return (
    <div className="share-list">
      {items.map((item) => (
        <ShareItem
          key={item}
          value={item}
          loading={loading}
          onClick={onSelect}
        />
      ))}
    </div>
  );
};

export default ShareList;
