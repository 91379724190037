import React, { FC, useEffect, useState, CSSProperties } from "react";
import Layer1 from "../../../../assets/images/layer1.svg";
import Layer2 from "../../../../assets/images/layer2.svg";
import Logo from "../../../../assets/icons/logo - svg/vuka-logo-light.svg";
import { Col, Row } from "antd";
import OnBoardingContainer from "../../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../../store/reducers/onBoardingReducer";
import "./onBoarding.scss";

interface OnBoardingProps extends OnBoardingReducerProps {
  styles?: CSSProperties;
}

const OnBoarding: FC<OnBoardingProps> = (props) => {
  const { children, currentImage, currentText, styles } = props;
  const [imageUrl, setImageUrl] = useState("");
  const [makeAnimation, setMakeAnimation] = useState(false);

  useEffect(() => {
    if (currentImage) {
      if (makeAnimation) {
        setMakeAnimation(false);
        setTimeout(() => {
          setImageUrl(currentImage);
          setMakeAnimation(true);
        }, 500);
      } else {
        setImageUrl(currentImage);
        setMakeAnimation(true);
      }
    }
  }, [currentImage]);

  return (
    <div className="on-boarding">
      <Row>
        <Col
          xs={0}
          sm={0}
          md={0}
          lg={12}
          className={`layer-image__wrapper ${
            makeAnimation ? "move-left-to-right" : "move-right-to-left"
          } `}
        >
          <img src={imageUrl} alt="" className="bg-image" />
          <img src={Layer1} alt="" className="layer outer-layer" />
          <img src={Layer2} alt="" className="layer" />

          <div className="logo">
            <img src={Logo} alt="" />
            <p>{currentText}</p>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          style={styles}
          className={`on-boarding__content no-scroll ${
            makeAnimation ? "fade-in" : "fade-out"
          }`}
        >
          {children}
        </Col>
      </Row>
    </div>
  );
};

export default OnBoardingContainer(OnBoarding);
