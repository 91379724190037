import { Button } from "antd";
import { Formik, Form, FormikValues } from "formik";
import React, { useEffect } from "react";
import InputField from "../../../shared/components/InputField";
import OnBoarding from "../../../shared/components/Layout/OnBoarding";
import TermsAndPolicy from "../../../shared/components/TermsAndPolicy";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/login.png";
import { useHistory, withRouter } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import "./resetPassword.scss"
import * as Yup from 'yup';
import { REGEX } from "../../../shared/utils/regex";
import AuthService from "../../../services/AuthService/auth.service";

const validationSchema = Yup.object({
    password: Yup.string()
        .min(6, 'Password has to be longer than 6 characters!')
        .required('Password is required!')
        .matches(REGEX.PASSWORD, "One Uppercase, One Lowercase, One Number and one special case Character"),
    confirmPassword: Yup.string().required('Confirm password is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
});

interface ResetPasswordProps extends OnBoardingReducerProps { }

const ResetPassword = (props: ResetPasswordProps) => {

    const { setCurrentImage, setCurrentText } = props;

    const history = useHistory();
    const {
        loading,
        resetPassword,
    } = AuthService()

    useEffect(() => {
        setCurrentImage(BackgroundImage);
        setCurrentText("Your First Step to Financial Freedom");
        return () => {
            setCurrentText("");
        };
    }, []);

    const submitHandler = async (data: FormikValues) => {
        const url = new URLSearchParams(history.location.search);
        const dynamicUrl = url.get("url");
        try {
            await resetPassword(dynamicUrl || '', data.password)
            history.push(AppRoutes.LOGIN)
        } catch (error) {

        }
    }

    return <div className="reset-password">
        <OnBoarding>
            <Formik
                initialValues={{
                    password: "",
                    confirmPassword: ""
                }}
                onSubmit={submitHandler}
                validationSchema={validationSchema}
            >
                {({ values, setFieldValue, isValid, dirty, setFieldError, resetForm }) => (<Form>
                    <h1 className="text-primary text-center">
                        Reset Password
                    </h1>
                    <InputField
                        title="New Password"
                        type="password"
                        name="password"
                        placeholder="Enter Password"
                    />
                    <InputField
                        title="Confirm New Password"
                        type="password"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                    />
                    <div className="submit-btn mt-3">
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="middle"
                            disabled={!isValid || !dirty}
                            loading={false}
                        >
                            &nbsp; Reset Password
                        </Button>
                    </div>
                    <TermsAndPolicy />
                </Form>

                )}
            </Formik>
        </OnBoarding>
    </div>

}

export default OnBoardingContainer(ResetPassword)