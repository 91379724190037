import { EmergencyContact, BeneficiaryDetail } from './../../models/Customer/customer.model';
import { Wallet, WalletHistory } from "./../../models/Wallet/wallet.model";
import axiosInstance from "../../interceptor/axiosInstance";
import { serialize, deserialize } from "serializr";
import { User } from "../../models/User/user.model";
import { store } from "../../store";
import {
    AUTHENTICATED,
    REQUEST_LOGOUT,
} from "../../store/definitions/authConstants";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { useState } from "react";
import { useHistory } from "react-router";
import { HOME } from "../../routes/routeConstants/appRoutes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { OTP } from "../../models/OTP/otp.model";
import LocalStorage from "../../shared/LocalStorage";
import { OTP_TYPES } from "../../enums/otpTypes";

const AuthService = () => {
    const history = useHistory();

    const [user, setUser] = useState<User>();
    const [wallet, setWallet] = useState<Wallet>();
    const [beneficiaryDetails, setBeneficiaryDetail] = useState<BeneficiaryDetail>();
    const [emergencyContact, setEmergencyContact] = useState<EmergencyContact>();
    const [walletHistory, setWalletHistory] = useState<WalletHistory[]>([]);

    const [error, setError] = useState<Error>();

    const [loading, setLoading] = useState(false);
    const [walletLoading, setWalletLoading] = useState(false);
    const [walletHistroyLoading, setWalletHistoryLoading] = useState(false);

    const loginUser = (data: User) => {
        setLoading(true);
        return axiosInstance
            .post(ApiRoutes.USER_LOGIN, data)
            .then((response) => {
                LocalStorage.setItem(
                    "vuka-token",
                    response.data["access_token"]
                );
                getUserDetails()
            })
            .catch((error) => {
                throw error
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const createAccount = async (user: User) => {
        setLoading(true);
        const userJSON = serialize(user);
        try {
            const response = await axiosInstance.post(
                ApiRoutes.CREATE_ACCOUNT,
                userJSON
            );
            if (response.data.success) {
                const user = deserialize(User, response.data.user);
                setUser(user);
                return user;
            }
            Notification({
                message: "",
                description: response.data?.message,
                type: NotificationTypes.ERROR,
            });
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const OTPVerification = async (otp: OTP) => {
        setLoading(true);
        try {
            const otpJSON = {
                ...serialize(otp),
            };
            const response = await axiosInstance.post(
                ApiRoutes.OTP_VERIFICATION,
                otpJSON
            );
            if (response.data.success) {
                LocalStorage.setItem(
                    "vuka-token",
                    response.data["access_token"]
                );
                return true;
            }
            Notification({
                message: "",
                description: response.data?.message,
                type: NotificationTypes.ERROR,
            });
            return false;
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const resendOTP = async (data: { type: OTP_TYPES; email: string }) => {
        const URL = ApiRoutes.RESEND_OTP;
        try {
            const response = await axiosInstance.put(URL, data);
            Notification({
                message: "OTP",
                description: response.data?.message,
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) { }
    };

    const logoutUser = async () => {
        try {
            const response = await axiosInstance.put(ApiRoutes.USER_LOGOUT);
            store.dispatch({
                type: REQUEST_LOGOUT,
                payload: "Request for logout",
            });
            LocalStorage.clearSenstive();
            Notification({
                message: "",
                description: response.data?.message,
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
        } finally {
        }
    };

    const getUserDetails = async (checkTokenExists = false, onError = () => { }) => {
        try {
            if (checkTokenExists && !LocalStorage.getItem("vuka-token")) return false;
            const response = await axiosInstance.get(ApiRoutes.GET_USER);
            if (response.data?.success) {
                const user = deserialize(User, response.data.user);
                store.dispatch({
                    type: AUTHENTICATED,
                    payload: {
                        authenticated: true,
                        user: user,
                    },
                });
                setUser(user);

                return user;
            }
            Notification({
                message: "",
                description: response.data?.message,
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
            onError();
        } finally {
        }
    };

    const getWalletDetails = async () => {
        setWalletLoading(true);
        try {
            const response = await axiosInstance.get(ApiRoutes.GET_USER_WALLET);
            if (response.data?.success) {
                const data = deserialize(Wallet, response.data["wallets"]);
                setWallet(data);
                return;
            }
            Notification({
                message: "",
                description: response.data?.message,
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
        } finally {
            setWalletLoading(false);
        }
    };

    const getWalletHistory = async () => {
        setWalletHistoryLoading(true);
        try {
            const response = await axiosInstance.get(
                ApiRoutes.GET_USER_WALLET_HISTORY
            );
            if (response.data?.success) {
                const data = deserialize(
                    WalletHistory,
                    response.data["wallet_history"]
                ) as WalletHistory[];
                setWalletHistory(data);
                return;
            }
            Notification({
                message: "",
                description: response.data?.message,
                type: NotificationTypes.SUCCESS,
            });
        } catch (error) {
        } finally {
            setWalletHistoryLoading(false);
        }
    };

    const getHeaderChips = () => {
        try {

        } catch (error) {

        } finally {

        }
    }

    const retrySubscription = () => {
        try {

        } catch (error) {

        } finally {

        }
    }

    const fetchEmergencyContact = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get(ApiRoutes.EMERGENCY_CONTACT);
            const data = deserialize(EmergencyContact, response.data["emergency_contact"])
            setEmergencyContact(data)
        } catch (error) {
            throw error
        } finally {
            setLoading(false);
        }
    }

    const updateEmergencyContact = async (values: EmergencyContact) => {
        setLoading(true);
        try {
            const serialisedData = serialize(EmergencyContact, values)
            const response = await axiosInstance.put(
                ApiRoutes.EMERGENCY_CONTACT,
                serialisedData
            );
            const data = deserialize(EmergencyContact, response.data["emergency_contact"])
            setEmergencyContact(data)
            if (response.data?.success) {
                Notification({
                    message: "",
                    description: response.data?.message,
                    type: NotificationTypes.SUCCESS,
                });
            }
        } catch (error) {
            throw error
        } finally {
            setLoading(false);
        }
    }

    const fetchBeneficiaryDetails = async () => {
        setLoading(true);
        try {
            const { data } = await axiosInstance.get(ApiRoutes.BENEFICIARY_DETAILS);
            const beneficiaryDetail = deserialize(BeneficiaryDetail, data['beneficiary_detail'])
            setBeneficiaryDetail(beneficiaryDetail)
        } catch (error) {
        } finally {
            setLoading(false);
        }
    }

    const updateBeneficiaryDetails = async (values: BeneficiaryDetail) => {
        setLoading(true);
        try {
            const serialisedData = serialize(BeneficiaryDetail, values)
            const response = await axiosInstance.put(
                ApiRoutes.BENEFICIARY_DETAILS,
                serialisedData
            );
            const data = deserialize(BeneficiaryDetail, response.data["beneficiary_detail"])
            setBeneficiaryDetail(data)
            if (response.data?.success) {
                Notification({
                    message: "",
                    description: response.data?.message,
                    type: NotificationTypes.SUCCESS,
                });
            }
        } catch (error) {
            throw error
        } finally {
            setLoading(false);
        }
    }

    const requestResetPasswordLink = async (email: string, disableNotification?: boolean) => {
        setLoading(true)
        try {
            const data = { email }
            const response = await axiosInstance.put(ApiRoutes.FORGOT_PASSWORD, data)
            if (response.data.success && !disableNotification) {
                Notification({
                    message: "",
                    description: response.data?.message,
                    type: NotificationTypes.SUCCESS,
                });
            }
            return true
        } catch (error) {
            throw error
        } finally {
            setLoading(false)
        }
    }

    const resetPassword = async (url: string, password: string) => {
        setLoading(true)
        if (!url) {
            return Notification({
                message: "",
                description: "Please use the link sent to your mail id",
                type: NotificationTypes.ERROR,
            });
        }
        try {
            const response = await axiosInstance.put(url, { password })
            if (response.data.success) {
                Notification({
                    message: "",
                    description: response.data?.message,
                    type: NotificationTypes.SUCCESS,
                });
            }
        } catch (error) {
            throw error
        } finally {
            setLoading(false)
        }
    }

    return {
        user,
        error,
        wallet,
        loading,
        loginUser,
        beneficiaryDetails,
        emergencyContact,
        createAccount,
        walletLoading,
        OTPVerification,
        getUserDetails,
        resendOTP,
        resetPassword,
        requestResetPasswordLink,
        fetchEmergencyContact,
        fetchBeneficiaryDetails,
        getWalletDetails,
        walletHistory,
        logoutUser,
        walletHistroyLoading,
        updateEmergencyContact,
        updateBeneficiaryDetails,
        getWalletHistory,
    };
};

export default AuthService;
