import React, { FC, Fragment, useState } from "react";
import PageHeader from "../../../shared/components/PageHeader";
import WalletBalance from "../../../shared/components/WalletBalance";
import { Tabs, Collapse, Space, Spin, TablePaginationConfig, Col, Row } from "antd";
import icon from "./../../../assets/icons/refer.svg";
import BuyIcon from "./../../../assets/icons/multi color icons - svg/share bought.svg";
import SoldIcon from "./../../../assets/icons/multi color icons - svg/share sold.svg";
import WithdrawIcon from "./../../../assets/icons/multi color icons - svg/money withdrawn.svg";
import AddIcon from "./../../../assets/icons/multi color icons - svg/money added.svg";
import "./wallet.scss";
import AuthService from "../../../services/AuthService/auth.service";
import { useEffect } from "react";
import { numberConvention } from "../../../shared/utils/numberConvention";
import { WalletHistoryTypes } from "../../../enums/walletHistoryTypes";
import { WalletHistory } from "../../../models/Wallet/wallet.model";
import { AuthState } from "../../../store/reducers/authReducer";
import RestrictedAccess from "../../../shared/components/RestrictedAccess";
import AuthContainer from "../../../store/container/AuthContainer";
import Loader from "../../../shared/components/Loader";
import { useHistory } from "react-router";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import ShareService from "../../../services/SharesService/share.service";
import TableComponent from "../../../shared/components/TableComponent";
import moment from "moment";
import { AlignType } from 'rc-table/lib/interface';
import { WithdrawalModesObject } from "../../../enums/withdrawalModes";

const columns = [
  {
    title: "Date",
    key: "date",
    dataIndex: "date",
    width: 200,
    render: (text: string) => text && moment(text).format("DD, MMM YYYY hh:mm A")
  },
  {
    title: "Amount Requested",
    key: "amountRequested",
    dataIndex: "amountRequested",
    width: 200,
    className: "amount-column",
    align: 'right' as AlignType,
    render: (text: string) => numberConvention(text)
  },
  {
    title: "Withdraw To",
    key: "withdrawTo",
    dataIndex: "withdrawTo",
    width: 150,
    render: (text: string) => <span>{text && WithdrawalModesObject[text]}</span>
  },
  {
    title: "Account Number",
    key: "accountNumber",
    dataIndex: "accountNumber",
    width: 200,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    width: 100,
    render: (text: number) => <span className={`text-capitalize ${text}`}>
      {text}
    </span>,
  },
]

interface WalletProps extends AuthState { }

const Wallet: FC<WalletProps> = (props) => {
  const history = useHistory();
  const { Panel } = Collapse;

  const { user } = props;

  const [selectedTab, setSelectedTab] = useState("1")

  const {
    getWalletDetails,
    getWalletHistory,
    walletHistory,
    walletHistroyLoading,
    wallet,
    getUserDetails,
    walletLoading,
  } = AuthService();

  const {
    loading,
    withdrawList,
    withdrawPagination,
    fetchWithdrawRequests,
  } = ShareService()

  useEffect(() => {
    // if (!user?.subscription) {
    //   return history.push(AppRoutes.HOME);
    // }
    getWalletDetails();
  }, []);

  useEffect(() => {
    if (selectedTab === "1") {
      getWalletHistory();
    } else {
      fetchWithdrawRequests()
    }
  }, [selectedTab])

  const { TabPane } = Tabs;

  const historyIconMapper = ({ type, details }: WalletHistory) => {
    if (WalletHistoryTypes.DEPOSIT === type) {
      return AddIcon;
    }
    if (WalletHistoryTypes.SELF === type && !!details) {
      return WithdrawIcon;
    }
    return BuyIcon;
  };

  const walletHistoryTabsTemplate = (
    <Tabs defaultActiveKey="1" onChange={setSelectedTab}>
      <TabPane tab="Wallet History" key="1"></TabPane>
      <TabPane tab="Withdraw Request" key="2"></TabPane>
    </Tabs>
  );

  const handleWithdrawTableChange = (pagination: TablePaginationConfig) => {
    fetchWithdrawRequests({ page: pagination.current || 1 })

  }

  return user?.kycAccepted === undefined ? (
    <Loader />
  ) : user?.kycAccepted ? (
    <Fragment>
      <PageHeader title="Wallet" showWallet={true} />
      <div className="wallet--container">
        <WalletBalance wallet={wallet} loading={walletLoading}
          fetchWallet={() => {
            getWalletDetails()
            fetchWithdrawRequests()
          }} />
        <div className="history__container">
          {walletHistoryTabsTemplate}
          {(walletHistroyLoading || loading) ? (
            <div className="loading">
              <Space size="middle" align="center" direction="vertical">
                <Spin size="large" />
              </Space>
            </div>
          ) : selectedTab === "1"
            ? walletHistory.length
              ? <Collapse accordion={true}>
                {walletHistory.map((history) => (
                  <Panel
                    // collapsible={history.details ? "header" : "disabled"}
                    showArrow={false}
                    header={
                      <div className="wallet-history--item">
                        <div className="content">
                          <div className="content-icon">
                            <img
                              src={historyIconMapper(history)}
                              alt={history.type}
                            />
                          </div>
                          <div className="content-text">
                            <p className="content-text--title">
                              <span>{history.title}</span>
                              <span className="content-text--expand">
                                {" "}
                                details
                              </span>
                            </p>
                            <p className="content-text--subtitle">
                              {history.transactionDate && moment(history.transactionDate).format("DD, MMM YYYY hh:mm A")}
                            </p>
                          </div>
                        </div>
                        <div
                          className={
                            "actions " +
                            (history.forDynamicWallet ? "success" : "danger")
                          }
                        >
                          {numberConvention(history.amount)}
                        </div>
                      </div>
                    }
                    key={history?.createdAt || ""}
                  >
                    {history.paymentDetails ? (
                      <Row className="footer_transaction">
                        <Col span={24} mb-5>
                          <Row>
                            {Object.entries(history.paymentDetails).map(
                              ([key, value]) => (
                                <Col span={24} mb-5>
                                  <label htmlFor="">{key}</label>
                                  <p>{value}</p>
                                </Col>
                              )
                            )}
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <div className="panel-content">
                        <div className="panel-content--details">
                          <p className="panel-content--details--title">Units</p>
                          <p className="panel-content--details--value">
                            {history.details?.shareQuantity}
                          </p>
                        </div>
                        <div className="panel-content--details">
                          <p className="panel-content--details--title">
                            Single share value
                          </p>
                          <p className="panel-content--details--value">
                            {numberConvention(history.details?.unitPrice)} KES
                          </p>
                        </div>
                        <div className="panel-content--details">
                          <p className="panel-content--details--title">
                            Sold Value
                          </p>
                          <p className="panel-content--details--value">
                            {numberConvention(history.details?.total)} KES
                          </p>
                        </div>
                        <div className="panel-content--details">
                          <p className="panel-content--details--title">
                            Status
                          </p>
                          <p className="panel-content--details--value">
                            {history.details?.status}
                          </p>
                        </div>
                      </div>
                    )}
                    {/* <div className="panel-content">
                      <div className="panel-content--details">
                        <p className="panel-content--details--title">Units</p>
                        <p className="panel-content--details--value">
                          {history.details?.shareQuantity}
                        </p>
                      </div>
                      <div className="panel-content--details">
                        <p className="panel-content--details--title">
                          Single share value
                        </p>
                        <p className="panel-content--details--value">
                          {numberConvention(history.details?.unitPrice)} KES
                        </p>
                      </div>
                      <div className="panel-content--details">
                        <p className="panel-content--details--title">
                          Sold Value
                        </p>
                        <p className="panel-content--details--value">
                          {numberConvention(history.details?.total)} KES
                        </p>
                      </div>
                      <div className="panel-content--details">
                        <p className="panel-content--details--title">Status</p>
                        <p className="panel-content--details--value">
                          {history.details?.status}
                        </p>
                      </div>
                    </div> */}
                  </Panel>
                ))}
              </Collapse>
              : <p style={{ textAlign: "center" }}>No records found...</p>
            : <TableComponent
              columns={columns}
              pagination={{
                pageSize: withdrawPagination?.perPage || 0,
                total: withdrawPagination?.total || 0,
                current: withdrawPagination?.currentPage || 0,
                showTotal: (total: number, range: [number, number]) => <p>Showing <b>{` ${range[0]} - ${range[1]}`}</b> of <b>{`${total.toLocaleString()}`}</b></p>
              }}
              onChange={handleWithdrawTableChange}
              dataSource={withdrawList} />
          }
        </div>
      </div>
    </Fragment>
  ) : (
    <RestrictedAccess getUserDetails={getUserDetails} user={user} />
  );
};

export default AuthContainer(Wallet);
