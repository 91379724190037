import React, { FC, useState } from "react";
import * as yup from "yup";
import AlertModal from "../../../shared/components/AlertModal";
import CustomerDetails from "./CustomerDetails";
import "./reviewDetails.scss";
import { WarningFilled } from "@ant-design/icons";
import { Form, Formik, FormikProps } from "formik";
import InputField from "../../../shared/components/InputField";
import { useRef } from "react";
import { CustomerType } from "../../../models/CustomerType/customerType.model";
import KYCService from "../../../services/KYCService/kyc.service";
import { REGEX } from "../../../shared/utils/regex";
import RadioField from "../../../shared/components/RadioField";

interface ReviewDetailsProps { }

const KenyanNationNumberValidationSchema = yup.object().shape({
    kenyanNationalNumber: yup
        .string()
        .required("Kenyan National Number is required!")
        .min(1, "Kenyan National Number must be 8 characters")
        .max(8, "Kenyan National Number must be 8 characters")
        .matches(REGEX.ALPHANUMERIC, "Kenyan National Number should be numeric"),
});

const ReviewDetails: FC<ReviewDetailsProps> = (props) => {
    const { } = props;

    const [changeDetails, setChangeDetails] = useState(false);
    const [wrongDetails, setWrongDetails] = useState(false);
    const [detailsChangedAlready, setDetailsChangedAlready] = useState(false);
    const formikRef =
        useRef<any>(null);
    const { updateNationalNumber, loading } = KYCService();

    const updateChangeDetails = (view: boolean) => setChangeDetails(view);

    const updateWrongDetails = (view: boolean) => {
        setWrongDetails(view);
    };

    const redirectToGovSite = () =>
        window.open("https://www.immigration.go.ke/contact-us/", "_blank");

    const initialValue = new CustomerType()

    const handleConfirm = () => {
        formikRef.current?.submitForm();
    };

    const handleSubmit = async (values: CustomerType) => {
        if (!values.kenyanNationalNumber) {
            return;
        }
        const customerDetails = Object.assign(new CustomerType(), {
            ...values,
        });
        try {
            const customer = await updateNationalNumber(customerDetails);
            if (!!customer) {
                formikRef.current?.handleReset();
                setChangeDetails(false);
                setDetailsChangedAlready(true);

            }
        } catch (error) { }
    };

    const ChangeDetailsTemplate = (
        <div className="change-kenyan-id__container">
            <p>
                {" "}
                <WarningFilled className="warning-icon" /> You have  one
                attempt to rectify your ID number
            </p>
            <Formik
                innerRef={formikRef}
                initialValues={initialValue}
                onSubmit={handleSubmit}
                validationSchema={KenyanNationNumberValidationSchema}
            >
                {({ values, setFieldValue, errors }) => (
                    <Form  >
                        <RadioField
                            name="idType"
                            title="Proof Type"
                            value={values.idType}
                            options={[{ label: 'Kenyan National ID', value: "IDCARD" }, { label: "Alien Card ID", value: "ALIENCARD" }, { label: "Passport ID", value: "PASSPORT" }]}
                            setFieldValue={(field: string, value: boolean) => {
                                setFieldValue('idType', value)
                            }}
                        />
                        <InputField
                            type="text"
                            title="Kenyan National ID / Alien card number / Passport ID"
                            name="kenyanNationalNumber"
                            placeholder="Enter here"
                        />
                    </Form>
                )}
            </Formik>
        </div >
    );

    const WrongDetailsTemplate = (
        <p>
            The details shown are fetched form IPRS website for the specified
            Kenyan national ID. If these details are incorrect please visit the
            IPRS website to make the changes
        </p>
    );

    return (
        <div className="review-details">
            <CustomerDetails
                alreadyChanged={detailsChangedAlready}
                openReviewForm={updateChangeDetails}
                openWrongDetails={updateWrongDetails}
                detailsChanged={changeDetails}
            />
            <AlertModal
                title="Change Kenyan ID"
                okText="Confirm & Proceed"
                visible={changeDetails}
                onOk={handleConfirm}
                onCancel={() => updateChangeDetails(false)}
                confirmLoading={loading}
            >
                {ChangeDetailsTemplate}
            </AlertModal>
            <AlertModal
                title="Wrong Details"
                okText="Contact IPRS"
                visible={wrongDetails}
                onOk={() => {
                    updateWrongDetails(false);
                    redirectToGovSite();
                }}
                onCancel={() => updateWrongDetails(false)}
            >
                {WrongDetailsTemplate}
            </AlertModal>
        </div>
    );
};

export default ReviewDetails;
