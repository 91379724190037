
import {serializable, alias, object, list, primitive} from 'serializr';

export class Answer { 
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('option', primitive()))
	answer?: string;
}

export class RiskAssesment { 
	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('question', primitive()))
	question?: string;

	@serializable(alias('is_multiselect', primitive()))
	multiSelect: boolean = false;

	@serializable(alias('options', list(object(Answer))))
	answers: Answer[] = [];
}