import React, { FC, useState } from "react";
import { CustomerTypes } from "../../enums/customerTypes";
import AlertModal from "../../shared/components/AlertModal";
import OnBoarding from "../../shared/components/Layout/OnBoarding";
import CustomerForm from "./CustomerForm";
import CustomerType from "./CustomerType";
import "./tellUsMoreAboutYou.scss";

interface TellUsMoreAboutYouProps { }

const TellUsMoreAboutYou: FC<TellUsMoreAboutYouProps> = (props) => {
	const { } = props;

	const [customerType, setCustomerType] =
		useState<CustomerTypes | undefined>();
	const [alertModal, setAlertModal] = useState<boolean>(false)

	const handleSetCustomerType = (type?: CustomerTypes) => {
		setCustomerType(type);
		setAlertModal(true)
	};

	const alertInfoTemplate = (
		<p className="alert-info">
			Please note that the process involves a video KYC step.  Before you proceed, ensure you are audible and in a well-lit space.
		</p>
	);

	return (
		<div className="tell-us-more-about-you">
			<OnBoarding>
				{customerType ? (
					<CustomerForm
						customerTypeHandler={handleSetCustomerType}
						customerType={customerType}
					/>
				) : (
					<CustomerType customerTypeHandler={handleSetCustomerType} />
				)}
			</OnBoarding>
			<AlertModal
				title="Important Note"
				okText="Close"
				visible={alertModal}
				onOk={() => setAlertModal(false)}
				onCancel={() => setAlertModal(false)}
			>
				{alertInfoTemplate}
			</AlertModal>
		</div>
	);
};

export default TellUsMoreAboutYou;
