import { Modal } from "antd";
import React, { FC } from "react";
import "./successModal.scss"

interface SuccessModalProps {
    shareAction: string
    visible: boolean
    className?: string
    onOk: () => void
    onCancel: () => void
}

const SuccessModal: FC<SuccessModalProps> = (props) => {

    const { shareAction, className, visible, onCancel, onOk } = props

    return (
        <Modal
            className={`success-modal ${shareAction} ${className}`}
            visible={visible}
            title="Congrats we received your order"
            onOk={onOk}
            okText="Track Order"
            onCancel={onCancel}
        >
            <p>Our team will process your order and confirm the {shareAction} units</p>
        </Modal>
    )
}

export default SuccessModal