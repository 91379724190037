import { serialize } from "serializr";
import { useState } from "react";
import { generatePath } from "react-router";
import {
  DynamicUrlResponse,
  Subscription,
  SubscriptionResponse,
  UpgradeSubscription,
} from "./../../models/Subscription/subscription.model";
import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { NotificationTypes } from "../../enums/notificationTypes";
import Notification from "../../shared/components/Notification";
import { recursiveRequest } from "../../shared/utils/recursiveRequest";
import AuthService from "../AuthService/auth.service";
import { User } from "../../models/User/user.model";

const SubscriptionService = () => {
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [SubscriptionAmount, setSubscriptionAmount] =
    useState<UpgradeSubscription>();
  const [loading, setLoading] = useState(false);

  const getSubscriptionPlans = async (user: User) => {
    console.log(user?.lastSubscription);
    try {
      const params = {
        renewal: user?.userHasActivePlan ? 0 : 1,
        upgrade: user?.userHasActivePlan ? 1 : 0,
      };

      const response = await axiosInstance.get(ApiRoutes.SUBSCRIPTIONS, {
        params,
      });
      if (response.data?.success) {
        const subscriptions = deserialize(
          Subscription,
          response.data["subscriptions"]
        ) as Subscription[];
        setSubscriptions(subscriptions);
        return;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  // user?.lastSubscription?.code!==subscription?.code ? 1 : 0
  const getSubscriptionAmount = async (
    id: number,
    user: User,
    subscriptionCode: string
  ) => {

    setLoading(true);
    let params;
    if (user.subscription || user.lastSubscription) {
      params = {
        renewal: user?.userHasActivePlan ? 0 : 1,
        upgrade: user.subscription
          ? user.subscription.code !== subscriptionCode
            ? 1
            : 0
          : user.lastSubscription?.code !== subscriptionCode
          ? 1
          : 0,
      };
    } else {
      params = { renewal: 0, upgrade: 0 };
    }

    try {
      const response = await axiosInstance.get(
        generatePath(ApiRoutes.SUBSCRIPTIONS_AMOUNT, { id }),
        { params }
      );
      if (response.data?.success) {
        const SubscriptionAmount = deserialize(
          UpgradeSubscription,
          response.data["payable"]
        ) as UpgradeSubscription;
        setSubscriptionAmount(SubscriptionAmount);

        return;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createSubscription = async (
    subscriptionData: Subscription,
    user: User,
    subscriptionCode: string,
    proceedToHomeHandler: Function
  ) => {
    setLoading(true);
    try {
      let params;
      if (user.subscription || user.lastSubscription) {
        params = {
          renewal: user?.userHasActivePlan ? 0 : 1,
          upgrade: user.subscription
            ? user.subscription.code !== subscriptionCode
              ? 1
              : 0
            : user.lastSubscription?.code !== subscriptionCode
            ? 1
            : 0,
        };
      } else {
        params = { renewal: 0, upgrade: 0 };
      }

      const serializedData = {
        ...serialize(Subscription, subscriptionData)
      };
      const response = await axiosInstance.put(
        ApiRoutes.SUBSCRIPTION,
        {...serializedData,...params}
      );
      if (response.data?.success) {
        const subscriptionResponse = deserialize(
          SubscriptionResponse,
          response.data
        );
        if (subscriptionResponse?.dynamicUrl) {
          return recursiveRequest(subscriptionResponse.dynamicUrl);
        }
        Notification({
          message: "",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        proceedToHomeHandler();
        return;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });

      return false;
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const createInvite = async (email: string) => {
    setLoading(true);
    try {
      const serializedData = { email };
      const response = await axiosInstance.post(
        ApiRoutes.INVITE,
        serializedData
      );
      if (response.data?.success) {
        Notification({
          message: "",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        return true;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const setAutoRenewal = async (autoRenewal: boolean) => {
    setLoading(true);
    try {
      const serializedData = { auto_renewal: autoRenewal };
      const response = await axiosInstance.put(
        ApiRoutes.AUTO_RENEWAL,
        serializedData
      );
      if (response.data?.success) {
        Notification({
          message: "",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        return response.status;
      }
      Notification({
        message: "",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    createInvite,
    subscriptions,
    SubscriptionAmount,
    getSubscriptionPlans,
    createSubscription,
    getSubscriptionAmount,
    setAutoRenewal,
  };
};

export default SubscriptionService;
