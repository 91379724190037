import { Radio, Space } from "antd";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React, { Dispatch, FC, SetStateAction } from "react";
import { RiskAssesment } from "../../../../../models/RiskAssesment/riskAssesment.model";
import "./question.scss";

const CheckboxGroup = Checkbox.Group;

interface QuestionProps {
	answer: { [key: string]: number };
	questionNo: number;
	question?: RiskAssesment;
	multiSelect: boolean;
	setShowSubmit: Dispatch<SetStateAction<boolean>>;
	changeQuestionHandler: (type: "next" | "prev") => void;
	setFieldValue: (name: string, value: any) => void;
}

const Question: FC<QuestionProps> = (props) => {
	const {
		answer,
		questionNo,
		question,
		changeQuestionHandler,
		setFieldValue,
		multiSelect,
		setShowSubmit
	} = props;
	if (!answer) return null;

	const handleSetValue = (value: number | CheckboxValueType[]) => {
		setFieldValue(`answer.${"key" + questionNo}`, value);
		if(typeof value === 'object'){
			setShowSubmit(!!value.length)
			return
		}
		setTimeout(() => {
			changeQuestionHandler("next");
		}, 500);
	};

	return (
		<div className="question">
			<h1>
				{questionNo + 1}. {question?.question}
			</h1>
			<Radio.Group
				value={answer["key" + questionNo]}
				className="primary-radio radio-color-text"
				size="large"
				onChange={(e) => handleSetValue(e.target.value)}
			>
				<Checkbox.Group
					//@ts-ignore
					value={
						Array.isArray(answer["key" + questionNo])
							? answer["key" + questionNo]
							: []
					}
					onChange={(value) => handleSetValue(value)}
				>
					<Space direction="vertical" className="space">
						{question?.answers?.map(({ id, answer }) =>
							multiSelect ? (
								<Checkbox value={id} key={id}>
									{answer}
								</Checkbox>
							) : (
								<Radio value={id} key={id}>
									{answer}
								</Radio>
							)
						)}
					</Space>
				</Checkbox.Group>
			</Radio.Group>
		</div>
	);
};

export default Question;
