import { Button } from "antd";
import { Formik, Form, FormikValues } from "formik";
import React, { useState } from "react";
import { useRef } from "react";
import { UploadDocumentModal } from "../../../models/Documents/documents.model";
import { DocumentsRejected } from "../../../models/User/user.model";
import KYCService from "../../../services/KYCService/kyc.service";
import VideoRecorder from "../../../views/KYCVerification/VideoKYC/VideoRecorder";
import AlertModal from "../AlertModal";
import DocumentUpload from "../DocumentUpload";
import "./pendingKycDocuments.scss"

interface PendingKycDocumentsProps {
    documents: DocumentsRejected[]
    getUserDetails: Function
}

type RejectedDocumentType = 'verification_video' | 'photograph' | 'kenyan_id_proof' | 'KRA_pin'
const PendingKycDocuments = (props: PendingKycDocumentsProps) => {

    const {
        documents,
        getUserDetails,
    } = props

    const [openDocumentUpload, setOpenDocumentUpload] = useState<RejectedDocumentType>()
    const [openVideoUpload, setOpenVideoUpload] = useState<boolean>(false)

    const formikRef = useRef(null)

    const { uploadKYCDocuments, loading } = KYCService();


    const handleSubmit = async (values: FormikValues) => {
        if (!Object.values(values).some(val => !!val)) { return }
        const documents = Object.assign(new UploadDocumentModal(), values);
        documents.reupload = true;
        try {
            await uploadKYCDocuments(documents, true)

            getUserDetails();
            (formikRef.current as any).resetForm()
            setOpenDocumentUpload(undefined)
        } catch (error) {

        }

    }
    const handleClose = () => {
        (formikRef.current as any).resetForm()
        setOpenDocumentUpload(undefined)
        setOpenVideoUpload(false)
    }



    const resubmitHandler = (document: RejectedDocumentType) => {
        if (document === 'verification_video') {
            setOpenVideoUpload(true)
        } else {
            setOpenDocumentUpload(document)
        }
    }


    const formikProps = {
        initialValues: {
            ...new UploadDocumentModal()
        },
        onSubmit: handleSubmit,
    };

    return <div className="pending-documents__container">
        {
            documents.map(document => (
                <div className="pending-document">
                    <div className="pending-document--name">
                        <p className="title">{document?.document?.replaceAll("_", " ")}</p>
                        <p className="reason">Reason : {document?.reason}</p>
                    </div>
                    <div className="pending-document--action">
                        {document?.document && <Button type='primary' onClick={() => {
                            if (!!document?.document) {
                                resubmitHandler(document?.document)
                            }
                        }}>Resubmit</Button>}
                    </div>
                </div>
            ))
        }
        <AlertModal
            className="footer-header-less"
            visible={openVideoUpload}
            onOk={() => { }}
            onCancel={() => { }}>
            <VideoRecorder
                disableClose={true}
                setKycStep={() => { getUserDetails() }}
                afterResubmit={() => {
                    getUserDetails();
                    setOpenVideoUpload(false)
                }}
            ></VideoRecorder>
        </AlertModal>
        <AlertModal
            title="Upload Document"
            visible={!!openDocumentUpload}
            onOk={() => (formikRef.current as any).submitForm()}
            confirmLoading={loading}
            onCancel={handleClose}>
            <p>PDF or Jpeg files allowed. Min size 100 KB, Max size 500 KB</p>
            <Formik
                innerRef={formikRef}
                {...formikProps} enableReinitialize onSubmit={(values) => handleSubmit(values)}>
                {({ values, setFieldValue, isValid, dirty, setFieldError, resetForm }) => (
                    <Form>
                        {openDocumentUpload === 'photograph' && <DocumentUpload
                            title="Photograph"
                            name="photograph"
                            placeholder="Your recent and clear photograph"
                            value={values.photograph}
                            setFieldValue={setFieldValue}
                            bold
                        />}
                        {openDocumentUpload === 'kenyan_id_proof' && <DocumentUpload
                            title="Kenyan ID proof"
                            name="idProof"
                            value={values.idProof}
                            setFieldValue={setFieldValue}
                            bold
                        />}
                        {openDocumentUpload === 'KRA_pin' && <DocumentUpload
                            title="KRA Pin"
                            name="KRAPin"
                            value={values.KRAPin}
                            setFieldValue={setFieldValue}
                            bold
                        />}
                    </Form>
                )}
            </Formik>
        </AlertModal>
    </div>
}

export default PendingKycDocuments
