import React, { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { Order } from "../../models/Order/order.model";
import { serialize } from "serializr";

const OrderService = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const confirmOrder = async (url: string, order: Order) => {
    setLoading(true);
    if (!url) {
      return Notification({
        message: "",
        description: "Please use the link sent to your mail id",
        type: NotificationTypes.ERROR,
      });
    }
    try {
      const payload = serialize(Order, order);
      const response = await axiosInstance.put(url, payload);
      Notification({
        message: "",
        description: response.data?.message || "",
        type: NotificationTypes.SUCCESS,
      });
      return response.data;
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    confirmOrder,
    loading,
  };
};

export default OrderService;
