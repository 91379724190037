import { serializable, alias, primitive } from "serializr";

export class Country {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("country_code", primitive()))
  countryCode?: string;
}

export class CountryMeta {
  @serializable(alias("id", primitive()))
  value?: number;
  @serializable(alias("name", primitive()))
  label?: string;
}
