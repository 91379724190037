import { CreateReducer } from "../../shared/utils/createReducer";
import { ActionProps } from "../../shared/types/action.type";
import {
    SET_CURRENT_IMAGE,
    SET_CURRENT_TEXT,
    SET_KYC_STEP,
} from "../definitions/onBoardingConstants";
import LocalStorage from "../../shared/LocalStorage";
import {
    ACCEPT_TERMS_AND_CONDITIONS,
    REJECT_TERMS_AND_CONDITIONS,
    SHOW_TERMS_AND_CONDITIONS,
    SHOW_TERMS_AND_CONDITIONS_WITHOUT_PROMPT,
} from "../definitions/termsAndConditionsConstants";

export interface TermsAndConditionsState {
    accept: boolean;
    show: boolean;
    noPrompt: boolean;
}

export interface TermsAndConditionsReducerProps
    extends TermsAndConditionsState {
    acceptTermsAndConditions: () => void;
    rejectTermsAndConditions: () => void;
    showTermsAndConditions: () => void;
    showTermsAndConditionsWithoutPrompt: () => void;
}

const initState: TermsAndConditionsState = {
    accept: LocalStorage.getItem("has-accepted-terms-and-conditions") ?? false,
    show: false,
    noPrompt: false,
};

const termsAndConditionsReducer = CreateReducer(initState, {
    [ACCEPT_TERMS_AND_CONDITIONS](
        state: TermsAndConditionsState
    ): TermsAndConditionsState {
        return { ...state, accept: true, show: false, noPrompt: false };
    },
    [SHOW_TERMS_AND_CONDITIONS_WITHOUT_PROMPT](
        state: TermsAndConditionsState
    ): TermsAndConditionsState {
        return { ...state, show: true, noPrompt: true };
    },
    [REJECT_TERMS_AND_CONDITIONS](
        state: TermsAndConditionsState
    ): TermsAndConditionsState {
        return { ...state, accept: false, show: false, noPrompt: false };
    },
    [SHOW_TERMS_AND_CONDITIONS](
        state: TermsAndConditionsState
    ): TermsAndConditionsState {
        return {
            ...state,
            show: true,
            noPrompt: false,
        };
    },
});

export default termsAndConditionsReducer;
