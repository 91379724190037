export const numberConvention = (number: number | string | undefined) => {
  if (!number) {
    return 0;
  }
  number = Number(number).toFixed(2);
  return Number(number).toLocaleString(undefined, { minimumFractionDigits: 2 });
};
export const inputNumberConvention = (number: number | string | undefined) => {
  if (!number) { return number }
  const [wholeNumber, decimalNumber] = number.toString().split('.')
  return parseInt(wholeNumber.replace(/\D/g, '')).toLocaleString() + (decimalNumber ? `.${decimalNumber}` : '')
}