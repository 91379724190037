import React, { FC, useState, Fragment, useRef } from "react";
import { Subscription } from "../../../models/Subscription/subscription.model";
import { ActionProps } from "../../../shared/types/action.type";
import { OnBoardingReducerProps } from "../../../store/reducers/onBoardingReducer";
import BackgroundImage from "../../../assets/images/subscription-payment.png";
import { useEffect } from "react";
import OnBoardingContainer from "../../../store/container/OnBoardingContainer";
import BackArrow from "../../../assets/icons/backArrow.svg";
import Logo from "../../../assets/icons/logo - svg/mpesa.png";
import Bank from "../../../assets/icons/multi color icons - svg/bank details.svg";
import "./paymentForm.scss";
import { DownOutlined } from "@ant-design/icons";
import {
  Dropdown,
  Menu,
  Button,
  List,
  Divider,
  Col,
  Radio,
  Row,
  RadioChangeEvent,
} from "antd";
import { Formik, Form, FormikValues, Field, FormikProps } from "formik";
import InputField from "../../../shared/components/InputField";
import { OptionProps } from "../../../shared/types/option.type";
import CheckboxField from "../../../shared/components/CheckboxField";
import { paymentValidationSchema } from "./validation";
import MPesaIcon from "./../../../assets/icons/single color icons - svg/Security check.svg";
import termsAndConditionsContainer from "../../../store/container/termsAndconditionsContainer";
import { TermsAndConditionsReducerProps } from "../../../store/reducers/termsAndConditionsReducer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import BankTransferInstructions from "../../../shared/components/BankTransferInstructions";
import { User } from "../../../models/User/user.model";
import MpesaTransferInstructions from "../../../shared/components/MpesaTransferInstructions";
import { useHistory } from "react-router-dom";
import * as AppRoutes from "../../../routes/routeConstants/appRoutes";
import SubscriptionService from "../../../services/SubscriptionService/subscription.service";

interface PaymentFormProps
  extends OnBoardingReducerProps,
  TermsAndConditionsReducerProps {
  subscription: Subscription;
  setSubscription: (nullify: null) => ActionProps;
  createSubscription: Function;
  loading: boolean;
  showTermsAndConditions: () => void;
  accept: boolean;
  user: User;
}

const PaymentForm: FC<PaymentFormProps> = (props) => {
  const { SubscriptionAmount, getSubscriptionAmount } = SubscriptionService();
  const history = useHistory();
  const {
    setCurrentImage,
    setCurrentText,
    setSubscription,
    subscription,
    createSubscription,
    loading,
    showTermsAndConditions,
    accept,
    user,
  } = props;

  const [paymentMode, setPaymentMode] = useState<number>(3);

  const formikRef = useRef<FormikProps<any>>(null);

  useEffect(() => {
    setCurrentImage(BackgroundImage);
    setCurrentText("Take the First Step");
    /*  getSubscriptionAmount(subscription.id) */
  }, []);
  useEffect(() => {
    if (subscription.id) {
      console.log(subscription.code,user.lastSubscription?.code)
      /*id , upgrade , renewal*/
      getSubscriptionAmount(subscription.id, user, subscription?.code??'0');
    }
  }, [subscription, user.subscription]);

  const totalAmount = (subscription?.price || 0) + (subscription?.joiningFee || 0);

  // const agreeTermsAndConditions = [
  //     {
  //         label: (
  //             <span className="terms-acceptance-label">
  //                 I have read all the{" "}
  //                 <b onClick={showTermsAndconditions}>Terms and Conditions</b>{" "}
  //                 and agree to the same
  //             </span>
  //         ),
  //         value: true,
  //         key: "true",
  //     },
  // ];

  const handleSubmit = (values: FormikValues) => {
    try {
      createSubscription(values);
    } catch (error) { }
  };

  const formikProps = {
    initialValues: {
      ...new Subscription(),
      subscriptionId: subscription.id,
      isAgreed: false,
      useWalletOnly: (SubscriptionAmount?.totalAmount || 0) <= 0,
      upgrade: Boolean(user.subscription),
    },
    innerRef: formikRef,
    enableReinitialize: true,
    validationSchema:
      SubscriptionAmount?.totalAmount ?? 0 > 0
        ? paymentValidationSchema
        : undefined,
    onSubmit: handleSubmit,
  };
  const usableDynamicWalletBalance = SubscriptionAmount
    ? SubscriptionAmount?.dynamicWallet > SubscriptionAmount?.amount
      ? SubscriptionAmount?.amount
      : SubscriptionAmount?.dynamicWallet
    : 0;
  const amountSplitupListTemplate = (
    <div className="payment-form__mobile-form--split-up">
      <List>
        {user.subscription?.name !== subscription.name && (
          <List.Item>
            <span>Joining Fee</span>
            <span className="price">{SubscriptionAmount?.joiningFee} KES</span>
          </List.Item>)}
        <List.Item>
          <span>{subscription.name} Subscription</span>
          <span className="price">{SubscriptionAmount?.price} KES</span>
        </List.Item>
        <List.Item>
          <span>Usable Dynamic Wallet Balance</span>
          <span className="price danger  ">
            {usableDynamicWalletBalance * -1} KES
          </span>
        </List.Item>
        <List.Item>
          <span>Amount to be paid</span>
          <span className="price price-bold">
            {SubscriptionAmount?.totalAmount} KES
          </span>
        </List.Item>
      </List>
    </div>
  );

  const paymentModeChanged = (e: RadioChangeEvent) => {
    setPaymentMode(e.target.value);
    formikRef.current?.setFieldValue("phone", undefined);
    formikRef.current?.setFieldTouched("phone", false);
  };

  const proceedToHomeHandler = () => {
    history.push(AppRoutes.HOME);
  };

  const shouldPaymentButtonBeDisabled=(isValid:boolean,dirty:boolean)=>{
    const baseCase = ((SubscriptionAmount?.totalAmount ?? 0) < 0) || !accept   || !totalAmount;
    return (SubscriptionAmount?.totalAmount ?? 0) > 0?  baseCase || (!isValid || !dirty) : baseCase || !isValid  
  }

  return (
    <div className="payment-form">
      <h1 className="text-primary">
        <img
          className="cursor-pointer"
          src={BackArrow}
          alt=""
          onClick={() => setSubscription(null)}
        />{" "}
        &nbsp; Payment
      </h1>
      {(SubscriptionAmount?.totalAmount ?? 0) > 0 ? (
        <>
          <Row align="middle">
            <Col span={2}>
              <Radio
                value={1}
                checked={paymentMode === 1}
                onChange={paymentModeChanged}
              />
            </Col>

            <Col span={22}>
              <div className="payment-form__payment-method justify-between">
                <p>Pay using Bank Account</p>
                <div className="bank-icon__container">
                  <img className="m-pesa-icon" src={Bank} alt="m-pesa-icon" />
                </div>
              </div>
            </Col>
          </Row>

          {paymentMode === 1 && (
            <BankTransferInstructions
              customerId={String(user?.uid).split("-").pop() || ""}
            />
          )}

          <Divider></Divider>
          <Row align="middle">
            <Col span={2}>
              <Radio
                value={2}
                checked={paymentMode === 2}
                onChange={paymentModeChanged}
              />
            </Col>

            <Col span={22}>
              <div className="payment-form__payment-method  justify-between">
                <p>Pay using Mpesa App</p>
                <img className="m-pesa-icon" src={Logo} alt="m-pesa-icon" />
              </div>
            </Col>
          </Row>

          {paymentMode === 2 && (
            <MpesaTransferInstructions
              amount={SubscriptionAmount?.totalAmount ?? 0}
              customerId={String(user?.uid).split("-").pop() || ""}
            />
          )}
          <Divider></Divider>
          <Row align="middle">
            <Col span={2}>
              <Radio
                value={3}
                checked={paymentMode === 3}
                onChange={paymentModeChanged}
              />
            </Col>
            <Col span={22}>
              <div className="payment-form__payment-method">
                <p>Pay using</p>
                <img className="m-pesa-icon" src={Logo} alt="m-pesa-icon" />
              </div>
            </Col>
          </Row>

          <Divider></Divider>
        </>
      ) : (
        ""
      )}
      <div className="payment-form__mobile-form">
        <Formik {...formikProps}>
          {({ isValid, dirty, setFieldValue, values }) => {
            return (
              <Form>
                {totalAmount > 0 &&
                  paymentMode === 3 &&
                  (SubscriptionAmount?.totalAmount ?? 0) > 0 ? (
                  <InputField
                    type="text"
                    name="phone"
                    className="mpesa-field"
                    placeholder="Enter MPESA mobile number"
                  />
                ) : null}
                {amountSplitupListTemplate}
                <Checkbox
                  className="terms-acceptance--checkbox"
                  name="isAgreed"
                  checked={accept}
                  onChange={showTermsAndConditions}
                >
                  <span className="terms-acceptance-label">
                    I have read all the{" "}
                    <b onClick={showTermsAndConditions}>Terms and Conditions</b>{" "}
                    and agree to the same
                  </span>
                </Checkbox>
                <div
                  className={`submit-btn mt-5  ${(SubscriptionAmount?.totalAmount ?? 0) > 0
                    ? "pay-btn"
                    : `pay-btn-wallet`
                    }`}
                >
                  {paymentMode === 3 ? (
                    <Button
                      type="primary"
                      htmlType="submit"
                      size="middle"
                      disabled={shouldPaymentButtonBeDisabled(isValid,dirty)}
                      loading={loading}
                    >
                      &nbsp;
                      {(SubscriptionAmount?.totalAmount ?? 0) > 0
                        ? `Pay ${SubscriptionAmount?.totalAmount} KES`
                        : "Subscribe"}
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      size="middle"
                      disabled={!accept}
                      loading={loading}
                      onClick={proceedToHomeHandler}
                    >
                      &nbsp;
                      {`Proceed to Home`}
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>

        {paymentMode === 3 && (
          <p className="payment_form__footer">
            {(SubscriptionAmount?.totalAmount ?? 0) > 0 ? (
              <>
                <img src={MPesaIcon} alt="mpesa" />
                MPESA: 100% Payment Protection
              </>
            ) : (
              ""
            )}
          </p>
        )}
      </div>
    </div>
  );
};

export default OnBoardingContainer(termsAndConditionsContainer(PaymentForm));
