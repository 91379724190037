import { Form } from "antd";
import { Formik, FormikHelpers } from "formik";
import React, { useState, useEffect } from "react";
import { BankDetail } from "../../../../models/Customer/customer.model";
import InputField from "../../../../shared/components/InputField";
import RadioField from "../../../../shared/components/RadioField";
import UIModal from "../../../../shared/components/UIModal";
import { bankDetailsValidation } from "./validation";
import DropdownField from "../../../../shared/components/DropdownField";
import KYCService from "../../../../services/KYCService/kyc.service";
import { AccountTypeOptions, accountType, BankAccountType, CurrencyOptions } from "./bankForm.constant";

interface BankFormProps {
    visible: boolean;
    loading: boolean;
    data?: any
    closeHandler: () => void;
    submitHandler: (values: BankDetail, formikHelpers: FormikHelpers<BankDetail>) => void
}

const BankForm = (props: BankFormProps) => {
    const {
        visible,
        loading,
        closeHandler,
        data,
        submitHandler,
    } = props;

    const {getCountries, countries} = KYCService();
    const [formData, setFormData] = useState<BankDetail>(new BankDetail())

    useEffect(() => {
        getCountries()
        const bankData: BankDetail = {
            ...data,
            isSavingsAccount: data?.accountType === accountType.SAVINGS ? true : false,
            accountType : data?.bankAccountType === "International Bank Account" ? 0 : 1

        }
        setFormData({ ...bankData })
    }, [data])

    return (
        <Formik enableReinitialize
            initialValues={formData}
            validationSchema={bankDetailsValidation}
            onSubmit={submitHandler}
        >
            {({ setFieldValue, values, resetForm, submitForm }) => {
                return (
                    <UIModal
                        visible={visible}
                        okText="Submit"
                        loading={loading}
                        onOk={submitForm}
                        onClose={() => {
                            resetForm()
                            closeHandler()
                        }}
                        title={`${!!data ? "Edit" : "Add"} Bank Details`}
                    >
                        <Form>
                            <InputField
                                type="text"
                                name="accountHolderName"
                                title="Account Name"
                            />
                            <InputField
                                type="text"
                                name="accountNumber"
                                title="Account Number"
                            />
                             <DropdownField
                             name="bankAccountType" 
                             title="Bank Account Type"
                             value={values?.bankAccountType}
                             options={BankAccountType}
                             onChange={(value) => {
                                setFieldValue("bankAccountType",value)
                                setFieldValue("accountType", value); 
                                if(value==1){
                                    console.log(value,"..value")
                                    setFieldValue("countryId",undefined);
                                    setFieldValue("currency", undefined); 
                                    setFieldValue("country", undefined);
                                }
                              }}
                              
                             />
                             
                            {
                              values?.accountType === 0 &&
                                <>
                                    <DropdownField
                                    name="countryId" 
                                    title="Country"
                                    value={values?.countryId}
                                    options={countries}
                                    onChange={(value) => {
                                        setFieldValue("countryId", value)
                                    }}
                                    showSearch
                                    className="country-dropdown"
                                    />
                                    <DropdownField
                                    name="currency" 
                                    title="Currency"
                                    value={values?.currency}
                                    options={CurrencyOptions}
                                    setFieldValue={setFieldValue}
                                    onChange={(value) => {
                                        setFieldValue("currency", value)
                                    }}
                                    showSearch
                                    />
                            </>
                            }
                            <InputField
                                type="text"
                                name="bankName"
                                title="Bank Name"
                            />
                            <InputField
                                type="text"
                                name="branchName"
                                title="Branch Name"
                            />
                            <InputField
                                type="text"
                                name="swiftCode"
                                title="Swift Code"
                            />
                            <RadioField
                                title="Account Type"
                                name="isSavingsAccount"
                                value={values?.isSavingsAccount}
                                options={AccountTypeOptions}
                                setFieldValue={setFieldValue}
                            />
                        </Form>
                    </UIModal>
                );
            }}
        </Formik>
    );
};

export default BankForm;
