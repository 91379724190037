import { Col, Form, Modal, Row } from "antd";
import { Formik, FormikHelpers, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import {
  BankDetail,
  EmergencyContact,
} from "../../../../models/Customer/customer.model";
import DropdownField from "../../../../shared/components/DropdownField";
import InputField from "../../../../shared/components/InputField";
import UIModal from "../../../../shared/components/UIModal";
import { emergencyDetailsValidation } from "./validation";
import "../profile.scss";

interface EmergencyFormProps {
  loading: boolean;
  visible: boolean;
  closeHandler: () => void;
  submitHandler: (
    values: EmergencyContact,
    formikHelpers: FormikHelpers<EmergencyContact>
  ) => void;
}

const EmergencyForm = (props: EmergencyFormProps) => {
  const { visible, loading, closeHandler, submitHandler } = props;

  const [formData, setFormData] = useState<EmergencyContact>(
    new EmergencyContact()
  );

  return (
    <Formik
      enableReinitialize
      initialValues={formData}
      onSubmit={submitHandler}
      validationSchema={emergencyDetailsValidation}
    >
      {({
        setFieldValue,
        values,
        dirty,
        resetForm,
        isValid,
        setFieldTouched,
        submitForm,
      }) => {
        return (
          <UIModal
            visible={visible}
            okText="Submit"
            loading={loading}
            onOk={submitForm}
            onClose={() => {
              resetForm();
              closeHandler();
            }}
            title={"Add Emergency Details"}
          >
            <Form>
              <div className="emergency-form__container">
                <Row gutter={[0, 15]}>
                  <Col sm={24} className="emergency__field">
                    <InputField
                      className="responsiveNameInput"
                      title="Name"
                      placeholder={"Name"}
                      name="name"
                      type="text"
                    />
                  </Col>
                  <Col sm={24} className="emergency__field">
                    <InputField
                      title="Phone Number"
                      placeholder={"Enter"}
                      name="phone"
                      type="text"
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </UIModal>
        );
      }}
    </Formik>
  );
};

export default EmergencyForm;
